import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppService } from '../app.service';
import { map } from 'rxjs/operators';
import { filterValue } from './global-monitoring';

@Injectable({
  providedIn: 'root'
})
export class GlobalMonitoringService {

  headers = new HttpHeaders();
  sessionId: any = "";
  public manualSubscriber = new Subject<any>();
  public selection = new Subject<string>();
  constructor(private http: HttpClient, private appService: AppService) {

    this.sessionId = localStorage.getItem('sessionID');
    this.headers = this.headers.set('Accept', 'application/json');
    this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
    this.headers = this.headers.set('sessionid', this.sessionId);
  }

  setSid(sessionId: any) {
    localStorage.setItem('sessionID', sessionId);
    this.sessionId = localStorage.getItem('sessionID');
    this.headers = this.headers.set('sessionid', sessionId);
  }

  getAllDashboardRecords(recordsPerPage: number, pageNumber: number, filterValue: any): any {
    var formData: any = new FormData();
    formData.append("RecordsPerPage", recordsPerPage);
    formData.append("PageNumber", pageNumber);
    if (filterValue) {
      if (filterValue.entityNameFilter)
        formData.append("entityNameFilter", filterValue.entityNameFilter);
      if (filterValue.alertDateFromFilter)
        formData.append("alertDateFromFilter", filterValue?.alertDateFromFilter);
      if (filterValue.alertDateToFilter)
        formData.append("alertDateToFilter", filterValue?.alertDateToFilter);
    }
    return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GetDashboardDetails', formData, { headers: this.headers }).pipe(map(res => res));
  }

  generateReport(formData: any): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: this.headers
    };
    return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GenerateFullReport', formData,
      httpOptions).pipe(map(res => res));
  }

  generateDeltaReport(formData: any): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: this.headers
    };
    return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GenerateDeltaReport', formData,
      httpOptions).pipe(map(res => res));
  }

  viewReport(formData: any): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: this.headers
    };
    return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GenerateDeltaReport', formData,
      httpOptions).pipe(map(res => res));
  }

  getGlobalMonitoringAlerts(itemId: any): any {
    var formData: any = new FormData();
    if (itemId)
      formData.append("itemId", itemId);
    formData.append("reportFormat", 'JSON');
    return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GenerateDeltaReport', formData, { headers: this.headers }).pipe(map(res => res));
  }

  exportToExcel(itemId: any): any {
    var formData: any = new FormData();
    if (itemId)
      formData.append("itemId", itemId);
    return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/CollectUBOExcelReport', formData, { headers: this.headers }).pipe(map(res => res));
  }
}
