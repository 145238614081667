/*----------------------------MODULES-------------------------------*/
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatNativeDateModule, } from '@angular/material/core';
import { IvyCarouselModule } from 'angular-responsive-carousel-ng16';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

/*-----------------------------COMPONENTS----------------------------------*/
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { KeyProductsComponent } from './key-products/key-products.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { GridCellRendererComponent } from './grid-cell-renderer/grid-cell-renderer.component';
import { CustomToolTipComponent } from './custom-tooltip/custom-tooltip.component';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { CustomDropDownFilter } from './custom-dropdown-filter/custom-dropdown-filter';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BillingStatementComponent } from './billing-statement/billing-statement.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// monitoring component
import { MonitorComponent } from './monitoring/monitor/monitor.component';
import { MonitoringSearchComponent } from './monitoring/shared-module/search-result/monitor-search';
import { AddManuallyDialogComponent } from './monitoring/shared-module/add-manually/add-manually.component';
import { MonitorConfimDialogComponent } from './monitoring/shared-module/confirm-dialog/confirm-dialog.component';
import { MonitorRecordsComponent } from './monitoring/monitored-all-records/monitor-records.component';
import { MonitoredNamesPaginationComponent } from './monitoring/shared-module/pagination/pagination.component';
import { ManuallyAddConfimDialogComponent } from './monitoring/shared-module/manually-confirm-dialog/confirm-dialog.component';
import { ManuallPickAlertComponent } from './monitoring/shared-module/manuall-pick-alert/company-pick-alert';
import { GridLoaderComponent } from './monitoring/shared-module/loader/loader.component';
// toast
import { Toast } from './monitoring/shared-module/toast/toast.component';
import { NotificationService } from './monitoring/shared-module/toast/toast.service';
// expand cell renderer
import { DetailCellRenderer } from './monitoring/shared-module/master-data-grids/cell-renderer.component';
import { ExpandGridCellRendererComponent } from './monitoring/shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
/*--------------------------------SERVICES------------------------------------*/
import { LocationStrategy, HashLocationStrategy, CurrencyPipe } from '@angular/common';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { OrderHistoryService } from './order-history/order-history.service';
import { MonitoringService } from './monitoring/monitoring.service';
/*-------------------------------MODELS---------------------------------------*/
import { ConfimDialogComponent } from './confirm-dialog-box/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog-box/error-dialog.component';
import { NoResultDialogComponent } from './no-result-dialog-box/no-result-dialog.component';
import { ErrorTranslationResultDialogComponent } from './error-translation-result-dialog-box/error-translation-result-dialog.component';
import { SearchResultsComponent } from './search-results/search-results';
import { ViewComparisonComponent } from './view-comparison/view-comparison';
import { DocumentViewComponent } from './document-view/document-view.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { ToastermessageComponent } from './toastermessage/toastermessage.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportsComponent } from './monitoring/reports/reports.component';
import { DashboardComponent } from './monitoring/dashboard/dashboard.component';
import { IndividualPickAlertComponent } from './monitoring/shared-module/individual-pick-alert/individual-pick-alert';
import { CompanyPickAlertComponent } from './monitoring/shared-module/company-pick-alert/company-pick-alert';
import { RestoreComponent } from './monitoring/restore/restore.component';
import { ExportAllAlertComponent } from './monitoring/shared-module/export-all-alert/export-all-alert.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { InfoDialogComponent } from './monitoring/shared-module/info-dialog/info-dialog.component';
import { DragDirective } from './bulk-upload/dragDrop.directive';
import { CreditMonitoringServiceComponent } from './monitoring/credit-monitoring-service-alert/credit-monitoring-service-alert.component';
import { BusinessMonitoringServiceComponent } from './monitoring/business-monitoring-service-alert/business-monitoring-service-alert.component';
import { PaymentMonitoringServiceComponent } from './monitoring/payment-monitoring-service-alert/payment-monitoring-service-alert.component';
import { BulkUploadGridComponent } from './bulk-upload-grid/bulk-upload-grid.component';
import { BulkUploadGridService } from './bulk-upload-grid/bulk-upload-grid.service';
import { MatchResultComponent } from './bulk-upload-grid/match-result/match-result';
import { BulkCellRendererComponent } from './bulk-upload-grid/grid-cell-renderer.component';
import { BulkGridCellRendererComponent } from './bulk-upload-grid/bulk-grid-cell-renderer/bulk-grid-cell-renderer.component';
import { OfflineSearchComponent } from './bulk-upload-grid/offline-search/offline-search';
import { TurnoverCellRendererComponent } from './bulk-upload-grid/turnover-cell-renderer.component';
import { SampleCellRendererComponent } from './bulk-upload-grid/match-result/sample-cell-renderer.component';
import { UploadCompleteDialogComponent } from './bulk-upload-grid/upload-complete-dialog-box/upload-complete-dialog.component';
import { NoMatchPopupCellRendererComponent } from './bulk-upload-grid/no-match-popup-cell-renderer/no-match-popup-cell-renderer.component';
import { ActionDialogComponent } from './monitoring/shared-module/action-dialog/action-dialog.component';
import { CMSHearingDefendantDetailCellRenderer } from './monitoring/credit-monitoring-service-alert/master-data-grid-hearing-defendant/cell-renderer.component';
import { CMSHearingPlandiffDetailCellRenderer } from './monitoring/credit-monitoring-service-alert/master-data-grid-hearing-plandiff/cell-renderer.component';
import { CMSNewDefendantDetailCellRenderer } from './monitoring/credit-monitoring-service-alert/master-data-grid-new-defendant/cell-renderer.component';
import { CMSNewPlandiffDetailCellRenderer } from './monitoring/credit-monitoring-service-alert/master-data-grid-new-plandiff/cell-renderer.component';
import { MonitoringBulkUploadGridComponent } from './monitoring/bulk-upload-grid/bulk-upload-grid.component';
import { MonitoringBulkUploadComponent } from './monitoring/bulk-upload/bulk-upload.component';
import { CommonDialogComponent } from './monitoring/shared-module/dialogs/confirm-dialog.component';
import { BWSDetailCellRenderer } from './monitoring/business-monitoring-service-alert/master-data-grid-bws-gaz/cell-renderer.component';
import { AlertTriggerCellRendererComponent } from './monitoring/reports/grid-cell-renderer.component';
import { ReportsDetailCellRenderer } from './monitoring/reports/master-data-grids/cell-renderer.component';
import { CartDataCellRenderer } from './home/cart-data-grids/cart-data.component';
import { OrderDataCellRenderer } from './thank-you/order-details-grid/order-data.component';
import { CartActionBottomSheet } from './action-bottom-sheet/cart-action-bottom-sheet.component';
import { OrderHistoryDataCellRenderer } from './order-history/order-history-data/order-history-data.component';
import { SearchResultDataCellRenderer } from './search-results/search-result-grid-data/search-result-grid-data.component';
import { FilterPopUpComponent } from './monitoring/monitor/filter-popup/filter-popup.component';
import { MonitorResultDataCellRenderer } from './monitoring/shared-module/search-result/monitor-result-grid-data/monitor-result-grid-data.component';
import { DownTimeComponent } from './bulk-upload-grid/downtime/downtime.component';
import { MonitorAllRecordDataCellRenderer } from './monitoring/monitored-all-records/monitor-all-record-data/monitor-all-record-data.component';
import { RestoreDataCellRenderer } from './monitoring/restore/restore-grid-data/restore-grid-data.component';
import { BWSAlertDetailCellRenderer } from './monitoring/business-monitoring-service-alert/master-data-grids/cell-renderer.component';
import { LitigationMonitoringServiceComponent } from './monitoring/litigation-monitoring-service-alert/litigation-monitoring-service-alert.component';
import { LMSNewDefendantDetailCellRenderer } from './monitoring/litigation-monitoring-service-alert/master-data-grid-new-defendant/cell-renderer.component';
import { LMSNewPlandiffDetailCellRenderer } from './monitoring/litigation-monitoring-service-alert/master-data-grid-new-plandiff/cell-renderer.component';
import { DailySuitServiceComponent } from './monitoring/daily-new-suits-alert/daily-new-suits-alert.component';
import { DNSADetailCellRenderer } from './monitoring/daily-new-suits-alert/master-data-grid-dnsa/cell-renderer.component';
import { LmsSubscriptionComponent } from './monitoring/shared-module/lms-subscription-bulk/lms-subscription-bulk.component';
import { MyNoRowsComponent } from './monitoring/shared-module/search-result/no-overlay.component';
import { AuthService } from './authGuard.service';
import { HomeAuthService } from './homeAuthGuard.service';
import { MonitorAuthService } from './monitorAuthGuard.service';

// print
import { NgxPrintModule } from 'ngx-print';
import { ConfimDeleteDialogComponent } from './monitoring/shared-module/confirm-dialog-box/confirm-dialog.component';
import { MatchCellRendererComponent } from './bulk-upload-grid/match-cell-renderer.component';
import { SelectAllHeader } from './monitoring/shared-module/header-component/select-all.component';
import { NameRendererComponent } from './monitoring/shared-module/search-result/name-renderer/name-renderer.component';
import { GlobalMonitoringComponent } from './global-monitoring/global-monitoring.component';
import { ExpandGlobalGridCellRendererComponent } from './global-monitoring/expand-global-grid-cell-renderer/expand-global-grid-cell-renderer.component';
import { GlobalDetailCellRenderer } from './global-monitoring/global-master-data-grids/cell-renderer.component';
import { GlobalAlertTriggerCellRendererComponent } from './global-monitoring/alert-trigger-cell-renderer/cell-renderer.component';
import { GlobalMonitoringAlertComponent } from './global-monitoring/global-monitoring-alert/global-monitoring-alert.component';
import { GlobalFilterPopUpComponent } from './global-monitoring/filter-popup/global-filter-popup.component';
import { GlobalNewDefendantDetailCellRenderer } from './global-monitoring/global-monitoring-alert/master-data-grid-new-defendant/global-cell-renderer.component';
import { HeaderNamePipe } from './global-monitoring/header-name.pipe';
import { KeepOrderPipe } from './global-monitoring/keep-order.pipe';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        BulkUploadGridComponent,
        MonitoringBulkUploadGridComponent,
        HeaderComponent,
        MonitorComponent,
        FooterComponent,
        SidebarComponent,
        KeyProductsComponent,
        OrderHistoryComponent,
        PageNotFoundComponent,
        ThankYouComponent,
        GridCellRendererComponent,
        BulkGridCellRendererComponent,
        NoMatchPopupCellRendererComponent,
        ExpandGridCellRendererComponent,
        CustomFilterComponent,
        CustomDropDownFilter,
        MyNoRowsComponent,
        SelectAllHeader,
        CustomToolTipComponent,
        ConfimDialogComponent,
        ConfimDeleteDialogComponent,
        InfoDialogComponent,
        ActionDialogComponent,
        ErrorDialogComponent,
        NoResultDialogComponent,
        ErrorTranslationResultDialogComponent,
        AddManuallyDialogComponent,
        SearchResultsComponent,
        UploadCompleteDialogComponent,
        OfflineSearchComponent,
        BulkCellRendererComponent,
        MatchCellRendererComponent,
        SampleCellRendererComponent,
        MatchResultComponent,
        ViewComparisonComponent,
        LoaderComponent,
        GridLoaderComponent,
        SessionExpiredComponent,
        PaginationComponent,
        ManuallyAddConfimDialogComponent,
        MonitoredNamesPaginationComponent,
        ManuallPickAlertComponent,
        BulkUploadComponent,
        DownTimeComponent,
        MonitoringBulkUploadComponent,
        DocumentViewComponent,
        AdvancedSearchComponent,
        BillingStatementComponent,
        ReportsComponent,
        DashboardComponent,
        MonitoringSearchComponent,
        MonitorConfimDialogComponent,
        CommonDialogComponent,
        IndividualPickAlertComponent,
        LmsSubscriptionComponent,
        CompanyPickAlertComponent,
        MonitorRecordsComponent,
        Toast,
        RestoreComponent,
        CreditMonitoringServiceComponent,
        LitigationMonitoringServiceComponent,
        BusinessMonitoringServiceComponent,
        DailySuitServiceComponent,
        PaymentMonitoringServiceComponent,
        ExportAllAlertComponent,
        ReportsDetailCellRenderer,
        CartDataCellRenderer,
        OrderDataCellRenderer,
        OrderHistoryDataCellRenderer,
        SearchResultDataCellRenderer,
        MonitorResultDataCellRenderer,
        FilterPopUpComponent,
        DetailCellRenderer,
        RestoreDataCellRenderer,
        BWSAlertDetailCellRenderer,
        MonitorAllRecordDataCellRenderer,
        CMSHearingDefendantDetailCellRenderer,
        CMSHearingPlandiffDetailCellRenderer,
        CMSNewDefendantDetailCellRenderer,
        LMSNewDefendantDetailCellRenderer,
        CMSNewPlandiffDetailCellRenderer,
        LMSNewPlandiffDetailCellRenderer,
        BWSDetailCellRenderer,
        DNSADetailCellRenderer,
        TurnoverCellRendererComponent,
        AlertTriggerCellRendererComponent,
        DragDirective,
        CartActionBottomSheet,
        NameRendererComponent,
        GlobalMonitoringComponent,
        ExpandGlobalGridCellRendererComponent,
        GlobalDetailCellRenderer,
        GlobalAlertTriggerCellRendererComponent,
        GlobalMonitoringAlertComponent,
        GlobalNewDefendantDetailCellRenderer,
        GlobalFilterPopUpComponent,
        HeaderNamePipe,
        KeepOrderPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        MatTabsModule,
        NgSelectModule,
        FormsModule,
        FlexLayoutModule,
        MatDialogModule,
        NgxDaterangepickerMd.forRoot(),
        MatToolbarModule,
        MatSelectModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgbModule,
        IvyCarouselModule,
        MatBottomSheetModule,
        ToastrModule.forRoot({ toastComponent: ToastermessageComponent, positionClass: 'toast-center-center', preventDuplicates: true }),
        // AgGridModule.withComponents([
        //     GridCellRendererComponent,
        //     BulkGridCellRendererComponent,
        //     NoMatchPopupCellRendererComponent,
        //     CustomFilterComponent,
        //     CustomDropDownFilter,
        //     MyNoRowsComponent,
        //     SelectAllHeader,
        //     CustomToolTipComponent,
        //     DetailCellRenderer,
        //     MonitorAllRecordDataCellRenderer,
        //     CMSHearingDefendantDetailCellRenderer,
        //     CMSHearingPlandiffDetailCellRenderer,
        //     CMSNewDefendantDetailCellRenderer,
        //     LMSNewDefendantDetailCellRenderer,
        //     CMSNewPlandiffDetailCellRenderer,
        //     LMSNewPlandiffDetailCellRenderer,
        //     BWSDetailCellRenderer,
        //     DNSADetailCellRenderer,
        //     ExpandGridCellRendererComponent,
        //     GridLoaderComponent,
        //     ReportsDetailCellRenderer,
        //     CartDataCellRenderer,
        //     OrderDataCellRenderer,
        //     OrderHistoryDataCellRenderer,
        //     SearchResultDataCellRenderer,
        //     MonitorResultDataCellRenderer,
        //     RestoreDataCellRenderer,
        //     BWSAlertDetailCellRenderer,
        //     ExpandGlobalGridCellRendererComponent,
        //     GlobalDetailCellRenderer,
        //     GlobalAlertTriggerCellRendererComponent,
        //     GlobalNewDefendantDetailCellRenderer,
        // ]),
        AgGridModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxPrintModule
    ],
    providers: [
        LoaderService,
        CurrencyPipe,
        OrderHistoryService,
        BulkUploadGridService,
        MonitoringService,
        NotificationService,
        AuthService,
        HomeAuthService,
        MonitorAuthService,
        ToastrService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
