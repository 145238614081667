<div class="dialog-content-wrapper" *ngIf="!showLmsDisclaimer">
    <div mat-dialog-content>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text" *ngIf="!isAddChangesToEdit && (!entity.edit || entity?.restore)">
                {{ 'monitor.countryPicAlert.pickYourAlertsForThisEntity' | translate }}
            </span>
            <span class="title-text" *ngIf="isAddChangesToEdit || ( entity.edit && !entity?.restore)">
                {{ 'monitor.countryPicAlert.editYourAlertsForThisEntity' | translate }}
            </span>
        </div>

        <div class="zanna_div row" style="margin-bottom: 36px;">
            <label>Selected Entity</label><br />
            <table class="table" style="width: 100%;">
                <tr>
                    <th class="table-border-header-first desktop-view" width="204">
                        {{ 'monitor.entityNo' | translate }}
                    </th>
                    <th class="table-border-header border-r-only" width="226">
                        {{ 'monitor.entity_name' | translate }}
                    </th>
                    <th  *ngIf="(entity.edit == false) && !isAddChangesToEdit" class="table-border-header desktop-view" width="200">
                        {{ 'monitor.file' | translate }}
                    </th>
                    <th class="table-border-header-last desktop-view" width="100">
                        {{ 'monitor.country' | translate }}
                    </th>
                </tr>
                <tr *ngIf="entity.edit == false" class="fs-2">
                    <td class="table-border-data-first desktop-view">
                       {{ entity.entity_no}}
                    </td>
                    <td class="table-border-data border-r-only" style="word-break: break-word;">
                         {{ entity.entity_name}}
                    </td>
                    <td *ngIf = "!isAddChangesToEdit" class="table-border-data desktop-view" style="word-break: break-word;">
                        <span>{{ entity.file }}</span>
                        <!-- <span *ngIf="!entity.file">N.A</span> -->
                    </td>
                    <td class="table-border-data desktop-view border-right" style="word-break: break-all;">
                        Singapore
                    </td>
                </tr>

                <tr *ngIf="entity.edit == true" class="fs-2">
                    <td class="table-border-data-first desktop-view">
                       {{ entity.entityId}}
                    </td>
                    <td class="table-border-data border-r-only" style="word-break: break-word;">
                         {{ entity.entityName}}
                    </td>
                    <!-- <td class="table-border-data desktop-view" style="word-break: break-word;">
                        <span>{{ entity.clientFileReference }}</span>
                    </td> -->
                    <td class="table-border-data desktop-view" style="word-break: break-all;">
                        Singapore
                    </td>
                </tr>                
            </table>
            <div class="table-details" *ngIf="isMobile">
                <div class="detail-container">
                    <label for="">{{ 'monitor.entityNo' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.entity_no}}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.entityId}}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.file' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.file }}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.clientFileReference }}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.country' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        Singapore
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity?.countryName }}
                    </div>
                </div>
            </div>
        </div>
        

        
        <div class="zanna_div row" style="margin-bottom: 36px;">
            <label>{{ 'monitor.countryPicAlert.addAlertsToEntity' | translate }}</label><br />
            <div>
                <table class="alert-table">
                    <tr>
                        <td style="width: 354px;padding-left: 0px;">
                            <label>{{ 'monitor.countryPicAlert.selectAll' | translate }}</label>
                        </td>
                        <td style="width: 60%;" class="desktop-view">
                            <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllCheck()" />
                        </td>
                        <td style="width: 50%;text-align: right;" class="mobile-view">
                            <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllCheck()" />
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngFor="let alert of alerts">
                <table class="alert-table" >
                    <tr>
                        <td style="padding-top: 3px; cursor: pointer;" (click)="alert.expanded = !alert.expanded">
                            <span *ngIf="!alert.expanded">
                                <mat-icon class="download-icon">keyboard_arrow_down</mat-icon>
                            </span>
                            <span *ngIf="alert.expanded">
                                <mat-icon class="download-icon">keyboard_arrow_up</mat-icon>
                            </span>
                        </td>
                        <td *ngIf="!alert.link" style="width: 300px;">
                            <label >{{ alert.monitoringName}}</label><span  class="small-text" *ngIf="alert.monitoringType =='NBB'"> ({{ alert.entitlement}})</span>
                        </td>
                        <td *ngIf="!alert.link && !isMobile" style="width: 60%;" >
                            <span *ngIf="alert.disabled">
                                <mat-checkbox  [(ngModel)]="alert.check" (change)="onAlertCheck(alert)" disabled></mat-checkbox>
                            </span>
                            <span *ngIf="!alert.disabled">
                                <mat-checkbox *ngIf="alert.monitoringType =='NBB' || alert.monitoringType =='DNSA'" [(ngModel)]="alert.check" (change)="onAlertCheck(alert)" disabled></mat-checkbox> <!-- [disabled]="alert.monitoringType =='NBB'" -->
                                <input *ngIf="alert.monitoringType !='NBB' && alert.monitoringType !='DNSA'" type="checkbox" [(ngModel)]="alert.check" (change)="onAlertCheck(alert)"/> <!-- [ngClass]="alert.monitoringType =='NBB' ? 'disable' : 'none'" --> 
                            </span>
                        </td>
                        <td *ngIf="alert.link" style="width: 300px;" > <!-- class="disabled"-->
                            <label>{{ alert.monitoringName}}</label>
                            <span  class="small-text disabled" *ngIf="alert.monitoringType =='NBB'"> ({{ alert.entitlement}})</span>
                        </td>
                        <td *ngIf="alert.link" style="width: 60%;">
                            <a *ngIf = "alert.link == 'upgradePlan'" class="link" href="mailto:cs@experian.com">
                                <span>{{ 'monitor.countryPicAlert.smeNetworkScoreWatchMessage' | translate }}</span>
                            </a>
                            <a *ngIf = "alert.link == 'signUpForPlan'" class="link">
                                <span>{{ 'monitor.countryPicAlert.financialMessage' | translate }}</span>
                            </a>
                            <a *ngIf = "alert.link == 'unAvailable'" class="link-disabled">
                                <span>{{ 'monitor.addManually.manuall_link' | translate }}</span>
                            </a>
                            <span *ngIf = "alert.link == 'Payment'" class="link-disabled">
                                <span>{{ 'monitor.fms.subscribe' | translate }} &nbsp;<a href="mailto:cs@experian.com" style="text-decoration: underline;">{{ 'offlineSearchResult.offlineLinkHere' | translate}}</a></span>
                            </span>
                            <span *ngIf = "alert.link == 'Non_Bank_Bereau_Records'" class="link-disabled">
                                <span>{{ 'monitor.fms.non_bank_bureau' | translate }}</span>
                            </span>
                            <span *ngIf = "alert.link == 'Basic_Upgrade_To_Detailed'" class="link-disabled">
                                <mat-checkbox  [(ngModel)]="alert.check" disabled></mat-checkbox>&nbsp;<span>{{ 'monitor.fms.upgrade_to_detailed' | translate }}</span>
                            </span>
                            <span *ngIf = "alert.link == 'restricted'" class="link-disabled">
                                <span>{{ 'monitor.lms.edit_not_allowed' | translate }}</span>
                            </span>
                            <span *ngIf = "alert.link == 'DNSA'" class="link-disabled">
                                <span>{{ 'monitor.dnsa.edit_not_allowed' | translate }}</span>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="alert.expanded">
                        <td></td>
                        <td> <span class="description">{{alert.description}} </span> </td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="zanna_div row" style="margin-bottom: 10px;display:inline-block;" *ngIf="!isLms">
            <div class="d_12_12 col">
                <button (click)="matDialogRef.close(false)" class="button btn button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="addAlerts()" class="button btn ml-22 ml-22" [disabled]="disabled" [ngClass]="disabled? 'button__basic': 'button__primary'">
                    <span *ngIf="!entity.edit && !isAddChangesToEdit || this.entity?.restore ">{{ 'bulk.upload.confirm' | translate }} </span>
                    <span *ngIf="(entity.edit && !this.entity?.restore)  || isAddChangesToEdit"> {{ 'monitor.countryPicAlert.save_changes' | translate }}</span>
                </button> 
            </div>
        </div>
        <div class="zanna_div row" style="margin-bottom: 10px;display:inline-block;" *ngIf="isLms">
            <div class="d_12_12 col">
                <button (click)="matDialogRef.close(false)" class="button btn button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="lmsDisclaimer()" class="button btn ml-22 ml-22" [disabled]="disabled" [ngClass]="disabled? 'button__basic': 'button__primary'">
                    <span *ngIf="!entity.edit && !isAddChangesToEdit || this.entity?.restore ">{{ 'bulk.upload.confirm' | translate }} </span>
                    <span *ngIf="(entity.edit && !this.entity?.restore)  || isAddChangesToEdit"> {{ 'monitor.countryPicAlert.save_changes' | translate }}</span>
                </button> 
            </div>
        </div>
    </div>
</div>

<div class="dialog-content-wrapper" *ngIf="showLmsDisclaimer">

    <!-- LMS PAYMENT -->
    
    <div mat-dialog-content *ngIf="!lmsAlreadyExist">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text" >
                {{ 'bulk.upload.take_note' | translate }}
            </span>
        </div>

        <div class="zanna_div row" style="margin-bottom: 36px;">
            <div class="grey-color fs-2" *ngIf="lmsCoast" style="margin-bottom: 20px;"> {{ 'monitor.lms.note' | translate }} <strong> ${{ lmsCoast }} {{ 'monitor.lms.note1' | translate }}</strong> {{ 'monitor.lms.note2' | translate }} <strong> {{ 'monitor.lms.90days' | translate }} </strong> {{ 'monitor.lms.note4' | translate }} </div><br />
            <div class="grey-color fs-2" *ngIf="!lmsCoast" style="margin-bottom: 20px;"> {{ 'monitor.lms.note3' | translate }} <strong> {{ 'monitor.lms.90days' | translate }} </strong> {{ 'monitor.lms.note4' | translate }} </div><br />
           
            <table class="table" style="width: 100%;">
                <tr>
                    <th class="table-border-header-first desktop-view" width="204">
                        {{ 'monitor.entityNo' | translate }}
                    </th>
                    <th class="table-border-header border-r-only" width="226">
                        {{ 'monitor.entity_name' | translate }}
                    </th>
                    <th *ngIf="entity.edit == false" class="table-border-header desktop-view" width="200">
                        {{ 'monitor.file' | translate }}
                    </th>
                    <th class="table-border-header-last desktop-view" width="100">
                        {{ 'monitor.country' | translate }}
                    </th>
                </tr>
                <tr *ngIf="entity.edit == false">
                    <td class="table-border-data-first desktop-view">
                       {{ entity.entity_no}}
                    </td>
                    <td class="table-border-data border-r-only" style="word-break: break-word;">
                         {{ entity.entity_name}}
                    </td>
                    <td class="table-border-data desktop-view" style="word-break: break-word;">
                        <span>{{ entity.file }}</span>
                        <!-- <span *ngIf="!entity.file">N.A</span> -->
                    </td>
                    <td class="table-border-data desktop-view" style="word-break: break-all;">
                        <span>Singapore</span>
                    </td>
                </tr>
                <tr *ngIf="entity.edit == true">
                    <td class="table-border-data-first">
                       {{ entity.entityId}}
                    </td>
                    <td class="table-border-data" style="word-break: break-word;">
                         {{ entity.entityName}}
                    </td>
                    <!-- <td class="table-border-data" style="word-break: break-word;">
                        <span>{{ entity.clientFileReference }}</span>
                    </td> -->
                    <td class="table-border-data" style="word-break: break-all;">
                        <span >Singapore</span>
                    </td>
                </tr>                
            </table>
            <div class="table-details" *ngIf="isMobile">
                <div class="detail-container">
                    <label for="">{{ 'monitor.entityNo' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.entity_no}}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.entityId}}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.file' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.file }}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.clientFileReference }}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.country' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        Singapore
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity?.countryName }}
                    </div>
                </div>
            </div>
        </div>
       

        <div class="zanna_div row" style="margin-bottom: 10px;" >
            <div class="d_12_12 col">
                <button (click)="hideDisclaimer()" class="button btn button__basic">
                    {{ 'monitor.lms.back' | translate }}
                </button> 
                <button (click)="addAlerts()" class="button btn ml-22 ml-22" [disabled]="disabled" [ngClass]="disabled? 'button__basic': 'button__primary'">
                    {{ 'bulk.upload.confirm' | translate }}
                </button> <!-- type="submit"-->
            </div>
        </div>
    </div>

    <!-- LMS ALERT ALREADY Exist -->


    <div mat-dialog-content *ngIf="lmsAlreadyExist">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text" >
                {{ 'monitor.Lms_entity_already_exist_title' | translate }}
            </span>
        </div>

        <div class="zanna_div row" style="margin-bottom: 50px;">
            <div class="grey-color" style="margin-bottom: 20px;"> {{ 'monitor.Lms_entity_already_exist_sub_title' | translate }} </div><br />
            <table class="table" style="width: 100%;">
                <tr>
                    <th class="table-border-header-first desktop-view" width="204">
                        {{ 'monitor.entityNo' | translate }}
                    </th>
                    <th class="table-border-header border-r-only" width="226">
                        {{ 'monitor.entity_name' | translate }}
                    </th>
                    <th class="table-border-header desktop-view" width="200">
                        {{ 'monitor.file' | translate }}
                    </th>
                    <th class="table-border-header-last desktop-view" width="100">
                        {{ 'monitor.country' | translate }}
                    </th>
                </tr>
                <tr *ngIf="entity.edit == false">
                    <td class="table-border-data-first desktop-view">
                       {{ entity.entity_no}}
                    </td>
                    <td class="table-border-data border-r-only" style="word-break: break-word;">
                         {{ entity.entity_name}}
                    </td>
                    <td class="table-border-data desktop-view" style="word-break: break-word;">
                        <span>{{ entity.file }}</span>
                    </td>
                    <td class="table-border-data desktop-view" style="word-break: break-all;">
                        <span>Singapore</span>
                    </td>
                </tr>
                <tr *ngIf="entity.edit == true">
                    <td class="table-border-data-first">
                       {{ entity.entityId}}
                    </td>
                    <td class="table-border-data" style="word-break: break-word;">
                         {{ entity.entityName}}
                    </td>
                    <td class="table-border-data" style="word-break: break-word;">
                        <span>{{ entity.clientFileReference }}</span>
                        <!-- <span *ngIf="!entity.clientFileReference">N.A</span> -->
                    </td>
                    <td class="table-border-data" style="word-break: break-all;">
                        <span>Singapore</span>
                    </td>
                </tr>                
            </table>
            <div class="table-details" *ngIf="isMobile">
                <div class="detail-container">
                    <label for="">{{ 'monitor.entityNo' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.entity_no}}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.entityId}}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.file' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        {{ entity.file }}
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity.clientFileReference }}
                    </div>
                </div>
                <div class="detail-container">
                    <label for="">{{ 'monitor.country' | translate }}</label>
                    <div class="details" *ngIf="entity.edit == false">
                        Singapore
                    </div>
                    <div class="details" *ngIf="entity.edit == true">
                        {{ entity?.countryName }}
                    </div>
                </div>
            </div>
        </div>
       

        <div class="zanna_div row" style="margin-bottom: 10px;" >
            <div class="zanna_div row" style="margin-bottom: 10px;" >
                <div class="d_6_12 col">
                    <button (click)="hideDisclaimer()" class="button btn button__primary">
                        {{ 'monitor.lms.back' | translate }}
                    </button> 
                </div>
            </div>    
        </div>
    </div>
</div>