<!-- COMPLETE BULK UPLOAD DIALOG-->

<div *ngIf="paramData.key=='completeBulkUpload'">
    <div style="padding-left: 20px;">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div> 
        <div class="cross-header">
            <span class="confirm-title">
                Complete Bulk Import
            </span>
        </div>  
        <div>
            <span class="grey-color">
                Are you sure you want to complete your bulk import ? Completing your bulk import will remove the bulk import results. Any of the results that were not assigned will be removed.
            </span>
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="dialogRef.close(false)" class="button btn button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="complete()" class="button btn button__primary ml-22 ml-22">
                    Complete Bulk Import
                </button>
            </div>
        </div>
    </div>
</div>

<!-- LMS UNSUBSCRIBES IN 90 DAYS DIALOG -->

<div *ngIf="paramData.key=='lmsUnsuscribes'">
    <div>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div> 
        <div class="cross-header" style="text-align: center;">
            <mat-icon class="icon-warning">
                warning
            </mat-icon>
            <span class="confirm-title" >
                Take Note
            </span>
        </div>  
        <div style="text-align: center; font-size: 16px; font-weight: 400;letter-spacing: normal;">
            You are not allowed to delete your Legal Monitoring Service alert.
            Legal Monitoring Service alert will automatically be removed 90 days after adding.
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_12_12 col" style="text-align: center;">
                <button (click)="dialogRef.close(false)" class="button btn button__primary">
                    Back
                </button> 
            </div>
        </div>
    </div>
</div>


<!-- ADD SINGLE ENTITY TO NAMELIST FROM BULK UPLOAD -->

<div *ngIf="paramData.key=='addSingle'">
    <div>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div> 
        <div class="cross-header" style="text-align: center;">
            <mat-icon class="icon-warning">
                warning
            </mat-icon>
            <span class="confirm-title">
                Confirm Action
            </span>
        </div>  
        <div style="text-align: center;">
            We are not able to find this entity: "{{ paramData.data.userSearchTerm }}" Do you still want to add to your Monitoring Name List?
        </div>
        <div class="zanna_div row pt-30">
            <div class="col" style="text-align: center;">
                <button (click)="dialogRef.close(false)" class="button btn button__basic" style="width: 100px;">
                    No
                </button> 
                <button (click)="dialogRef.close(true)" class="button btn button__primary ml-22 ml-22" style="width: 100px;">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>

<!-- ADD MULTIPLE ENTITY TO NAMELIST FROM BULK UPLOAD -->

<div *ngIf="paramData.key=='addMultiple'">
    <div>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div> 
        <div class="cross-header" style="text-align: center;">
            <mat-icon class="icon-warning">
                warning
            </mat-icon>
            <span class="confirm-title">
                Confirm Action
            </span>
        </div>  
        <div style="text-align: center;">
            We are not able to find the {{ paramData.data }} entities that you have selected. Do you still want to add to your Monitoring Name List?
        </div>
        <div class="zanna_div row pt-30">
            <div class="col" style="text-align: center;">
                <button (click)="dialogRef.close(false)" class="button btn button__basic" style="width: 100px;">
                    No
                </button> 
                <button (click)="dialogRef.close(true)" class="button btn button__primary ml-22 ml-22" style="width: 100px;">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>

