import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { BulkUploadGridService } from '../bulk-upload-grid/bulk-upload-grid.service';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import { LoaderService } from '../services/loader.service';
import { FileHandle } from './dragDrop.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
declare const $: any;

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulkUploadComponent implements OnInit {
  fileToUpload: File;
  fileUploaded: string = 'step1';
  time: number = 1;
  fileName: string = "";
  bulkOrderNumber: any;
  minutes: number = 0;
  loader: any;
  bulkUploadStatus: any;
  cancelUploading: boolean = false;
  productList: any;
  categoryId: any;
  loaded: number = 10;
  mySubscription: Subscription;
  validateSubscription$: Subscription;
  files: FileHandle[] = [];
  validatedBulkUploadeDeta:any={};
  constructor(public matDialogRef: MatDialogRef<BulkUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, public dialog: MatDialog,
    public uploadService: BulkUploadGridService, public translateService: TranslateService,
    public loaderService: LoaderService,  public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer) {
      iconRegistry
		  .addSvgIcon('reupload', sanitizer.bypassSecurityTrustResourceUrl('assets/img/reupload.svg'))
      .addSvgIcon('bulk-upload', sanitizer.bypassSecurityTrustResourceUrl('assets/img/bulk-upload.svg'));

    this.getBulkOrderProductCategory();
  }

  ngOnInit() {
   
  }

  getBulkOrderProductCategory() {
    this.loaderService.isLoading.next(true);
    this.uploadService.getBulkOrderProductCategory().subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success) {
          this.productList = res.data;
          var validatedObj = localStorage.getItem("validation");
				  if (validatedObj) {
					  let obj = JSON.parse(validatedObj);
            if(obj?.iIRGDNReportAccess =='N') {
              this.productList = this.productList.filter((rec: any) => rec.categoryId != 1)
            }
            if(obj?.iIRROCReportAccess =='N') {
              this.productList = this.productList.filter((rec: any) => rec.categoryId != 4)
            }
          }
          if(this.productList.length > 0)
          this.categoryId = this.productList[0].categoryId;
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
      }
    );
  }

  filesDropped(files: FileHandle[]): void {
    this.files = files;
    this.fileUploaded = 'step2';
    this.matDialogRef.updateSize('600px', '300px'); 
		this.time =1;
    this.loaded = 20;
    this.fileName = files[0].file.name;
    this.fileToUpload = files[0].file;
    this.uploadFiles();
    setTimeout(() => { 
      this.loaded = 50;
    }, 4000);
  }

  csvInputChange(fileInputEvent: any) {
    this.fileUploaded = 'step2';
    this.matDialogRef.updateSize('600px', '300px');
		setTimeout(() => {  
		  this.time =1;
      this.loaded = 40;
      this.fileToUpload = fileInputEvent.target.files[0];
      this.fileName = fileInputEvent.target.files[0].name;
      this.uploadFiles();
		}, 4000);    
  }

  cancelFileValidation() {
    this.cancelUploading = true;
    this.loaderService.isLoading.next(true);

  }

  uploadFiles() {  
    this.validateSubscription$ = this.uploadService.upload(this.fileToUpload, this.categoryId).subscribe(
      (res: any) => {
        if(res.success == true && res.data.isValidFile){
          if(this.cancelUploading) {
            this.bulkOrderNumber =  res.data?.bulkOrderNumber;
            this.cancelBulkOrder();
          } else {
            this.validatedBulkUploadeDeta=res.data?.summary;
            this.bulkOrderNumber =  res.data?.bulkOrderNumber;
            if(res?.data?.isLimitExceed) {
              this.matDialogRef.updateSize('600px', 'auto'); 
              this.fileUploaded = 'Exceed';
            } else {
              this.fileUploaded = 'step3'; 
            }
          }
        }
        else{
          if(this.cancelUploading) {
            this.matDialogRef.close(false);
          } else {
            this.fileUploaded = 'invalid';
          }
        }
      },
      (err: any) => {
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.uploadFiles();
            }
          });
        });	
      }
    );    
  }

  cancelBulkOrder() {
    this.loaderService.isLoading.next(true);
    this.uploadService.cancelBulkUpload(this.bulkOrderNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true){
          // this.matDialogRef.updateSize('680px', '499px');
          // this.fileUploaded = 'step1';
          // this.loaded = 10;
          // this.time = 1;
          // this.fileName = "";
          this.matDialogRef.close(false)
        }
        else{ 
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.cancelBulkOrder();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.cancelBulkOrder();
            }
          });
        });
      }
    ); 
  }

  reUploadFiles() {
    this.matDialogRef.updateSize('680px', 'auto');
    this.fileUploaded = 'step1';
    this.loaded = 10;
    this.time = 1;
    this.fileName = "";
  }

  uploadselectedFiles() {
    this.fileUploaded = 'step4'
  }

  runInBackground() {
    var obj: any = { 
      "bulkOrderNumber": this.bulkOrderNumber,
      "bulkOrderStatus": "Uploaded"
    };
    if(this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    this.matDialogRef.close(obj);
  }

  cancelProcess() {
    this.loaderService.isLoading.next(true);
    if(this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    this.uploadService.cancelProcessing(this.bulkOrderNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true) {
          var obj: any = { 
            "bulkOrderNumber": this.bulkOrderNumber,
            "bulkOrderStatus": "Cancelled"
          };
          if(this.mySubscription) {
            this.mySubscription.unsubscribe();
          }
          this.matDialogRef.close(obj);
        } else {
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.cancelProcess();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.cancelProcess();
            }
          });
        });
      }
    ); 
  }

  confirmBulkOrder() {
    this.loaderService.isLoading.next(true);
    this.uploadService.confirmBulkOrder(this.bulkOrderNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true){
          localStorage.removeItem('bulkProcessComplete');
          this.fileUploaded = 'step4'
          this.bulkUpladProcessingStatus();
        } else {
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.confirmBulkOrder();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.confirmBulkOrder();
            }
          });
        });
      }
    );    
  }

  getStatus() {
    this.mySubscription = interval(10000).subscribe((x => {
      this.uploadService.bulkUploadProcessingStatus(this.bulkOrderNumber).subscribe( //this.validatedData
        (res: any) => {
          if(res.success == true && res.data != null){
            this.loaderService.isLoading.next(false);
            this.bulkUploadStatus = JSON.parse(res.data.statusJson);
            this.loader = ( this.bulkUploadStatus.CurrentCount / this.bulkUploadStatus.TotalCount ) * 100;
            var val = this.bulkUploadStatus?.EstimatedTime / 60;
				    this.minutes = Math.round(val);
            if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
              this.mySubscription.unsubscribe();
              this.runInBackground();
            }
          }
        });
    }));
  }

  bulkUpladProcessingStatus() {
    this.loaderService.isLoading.next(true);
		this.uploadService.bulkUploadProcessingStatus(this.bulkOrderNumber).subscribe( //this.validatedData
		(res: any) => {
			if(res.success == true && res.data != null){
        this.loaderService.isLoading.next(false);
				this.bulkUploadStatus = JSON.parse(res.data.statusJson);
				this.loader = ( this.bulkUploadStatus?.CurrentCount / this.bulkUploadStatus?.TotalCount ) * 100;
        var val = this.bulkUploadStatus?.EstimatedTime / 60;
				this.minutes = Math.round(val);
        this.getStatus();
			} else {
        this.getStatus();
      }
		});
	}
}
