import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as go from 'gojs';


@Component({
  selector: 'app-orgs-chart',
  templateUrl: './orgs-chart.component.html',
  styleUrls: ['./orgs-chart.component.scss'],
})
export class OrgsChartComponent implements OnChanges {

  @Input() savedModel: any = [];
  @Output() imageCreated = new EventEmitter<any>();
  myDiagram: go.Diagram;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['savedModel'] && this.savedModel) {
      this.initDiagram();
    }
  }

  initDiagram(): void {
    if (this.myDiagram) {
      this.myDiagram.div = null;
    }

    this.myDiagram = new go.Diagram('myDiagramDiv', {
      allowCopy: false,
      allowDelete: false,
      initialAutoScale: go.AutoScale.Uniform,
      maxSelectionCount: 1,
      validCycle: go.CycleMode.DestinationTree,
      layout: new go.TreeLayout({
        treeStyle: go.TreeStyle.LastParents,
        angle: 270,  // This makes the tree grow upwards
        layerSpacing: 35,
        alignment: go.TreeAlignment.CenterChildren,  // Use the new enumeration for alignment
        alternateAngle: 270,
        alternateLayerSpacing: 35,
        alternateAlignment: go.TreeAlignment.CenterChildren,  // Use the new enumeration for alternate alignment
        alternateNodeSpacing: 20
      }),
      'undoManager.isEnabled': true
    });
    this.myDiagram.addDiagramListener('Modified', (e) => {
      const button = document.getElementById('SaveButton') as HTMLButtonElement;
      if (button) button.disabled = !this.myDiagram.isModified;
      const idx = document.title.indexOf('*');
      if (this.myDiagram.isModified) {
        if (idx < 0) document.title += '*';
      } else {
        if (idx >= 0) document.title = document.title.slice(0, idx);
      }
    });

    function textStyle(obj: any) {
      return Object.assign({ font: '9pt Segoe UI,sans-serif' }, obj || {});
    }

    function findHeadShot(pic: any) {
      let path;
      if (pic == "C" || pic == null) {
        path = '/assets/img/GoJs/Company.png';
      } else {
        path = '/assets/img/GoJs/Individual.png';
      }
      return path;
    }

    this.myDiagram.nodeTemplate = new go.Node('Auto')
      .bind('text', 'name')
      .bind('layerName', 'isSelected', (sel) => (sel ? 'Foreground' : ''))
      .add(
        new go.Shape('RoundedRectangle', {
          name: 'SHAPE',
          fill: 'white',
          stroke: 'black',
          strokeWidth: 2,
        }),
        new go.Panel('Horizontal')
          .add(
            new go.Picture({
              name: 'Picture',
              desiredSize: new go.Size(40, 40),// Match the original image size
              margin: new go.Margin(2, 2, 2, 2),
              source: 'assets/img/GoJs/A.png'
            }).bind('source', 'pic', findHeadShot),
            new go.Panel('Table', {
              maxSize: new go.Size(150, 999),
              margin: new go.Margin(3, 10, 3, 2),
              defaultAlignment: go.Spot.Left
            })
              .addColumnDefinition(2, { width: 4 })
              .add(
                new go.TextBlock(textStyle({
                  name: 'NAMETB',
                  row: 0,
                  column: 0,
                  columnSpan: 5,
                  font: 'bold 12pt Segoe UI, sans-serif',
                  editable: true,
                  isMultiline: false,
                  minSize: new go.Size(10, 16)
                })).bind('text', 'name'),
                new go.TextBlock(textStyle({
                  row: 1,
                  column: 0,
                })).bind('text', 'votingrights'),
                new go.TextBlock(textStyle({ row: 2, column: 0 })).bind('text', 'capitalshares'),
                new go.TextBlock(textStyle({ row: 3, column: 0, margin: new go.Margin(0, 0, 3, 0), })).bind('text', 'effectiveshares'),
              )
          )
      );

    this.myDiagram.linkTemplate = new go.Link({
      routing: go.Routing.Orthogonal,
      corner: 5
    })
      .add(new go.Shape({
        strokeWidth: 3,
        stroke: 'black',  // Change the color to black
      }))
      .add(new go.Shape({
        toArrow: 'Standard',  // Add an arrowhead
        stroke: 'black',      // Arrowhead border color
        fill: 'black'         // Arrowhead fill color
      }));
    this.load();

    setTimeout(() => {
      if (this.myDiagram) {
        const diagramImage: any = this.myDiagram.makeImageData({
          scale: NaN,
          maxSize: new go.Size(3000, 3000),
        });
        const base64String = diagramImage.replace(/^data:image\/[a-z]+;base64,/, '');
        this.imageCreated.emit(base64String);
      }
    }, 3000);
  }

  load(): void {
    const savedModel = this.savedModel;
    if (savedModel) {
      if (!this.myDiagram) {
        this.initDiagram();

      }
      if (this.myDiagram) {
        this.myDiagram.model = go.Model.fromJson(savedModel);
        let lastkey = 1;
        this.myDiagram.model.makeUniqueKeyFunction = (model, data) => {
          let k = data.key || lastkey;
          while (model.findNodeDataForKey(k)) k++;
          data.key = lastkey = k;
          return k;
        };
      }
    }
  }

}


function isAssistant(n: any) {
  if (n === null) return false;
  return n.data.isAssistant;
}

class SideTreeLayout extends go.TreeLayout {
  net: any;
  constructor(init: any) {
    super();
    if (init) Object.assign(this, init);
  }

  makeNetwork(coll: any) {
    const net = super.makeNetwork(coll);
    const vertexcoll = new go.Set();
    vertexcoll.addAll(net.vertexes);
    for (const it = vertexcoll.iterator; it.next();) {
      const parent: any = it.value;
      let acount = 0;
      const ait = parent.destinationVertexes;
      while (ait.next()) {
        if (isAssistant(ait.value.node)) acount++;
      }
      if (acount > 0) {
        const asstedges: any = new go.Set();
        const childedges = new go.Set();
        let eit = parent.destinationEdges;
        while (eit.next()) {
          const e = eit.value;
          if (isAssistant(e.toVertex.node)) {
            asstedges.add(e);
          } else {
            childedges.add(e);
          }
        }
        eit = asstedges.iterator;
        while (eit.next()) {
          parent.deleteDestinationEdge(eit.value);
        }
        eit = childedges.iterator;
        while (eit.next()) {
          parent.deleteDestinationEdge(eit.value);
        }
        if (acount % 2 == 1) {
          const dummy = net.createVertex();
          net.addVertex(dummy);
          net.linkVertexes(parent, dummy, asstedges.first().link);
        }
        eit = asstedges.iterator;
        while (eit.next()) {
          parent.addDestinationEdge(eit.value);
        }
        const subst = net.createVertex();
        net.addVertex(subst);
        eit = childedges.iterator;
        while (eit.next()) {
          const ce = eit.value;
          ce.fromVertex = subst;
          subst.addDestinationEdge(ce);
        }
        const newedge = net.linkVertexes(parent, subst, null);
      }
    }
    this.net = net;
    return net;
  }

  assignTreeVertexValues(v: any) {
    let any = false;
    const children = v.children;
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (isAssistant(c.node)) {
        any = true;
        break;
      }
    }
    if (any) {
      v.alignment = go.TreeAlignment.Bus;
      v.nodeSpacing = 50;
    } else if (v.node == null && v.childrenCount > 0) {
      v.layerSpacing = 0;
    }
  }

  commitLinks() {
    super.commitLinks();
    const eit = this.net.edges.iterator;
    while (eit.next()) {
      const e = eit.value;
      if (e.link == null) continue;
      const r = e.link;
      const subst: any = e.fromVertex;
      if (subst.node == null && r.routing == go.Routing.Orthogonal) {
        r.updateRoute();
        r.startRoute();
        const p1 = subst.center;
        const p2 = r.getPoint(2).copy();
        const p3 = r.getPoint(3).copy();
        const p5 = r.getPoint(r.pointsCount - 1);
        let dist = 10;
        if (subst.angle == 270 || subst.angle == 180) dist = -20;
        if (subst.angle == 90 || subst.angle == 270) {
          p2.y = p5.y - dist;
          p3.y = p5.y - dist;
        } else {
          p2.x = p5.x - dist;
          p3.x = p5.x - dist;
        }
        r.setPoint(2, p2);
        r.setPoint(3, p3);
        r.commitRoute();
      }
    }
  }
}