import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { BulkUploadGridService } from '../bulk-upload-grid.service';

@Component({
	selector: 'bulk-grid-cell-renderer',
	templateUrl: './no-match-popup-cell-renderer.component.html',
	styleUrls: ['./no-match-popup-cell-renderer.component.scss'] 
})

export class NoMatchPopupCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;
	categoryId: any;
	width: any;
	availabilityOptions: any;
	selected: any;
	selectedLang: any;
	selectedReport: any;
	constructor(public uploadService: BulkUploadGridService, public loaderService: LoaderService,
		 public translateService: TranslateService, public dialog: MatDialog) { }

	agInit(params: any): void {
		this.params = params;
		this.width = this.params.column.actualWidth - 10;
		if(this.params.data?.productAvailabilityJson != null)
		this.availabilityOptions = JSON.parse(this.params.data.productAvailabilityJson);
		if(params.label == 'reportType' && this.params.data.availabilityReportType) {
			this.selected = this.params.data.availabilityReportType+" ["+this.params.data.availabilitySpeedDescription+"]";
		}
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	availabilitySelected(availability: any) {
		var Obj = {
			"BulkReportOrderId": this.params.data.id,
			"Scr": availability?.scr,
			"AvailabilityTatSupplier": String(availability?.turnAroundTimeBySupplier),
			"AvailabilityProductPrice": Number(availability?.productPrice),
			"AvailabilitySpeedDescription": availability?.SpeedDescription,
			"AvailabilitySpeedCode": availability?.speedCode,
			"AvailabilityReportType": availability?.reportType,
			"AvailabilityOfferingId": availability?.productOfferingId,
			"Status": "Match_Not_Found"
		};
		this.loaderService.isLoading.next(true);
		this.uploadService.UpdateAvailabilityDetails(Obj).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				this.params.data.availabilityOfferingId = availability?.productOfferingId;
				this.params.data.availabilityProductPrice = availability?.productPrice;
				this.params.data.availabilityReportType = availability?.reportType;
				this.params.data.availabilitySpeedCode = availability?.speedCode;
				this.params.data.availabilitySpeedDescription = availability?.SpeedDescription;
				this.params.data.scr = availability?.scr;
				this.params.data.availabilityTatSupplier = availability?.turnAroundTimeBySupplier;
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		);
	}

	selectTranslation(translation: string) {
		if(translation == "Y") {
			this.apply(this.params.data.id, "Y");
		} else if(translation == "N") {
			this.apply(this.params.data.id, "N");
		}
	}

	apply(bulkOrderItemId: any, status: any) {
		this.loaderService.isLoading.next(true);
		this.uploadService.updateTranslation(bulkOrderItemId, status).subscribe((res: any) => {
			this.loaderService.isLoading.next(false);
			if(res.success) {
				
			} else {
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.apply(bulkOrderItemId, status);
						} else {
							this.params.data.translation = "N";
						}
					});
				});
			}
		},
		(err: any) => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: '37vw',
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.apply(bulkOrderItemId, status);
					}
				});
			});
		});
	}
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	viewMatches(ev: any) {
		this.params['label'] = 'viewMatches';
		this.params.onClick(this.params);
	}

}