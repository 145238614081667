<div id="info">
    <h1 matDialogTitle class="info-title">
        <mat-icon class="icon-info" >
            info
        </mat-icon>
        <span> Already Exist</span>
    </h1>
    <div matDialogContent class="content-msg">
        {{ data.infoMessage }}
    </div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button (click)="dialogRef.close(true)" class="button button__basic">OK</button>

    </div>
</div>