<div id="confirm">
    <h1 matDialogTitle class="confirm-title">
        <mat-icon class="icon-warning">
            warning
        </mat-icon>
        {{ 'confirmAction.confirm' | translate }}
    </h1>
    <div matDialogContent class="content-msg" >
        <div>
            <span style="font-size: 14px;letter-spacing: normal;" class="fs-2">
                {{ 'monitor.addManually.invalid' | translate }}
            </span>
            <!-- <span *ngIf="paramData.isValid" style="font-size: 14px">
                {{ 'monitor.addManually.valid' | translate }}
            </span> -->
            <strong style="font-size: 14px;letter-spacing: normal;" class="fs-2">'{{ paramData.entity_no }}'! </strong> 
            <div style="font-size: 14px;letter-spacing: normal;" class="fs-2">{{ 'monitor.addManually.invalid_confirm' | translate }} </div>
            <!-- <div *ngIf="paramData.isValid" style="font-size: 14px">{{ 'monitor.addManually.valid_confirm' | translate }}</div> -->
        </div> 
    </div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button (click)="dialogRef.close(false)" style="font-weight: 500;" class="button button__basic">
            {{ 'confirmAction.no' | translate }}
        </button>
        <button (click)="dialogRef.close(true)" class="button button__primary ml-22" style="margin-left: 20px;">
            {{ 'confirmAction.yes' | translate }}
        </button>
    </div>
</div>