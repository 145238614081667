import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-already-exist',
  templateUrl: './already-exist.component.html',
  styleUrls: ['./already-exist.component.scss']
})
export class AlreadyExistComponent {
  constructor(public dialogRef: MatDialogRef<AlreadyExistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}
