<mat-card appearance="outlined" class="p-s-r p-0 b-shadow-n">
    <div *ngIf="data.label == 'monitor-alert-filter-popup'" class="filter-popup">
        <div class="filter-title">
            <span>Filter By</span>
        </div>
        <div class="mt-36">
            <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
            <input matInput type="text" [(ngModel)]="searchByEntityName">
            <mat-icon matSuffix class="search-input-icon">search</mat-icon>
        </div>
          
        <div class="mt-27">
            <div class="d-flex">
                <label class="alert-label">{{ 'monitor.alertType' | translate }}</label>
                <div (click)="expandAlertList()">
                    <mat-icon class="download-icon" *ngIf="!isExpand">keyboard_arrow_down</mat-icon>
                    <mat-icon class="download-icon" *ngIf="isExpand">keyboard_arrow_up</mat-icon>
                </div>                
            </div>
            <div *ngIf="isExpand">
                <ul>
                    <!-- Parent Item -->
                    <li *ngFor="let item of allAlerts">  
                    <!-- Parent Checkbox -->
                        <div class="mt-10 d-flex">
                            <label for="alert" class="alert-label">{{item.monitoringType}}</label>
                            <input type="checkbox" class="chekbox-alignment" [(ngModel)]="item.isSelected" value="{{item.monitoringType}}" (ngModelChange)="parentCheck(item)" />
                        </div>
                        <!-- Child -->
                        <div class="child-list">  
                            <ul *ngIf = "item.monitoringClassification[0] != null">
                                <!-- Child Item -->
                                <li class="list-group-item d-flex mt-10" *ngFor="let monitoring of item.monitoringClassification">
                                    <!-- Child Checkbox -->
                                    <label for="alert" class="alert-label">{{monitoring.monitoringClassification}}</label>
                                    <input type="checkbox" class="chekbox-alignment" [(ngModel)]="monitoring.isSelected" value="{{monitoring.monitoringClassification}}" (ngModelChange)="childCheck(item,item.monitoringClassification)" />
                                </li>  
                            </ul>  
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="middle-border"></div>
        <div class="d-flex">
            <label class="alert-label">{{ 'monitor.alertTriggers' | translate }}</label>
            <div (click)="expandAlertTriggerList()">
                <mat-icon class="download-icon" *ngIf="!isExpandTrigger">keyboard_arrow_down</mat-icon>
                <mat-icon class="download-icon" *ngIf="isExpandTrigger">keyboard_arrow_up</mat-icon>
            </div>
            <!-- <ng-select name="alerts" [items]="alertTrigger" bindLabel="alertTrigger" [(ngModel)]="selectedAlertTrigger" (change)="alertTriggerSelect($event)">
            </ng-select> -->
        </div>    
        <div *ngIf="isExpandTrigger">
            <div *ngFor="let alertTrigger of alertTrigger" class="mt-10 d-flex">
                <label for="alert" class="alert-label">{{alertTrigger.alertTrigger}}</label>
                <input type="checkbox" name="alertTrigger" class="chekbox-alignment" (change)="alertTriggerSelect(alertTrigger.alertTrigger,$event)">                    
            </div>
        </div>     
        <div class="middle-border"></div>
        <div fxLayout="row">
            <div fxFlex="50" style="margin: 0 10px 10px 0;">
                <label>{{ 'monitor.dateOfAlert' | translate }}</label><br />
                <input type="text"
                ngxDaterangepickerMd
                [(ngModel)]="startDate"
                [showCustomRangeLabel]="true"
                [lockStartDate]="false"
                [alwaysShowCalendars]="true"
                [singleDatePicker]="true"
                [locale]="locale"
                [ranges]="ranges"
                [linkedCalendars]="false"
                [autoApply]="true"
                [minDate]="startMinDate"
                [maxDate]="maxDate"
                [showClearButton]="false"
                (datesUpdated)="startDateUpdated()"
                [placeholder]="'placeholder.from' | translate" readonly/>
                <mat-icon matSuffix class="search-input-icon ngx-daterangepicker-action" (click)="open($event)">date_range</mat-icon>
            </div>
            <div fxFlex="50" style="margin: 0 0 10px 10px;">
                <label style="padding-bottom: 14px;"></label><br />
                <input type="text" #picker1
                ngxDaterangepickerMd
                [(ngModel)]="endDate"
                [showCustomRangeLabel]="false"
                [alwaysShowCalendars]="true"
                [singleDatePicker]="true"
                [locale]="locale"
                [linkedCalendars]="false"
                [autoApply]="true"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [showClearButton]="false"
                opens="left"
                (datesUpdated)="endDateUpdated()"
                [placeholder]="'placeholder.to' | translate" readonly/>
                 <mat-icon matSuffix  class="search-input-icon open ngx-daterangepicker-action" >date_range</mat-icon>
            </div>
        </div>
       
        <div fxLayout="row">
            <div style="margin: 10px 0;" class="mt-10">
                <button class="button button__primary float-right" (click)="applyFilter(allAlerts)" style="margin: 10px;">
                    Apply
                </button>
                <button (click)="removeFilter()" class="button btn button__basic float-right mt-10" style="margin: 10px;">
                    {{ 'monitor.clear_filter' | translate }}
                </button> 
            </div>
        </div>
    </div>
    <div *ngIf="data.label == 'monitor-all-record-filter-popup'">
        <div style="padding: 50px 16px 36px 16px;">
            <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
            <input matInput type="text" [(ngModel)]="filterValue">
            <mat-icon matSuffix class="search-input-icon">search</mat-icon>
            <div style="margin: 35px 0 0 0;flex: auto;"> 
                <button class="button button__primary m-r-10" (click)="ApplyFilter()">
                    Apply
                </button> 
                <button (click)="RemoveFilter()" class="button btn button__basic">
                    {{ 'monitor.clear_filter' | translate }}
                </button> 
            </div>
        </div>
    </div>
    <div class="close-icon" [ngStyle]="data.label == 'monitor-all-record-filter-popup'?{'top':'20px'}:{'top':'35px'}">
        <i class="fa fa-times" (click)="close()"></i>
    </div>
</mat-card>

