<article >
    <div class="zanna_div row">          
        <div class="data-grid-strip">                   
            <div class="end">
                <div  class="first" (click)="goToPreviouslyDeleted()">
                    <mat-icon class="deleted" svgIcon="deleted"></mat-icon> <span style="color: #fff !important;float: right;"> {{ 'monitor.monitorRecords.viewPreviouslyDeletedEntities' | translate }} </span> 
                </div>
                <div class="middle-button desktop-view" (click) = "export()" >
                    <mat-icon >get_app</mat-icon><span style="color: #fff !important;float: right;"> &nbsp;Export Entities</span>
                </div>
                <div class="last" (click)="minimize = !minimize" *ngIf="!isMobile">
                    <i class="fa fa-sliders content-align font-14" aria-hidden="true"></i>
                    <span class="font-14" style="color: #fff !important;">{{ 'monitor.filter' | translate }}</span>
                    <i class="fa fa-angle-down content-align font-18" aria-hidden="true" *ngIf="minimize"></i>
                    <i class="fa fa-angle-up content-align font-18" aria-hidden="true" *ngIf="!minimize"></i>
                </div>
            </div>
        </div>           
        <div class="btn-data-grid-strip border-b-n">
            <div class="filter-container" (click)="openFilterPopup()" *ngIf="isMobile">
                <i class="fa fa-sliders p-r-8 icon-color" aria-hidden="true"></i>
                <span class="font-14">{{ 'monitor.filter' | translate }}</span>
            </div>
            <div (click)=ExpandAll(allExpand) class="arrow-container" *ngIf="isMobile && records.length>0">
                <mat-icon class="down-arrow-icon icon-color" *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon> <span>{{expandCollapseButtonValue}}</span>
            </div>
        </div>
        <mat-card appearance="outlined" *ngIf="!minimize" >
            <div fxLayout="row">
                <div fxFlex="48.5" style="margin: 10px;">
                    <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
                    <input matInput type="text" [(ngModel)]="filterValue">
                    <mat-icon matSuffix class="search-input-icon">search</mat-icon>
                </div>
                <div style="margin: 35px 10px 10px 10px;flex: auto;">
                    <button class="button button__primary float-right" (click)="ApplyFilter()">
                        {{ 'bulk.upload.apply' | translate }}
                    </button>
 					<button (click)="removeFilter()" class="button btn button__basic float-right" style="margin: 0px 10px;">
                        {{ 'monitor.clear_filter' | translate }}
                    </button> 
                </div>
            </div>        
        </mat-card>  
        <ag-grid-angular 
            class="ag-theme-alpine"
            [id]="gridId"
            [getRowStyle]="getRowStyle"
            [detailRowAutoHeight]="true"
            [rowData]="records"
            [columnDefs]="columnDefs"
            [masterDetail]="true"
            [detailRowHeight]="detailRowHeight"
            [loadingOverlayComponent]="loadingOverlayComponent"
            [loadingOverlayComponentParams]="loadingOverlayComponentParams"
            [detailCellRenderer]="detailCellRenderer"
            (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions"
            [tooltipShowDelay]="tooltipShowDelay"
            (filterChanged)="onFilterChanged($event)"
            [domLayout]="domLayout"
            [icons]="icons">
        </ag-grid-angular>
        <app-monitoring-pagination *ngIf="records.length > 0" 
            [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" (loadNext)="loadNextRecords()" (loadPrev)="loadPreviousRecords()"
            [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="totalRecords" [showing]="showing" [avalilableResults]="avalilableResults">
        </app-monitoring-pagination>  
    </div>
</article>


<!-- EXPORT TEMPLATE-->

<div style="display: none;">   
    <table id="excel-table">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Entity Type </th>
            <th> Available Alerts </th>
            <th> Entity Status </th>
            <th> Client File Reference </th>
        </tr>
      <tr *ngFor="let item of exportData">
        <td> {{ item.entityId }} </td>
        <td> {{ item.entityName }} </td>
        <td> <span *ngIf="item.entityType == 'I'"> Individual</span><span *ngIf="item.entityType == 'C'"> Company</span></td>
        <td> 
            <span *ngFor="let alert of item.availableAlerts"> 
                <span *ngIf="alert.isSubscribed == 1 || alert.isSubscribed == '1'"> {{ alert.monitoringType }} &nbsp;</span>
            </span>
         </td>
        <td> {{ item.entityStatus }} </td>
        <td> 
            <div *ngFor="let alert of item.availableAlerts"> 
                <div *ngIf="(alert.isSubscribed ==1) && (alert.clientFileReference != null)">
                    {{alert.monitoringType}} : {{alert.clientFileReference}}&nbsp;
                </div>	
            </div>
         </td>
      </tr>
    </table>
</div>