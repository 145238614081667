export const environment = {
	production: true,
	
	//server_url: 'https://business-logic-services.internal.cs.iir.dev.sg.coaas.net',
	//search_url: 'https://integration-services-gdn.internal.cs.iir.dev.sg.coaas.net',
	//server_url: 'https://business-logic-services.internal.cs.iir.uat.sg.coaas.net',
	//search_url: 'https://iir-integration-services-gdn.internal.cs.iireport.uat.sg.coaas.net',
	server_url: 'https://iir-business-logic-services.experian.com',
	search_url: 'https://iir-integration-services-gdn.experian.com',
	//server_url: 'https://iir-business-logic-services.internal.cs.iireport.prod.sg.coaas.net',
	//search_url: 'https://iir-integration-services-gdn.internal.cs.iireport.prod.sg.coaas.net',
	questnet_url: 'https://www.questnet.sg',
	ibr360_url: 'https://www.experian-ibr360.com/HomePage.aspx',
	//questnet_url: 'https://uat.questnet.sg',
	//ibr360_url: 'https://uat.experian-ibr360.com/HomePage.aspx',
	ag_grid_key: 'CompanyName=Aquion Pty Ltd_on_behalf_of_Experian (Singapore),LicensedGroup=Experian APAC BI Replatform,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=2,AssetReference=AG-022072,ExpiryDate=15_November_2022_[v2]_MTY2ODQ3MDQwMDAwMA==a09b6e78bd08fd53470aebc475bf2981'
};
