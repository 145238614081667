
<span id="print-section" >
    <app-header [title]="title"></app-header> <!-- (langChange)="changeLanguageGrid($event)" -->
    <app-toast class="position"></app-toast>
    <main>
        <section class="p1">
            <article>
                <div class="zanna_div row mt-60">
                    <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                        {{ 'monitor.fms.title' | translate }}
                        <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true" printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                            <mat-icon class="material-icon icon-color">printer</mat-icon>
                            &nbsp;{{ 'monitor.fms.print_page' | translate }}
                        </button>
                        <button type="button" class="button button__primary " [ngClass] = "count == 0 ? 'disabled' : 'userManual-btn'" (click)="export()" *ngIf="!isMobile">
                            <mat-icon class="material-icon icon-color">get_app</mat-icon>
                            &nbsp;{{ 'monitor.bws.export' | translate }}
                        </button>
                    </h1>
                    <p class="p-subTitle">
                        {{ 'monitor.fms.subtitle1' | translate }} {{ count }} {{ 'monitor.fms.subtitle2' | translate }}
                    </p> 
                </div> 
                <div class="zanna_div row mt-32">
                    <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                        {{ 'monitor.fms.Corporate' | translate }}
                    </h1>
                </div>
                <div class="expand-btn-area"  *ngIf="isMobile && (paymentDetails?.list?.nbbpayment?.corporate.length > 0 || paymentDetails?.list?.nbbpayment?.individual.length > 0)">
                    <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(isExpandList)">
                        <mat-icon *ngIf="!isExpandList">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="isExpandList">keyboard_arrow_up</mat-icon>
                        {{expandCollapseButtonValue}}
                    </button>
                </div> 
                <div class="zanna_div row" *ngIf="!(paymentDetails?.list?.nbbpayment?.corporate.length > 0)">
                    <div >
                        <div class="data-grid-strip">
                            <span style="color: #fff !important;">
                                {{ 'monitor.fms.alert' | translate }} 
                            </span>        
                        </div>
                        <table class="table table-variation" style="width: 100%;">
                            <tr>
                                <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'0.1%'}">{{ 'monitor.fms.no' | translate }}</th>
                                <th class="table-border-header" [ngStyle]="isMobile?{'padding':'10px 16px', 'border-right': '1px solid #D0D0D0'}:{'padding':'10px 20px', 'width': '29%'}">
                                    {{ 'monitor.entityIdentification' | translate }}
                                </th>
                                <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                    {{ 'monitor.fms.dtc' | translate }}
                                </th>
                                <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                    {{ 'monitor.fms.grade' | translate }}
                                </th>
                                <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                    {{ 'monitor.fms.balance' | translate }}
                                </th>
                                <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view border-r-only " style="width: 15%;">
                                    {{ 'monitor.fms.last_paid_amount' | translate }}
                                </th>
                            </tr>
                        </table>
                        <mat-card appearance="outlined">
                            <div class="centered-content">
                                <div>
                                    <mat-icon style="margin-left: 40px;" class="svg-icon" svgIcon="no-record"></mat-icon>
                                    <span style="text-align: center;"><h4> No results found.</h4> </span>
                                </div>
                                <div>

                                </div>
                            </div>  
                        </mat-card>

                        <!-- <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="empty" [columnDefs]="columnDefs" [animateRows]="true" [gridOptions]="gridOptions" [domLayout]="domLayout"></ag-grid-angular> -->
                    </div>
                </div>
                <div *ngIf="(paymentDetails?.list?.nbbpayment?.corporate.length > 0)">
                    <div *ngFor="let category of paymentDetails.list.nbbpayment.corporate;let index=index;">
                        <div *ngIf="category.category == NegativeBlackList">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.balance' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view border-r-only " *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.last_paid_amount' | translate }}
                                    </th>
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                                {{ 'monitor.entityNo' | translate }}. {{ row?.entityId}}
                                            </span><br>
                                        <!-- <span class="delivery-date-format">
                                            {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.creditorName }}
                                        </span><br> -->
                                            <span class="delivery-date-format">
                                                {{ row?.customerName }}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'">  {{ 'monitor.fms.days' | translate }} </span> 
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.alternativeConsumerGrade }}
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row?.outstandingBalance }} <br>
                                            <span *ngIf="row.outstandingBalanceDate && row?.outstandingBalanceDate != 'NIL'">{{ row?.outstandingBalanceDate }} </span>
                                        </td>
                                        <td class="table-border-data border-r-only desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.lastPaidAmount }} <br>
                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details">
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'">  {{ 'monitor.fms.days' | translate }} </span> 
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.alternativeConsumerGrade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.balance' | translate }}</label>
                                                    <div class="details">
                                                        {{ row?.outstandingBalance }} 
                                                        <span *ngIf="row.outstandingBalanceDate && row?.outstandingBalanceDate != 'NIL'">{{ row?.outstandingBalanceDate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.last_paid_amount' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.lastPaidAmount }} 
                                                        <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>                            
                            </table>
                            <br>
                            <br>
                        </div>
                    
                        <div *ngIf="category.category == 'carporate_known_active_borrowing'">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.loan_date' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.amount_of_loan' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.type_of_loan' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.purpose_of_loan' | translate }}
                                    </th>
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '24%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                                {{ row.customerName }}
                                            </span><br>
                                            <span class="delivery-date-format">
                                                {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>      
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.grade }}
                                        </td>
                                        <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.loanDate }}
                                        </td>
                                        <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.AmountOfLoan }}
                                        </td>
                                        <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.TypeOfLoan }}
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.PurposeoFLoan }}
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details">
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.grade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.loan_date' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.loanDate }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.amount_of_loan' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.AmountOfLoan }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.type_of_loan' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.TypeOfLoan }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.purpose_of_loan' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.PurposeoFLoan }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>                            
                            </table>
                            <br>
                            <br>
                        </div>
                    
                        
                        <div *ngIf="category.category == greatorDaysGretorAmount">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.credit_type' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view border-r-only" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.known_credit_explosure' | translate }}
                                    </th>
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                            {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                            </span><br>
                                            <span class="delivery-date-format">
                                                {{ row.customerName }}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.creditType }}                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.grade }}
                                        </td>
                                        <td class="table-border-data desktop-view border-r-only" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.knownCreditExposureBalance }}<br>
                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details">
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.credit_type' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.creditType }}     
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.grade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.knownCreditExposureBalance }} 
                                                        <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <br>
                        </div>
                    
                    
                        <div *ngIf="category.category == gretorDaysLessEqualAmount">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view"  *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.credit_type' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view"  *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view border-r-only" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.known_credit_explosure' | translate }}
                                    </th>                                
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                                {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                            </span><br>
                                            <span class="delivery-date-format">
                                                {{ row.customerName }}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.creditType }}                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.grade }}
                                        </td>
                                        <td class="table-border-data desktop-view border-r-only" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.knownCreditExposureBalance }}<br>                                        
                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details">
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.credit_type' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.creditType }}     
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.grade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.knownCreditExposureBalance }}                                      
                                                        <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <br>
                        </div>
                    
                    
                        <div *ngIf="category.category == LessEqualDaysGretorAmount">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}" >{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.credit_type' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view border-r-only" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.known_credit_explosure' | translate }}
                                    </th>
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                                {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                            </span><br>
                                            <span class="delivery-date-format">
                                                {{ row.customerName }}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.creditType }}                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }} <span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.grade }}
                                        </td>
                                        <td class="table-border-data desktop-view border-r-only" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.knownCreditExposureBalance }}<br>                                        
                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details">
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.credit_type' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.creditType }}     
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }} <span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.grade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.knownCreditExposureBalance }}
                                                        <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>                            
                            </table>
                            <br>
                            <br>
                        </div>
                        
                        
                        <div *ngIf="category.category == LessEqualDaysLessEqualAmount">
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">{{ category.categoryName }}</span>
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.credit_type' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.grade' | translate }}
                                    </th>
                                    <th class="table-border-header desktop-view border-r-only" *ngIf="entitlement == 'Detailed'">
                                        {{ 'monitor.fms.known_credit_explosure' | translate }}
                                    </th>
                                    <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                        
                                    </th>
                                </tr>
                                <tbody *ngFor="let row of category.alertData; let i = index">
                                    <tr>
                                        <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                            {{ i+1 }}
                                        </td>
                                        <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                            <span class="delivery-date-format">
                                                {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                            </span><br>
                                            <span class="delivery-date-format">
                                                {{ row.customerName }}
                                            </span><br>
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.creditType }}                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                        </td>
                                        <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.grade }}
                                        </td>
                                        <td class="table-border-data desktop-view border-r-only" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                            {{ row.knownCreditExposureBalance }}<br>
                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                        </td>
                                        <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                            <div (click)="expandCorporate(index,i)" class="expand-icon">
                                                <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                            </div>
                                        </td>                                    
                                    </tr>
                                    <tr *ngIf="isMobile && row.isExpand">
                                        <td colspan="3" style="padding:0 !important">
                                            <div class="table-details" *ngIf="entitlement == 'Detailed'">
                                                <div class="detail-container">
                                                    <label for="">{{ 'monitor.fms.credit_type' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.creditType }}     
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.grade' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.grade }}
                                                    </div>
                                                </div>
                                                <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                    <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                    <div class="details">
                                                        {{ row.knownCreditExposureBalance }}
                                                        <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>                        
                                    </tr>
                                </tbody>                           
                            </table>
                            <br>
                            <br>
                        </div>
                    </div>
                </div> 

                <!--  INDIVIDUAL TABLES-->

                <div>
                    <div class="zanna_div row mt-32">
                        <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                            {{ 'monitor.individual' | translate }}
                        </h1>
                    </div>
                    <div class="zanna_div row" *ngIf="!(paymentDetails?.list?.nbbpayment?.individual.length > 0)">
                        <div>
                            <div class="data-grid-strip">
                                <span style="color: #fff !important;">
                                    {{ 'monitor.fms.alert' | translate }} 
                                </span>        
                            </div>
                            <table class="table table-variation" style="width: 100%;">
                                <tr>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'0.1%'}">{{ 'monitor.fms.no' | translate }}</th>
                                    <th class="table-border-header" [ngStyle]="isMobile?{'padding':'10px 16px', 'border-right': '1px solid #D0D0D0'}:{'padding':'10px 20px', 'width': '29%'}">
                                        {{ 'monitor.entityIdentification' | translate }}
                                    </th>
                                    <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                        {{ 'monitor.fms.dtc' | translate }}
                                    </th>
                                    <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                        {{ 'monitor.fms.alternative_grade' | translate }}
                                    </th>
                                    <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view" style="width: 15%;">
                                        {{ 'monitor.fms.balance' | translate }}
                                    </th>
                                    <th *ngIf="entitlement =='Detailed'" class="table-border-header desktop-view border-r-only " style="width: 15%;">
                                        {{ 'monitor.fms.last_paid_amount' | translate }}
                                    </th>
                                </tr>
                            </table>
                            <mat-card appearance="outlined">
                                <div class="centered-content">
                                    <div>
                                        <mat-icon style="margin-left: 40px;" class="svg-icon" svgIcon="no-record"></mat-icon>
                                        <span style="text-align: center;"><h4> No results found.</h4> </span>
                                    </div>
                                    <div>
    
                                    </div>
                                </div>  
                            </mat-card>    
                            <!-- <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="empty" [columnDefs]="columnDefsIndividual" [animateRows]="true" [gridOptions]="gridOptions" [domLayout]="domLayout"></ag-grid-angular> -->
                        </div>
                    </div>
                    <div *ngIf="paymentDetails?.list?.nbbpayment?.individual.length > 0">
                        <div *ngFor="let category of paymentDetails.list.nbbpayment.individual;let index=index;">
                            <div *ngIf="category.category == NegativeBlackList">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.balance' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view border-r-only" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.last_paid_amount' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.passportNumber' | translate }}. {{ row.entityId}}
                                                </span><br>
                                            <!-- <span class="delivery-date-format">
                                                {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.creditorName }}
                                            </span><br> -->
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                            
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.alternativeConsumerGrade }}
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row?.outstandingBalance }} <br>
                                                <span *ngIf="row.outstandingBalanceDate && row?.outstandingBalanceDate != 'NIL'">{{ row?.outstandingBalanceDate }} </span>
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.lastPaidAmount }} <br>
                                                <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for=""> {{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span> 
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.alternativeConsumerGrade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.balance' | translate }}</label>
                                                        <div class="details">
                                                            {{ row?.outstandingBalance }} <br>
                                                            <span *ngIf="row.outstandingBalanceDate && row?.outstandingBalanceDate != 'NIL'">{{ row?.outstandingBalanceDate }} </span> 
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.last_paid_amount' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.lastPaidAmount }} <br>
                                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>

                            <div *ngIf="category.category == 'carporate_known_active_borrowing'">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.loan_date' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.amount_of_loan' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.type_of_loan' | translate }}
                                        </th>
                                        <th class="table-border-header border-r-only desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.purpose_of_loan' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" *ngIf="entitlement == 'Detailed'" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>
                                            </td>
                                            <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.grade }}
                                            </td>
                                            <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.loanDate }}
                                            </td>
                                            <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.AmountOfLoan }}
                                            </td>
                                            <td class="table-border-data desktop-view" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.TypeOfLoan }}
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style="width: 15%;word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.PurposeoFLoan }}
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span> 
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.grade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.loan_date' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.loanDate }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.amount_of_loan' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.AmountOfLoan }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.type_of_loan' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.TypeOfLoan }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.purpose_of_loan' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.PurposeoFLoan }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>

                            
                            <div *ngIf="category.category == greatorDaysGretorAmount">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.credit_type' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view border-r-only " *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.known_credit_explosure' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                                </span><br>
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.creditType }}                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.grade }}
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style=" width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.knownCreditExposureBalance }}<br>
                                                <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for=""> {{ 'monitor.fms.credit_type' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.creditType }}  
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.grade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.knownCreditExposureBalance }}
                                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>


                            <div *ngIf="category.category == gretorDaysLessEqualAmount">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.credit_type' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header border-r-only desktop-view"  *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.known_credit_explosure' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                                </span><br>
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.creditType }}                                   
                                            </td>
                                            <td class="table-border-data desktop-view " style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.grade }}
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.knownCreditExposureBalance }}<br>
                                                <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for=""> {{ 'monitor.fms.credit_type' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.creditType }}  
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.grade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.knownCreditExposureBalance }}
                                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>


                            <div *ngIf="category.category == LessEqualDaysGretorAmount">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.credit_type' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view " *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view border-r-only " *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.known_credit_explosure' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                                </span><br>
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.creditType }}                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.grade }}
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.knownCreditExposureBalance }}<br>
                                                
                                                <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for=""> {{ 'monitor.fms.credit_type' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.creditType }}  
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.grade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.knownCreditExposureBalance }}
                                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>


                            <div *ngIf="category.category == LessEqualDaysLessEqualAmount">
                                <div class="data-grid-strip">
                                    <span style="color: #fff !important;">{{ category.categoryName }}</span>
                                </div>
                                <table class="table table-variation" style="width: 100%;">
                                    <tr>
                                        <th class="table-border-header" [ngStyle]="isMobile?{'width':'30px'}:{'width':'6%'}">{{ 'monitor.fms.no' | translate }}</th>
                                        <th class="table-border-header" [ngClass]="entitlement == 'Detailed'? 'detailed': 'basic'">
                                            {{ 'monitor.entityIdentification' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.credit_type' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.dtc' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view" *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.alternative_grade' | translate }}
                                        </th>
                                        <th class="table-border-header desktop-view border-r-only " *ngIf="entitlement == 'Detailed'">
                                            {{ 'monitor.fms.known_credit_explosure' | translate }}
                                        </th>
                                        <th class="table-border-header text-center mobile-view" width="10" style="border-right: 1px solid #D0D0D0;">
                                            
                                        </th>
                                    </tr>
                                    <tbody *ngFor="let row of category.alertData; let i = index">
                                        <tr>
                                            <td class="table-border-data" style="word-break: break-word;padding: 10px 0 10px 22px !important;vertical-align: baseline;">
                                                {{ i+1 }}
                                            </td>
                                            <td class="table-border-data" [ngStyle]="isMobile?{'padding':'10px 16px'}:{'padding':'10px 20px', 'width': '29%'}" style="word-break: break-word;padding: 10px 20px !important;vertical-align: baseline;">
                                                <span class="delivery-date-format">
                                                    {{ 'monitor.entityNo' | translate }}. {{ row.entityId}}
                                                </span><br>
                                                <span class="delivery-date-format">
                                                    {{ row.customerName }}
                                                </span><br>
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.creditType }}                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                            </td>
                                            <td class="table-border-data desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.grade }}
                                            </td>
                                            <td class="table-border-data border-r-only desktop-view" style="width: 15%; word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;" *ngIf="entitlement == 'Detailed'">
                                                {{ row.knownCreditExposureBalance }}<br>
                                                <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                            </td>
                                            <td class="table-border-data border-r-only text-center mobile-view" *ngIf="entitlement == 'Detailed'">
                                                <div (click)="expandIndividual(index,i)" class="expand-icon">
                                                    <mat-icon class="expand-icon" *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                                                    <mat-icon class="expand-icon" *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isMobile && row.isExpand">
                                            <td colspan="3" style="padding:0 !important">
                                                <div class="table-details">
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for=""> {{ 'monitor.fms.credit_type' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.creditType }}  
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.dtc' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.dtc }}<span *ngIf="row.dtc && row.dtc!='N.A.'"> {{ 'monitor.fms.days' | translate }} </span>                                   
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.alternative_grade' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.grade }}
                                                        </div>
                                                    </div>
                                                    <div class="detail-container" *ngIf="entitlement == 'Detailed'">
                                                        <label for="">{{ 'monitor.fms.known_credit_explosure' | translate }}</label>
                                                        <div class="details">
                                                            {{ row.knownCreditExposureBalance }} 
                                                            <span *ngIf="row.lastKnownDate == 'NIL'"></span> <!-- {{ row.lastKnownDate }} -->
                                                            <span *ngIf="row.lastKnownDate != 'NIL'">({{ row.lastKnownDate }}) </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                </div> 
            </article>
        </section>
    </main>
</span>
<div style="display: none;" *ngIf="entitlement == 'Detailed'">   
    <table id="excel-table-nbb">
      <tr>
        <th> Record Type </th>
        <th> Entity Name </th>
        <th> Identification / Entity Number  </th>
        <th> Entity Type </th>
        <th> Credit Type </th>
        <th> Debt Turn Cash (DTC) </th>
        <th> Credit Payment Grade </th>
        <th> Alternative Consumer Grade </th>
        <th> Known Credit Exposure Balance O/S $</th>
        <th> Known Credit Exposure Balance O/S Date** </th>
      </tr>
      <tr *ngFor="let item of exportNBB">
        <td> {{ item.RecordType }} </td>
        <td> {{ item.CustomerName }} </td>
        <td> {{ item.EntityId }} </td>
        <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
        <td> {{ item.CreditType }} </td>
        <td> {{ item.DebtsTurnCash }} </td>
        <td> {{ item.CreditPaymentGrade }} </td>
        <td> {{ item.alternativeConsumerGrade }} </td>
        <td> {{ item.KnownCreditExposure }} </td>
        <td> {{ item.OutstandingBalanceDate }} </td>
      </tr>
    </table>
  </div>

  <div style="display: none;" *ngIf="entitlement == 'Detailed'">   
    <table id="excel-table-nbl">
        <tr>
            <th> Record Type </th>
            <th> Entity Name </th>
            <th> Identification / Entity Number  </th>
            <th> Entity Type </th>
            <th> Credit Type </th>
            <th> Debt Turn Cash (DTC) </th>
            <th> Credit Payment Grade </th>
            <th> Alternative Consumer Grade </th>
            <th> Balance O/S $ </th>
            <th> Balance O/S $ Date** </th>
            <th> Last Paid Amount</th>
            <th> Last Paid Amount Date** </th>
          </tr>
          <tr *ngFor="let item of exportNBL">
            <td> {{ item.RecordType }} </td>
            <td> {{ item.CustomerName }} </td>
            <td> {{ item.EntityId }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.CreditType }} </td>
            <td> {{ item.DebtsTurnCash }} </td>
            <td> {{ item.CreditPaymentGrade }} </td>
            <td> {{ item.alternativeConsumerGrade }} </td>
            <td> {{ item.OutstandingBalance }} </td>
            <td> {{ item.OutstandingBalanceDate }} </td>
            <td> {{ item.LastPaidAmount }} </td>
            <td> {{ item.LastKnownDate }} </td>
          </tr>
    </table>
  </div>

  <div style="display: none;" *ngIf="entitlement == 'Basic'">   
    <table id="excel-table">
      <tr>
        <th> Record Type </th>
        <th> Entity Name </th>
        <th> Entity Number </th>
      </tr>
      <tr *ngFor="let item of exportNBB">
        <td> {{ item.RecordType }} </td>
        <td> {{ item.CustomerName }} </td>
        <td> {{ item.EntityId }} </td>
      </tr>
    </table>
  </div>
<app-footer></app-footer>