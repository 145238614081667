import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'bulk-grid-cell-renderer',
	template: `
				<ng-template #tipContent1 >
					<div class="custom-multi-tooltip">
						<div>
							{{ 'bulk.upload.downtime_disclaimer' | translate }}
						</div>
						<mat-icon class="tooltip-arrow-custom">arrow_drop_down</mat-icon>
					</div>
				</ng-template>
				<div *ngIf="params.label == 'address'">
					<span>
						<div>{{ params.data?.countryName }} <span [ngbTooltip]="tipContent1" container="body" placement="top" class="icon" *ngIf="params.data.downtime == true"><mat-icon style="position: absolute; font-size: 22px; padding: 2px;">error_outline</mat-icon></span></div>
					</span>
					<span *ngIf="params.data?.searchStreet">
						<div>{{ params.data?.searchStreet }} </div>
					</span>
					<span *ngIf="params.data?.searchCity">
						{{ params.data?.searchCity }}
					</span>
					<span *ngIf="params.data?.searchPostCode">
						{{ params.data?.searchPostCode }} 
					</span>
				</div>`,
	styles: ['.link { color:#426DA9; text-decoration: underline; cursor: pointer;}', '.icon { font-size: 21px; color: #426DA9 }', '.custom-multi-tooltip { padding: 15px !important; cursor: pointer; color: white; background-color: black; z-index: 999999; opacity: 1; border-radius: 6px; width: 20vw;}', '.tooltip-arrow-custom { position: absolute; bottom: -13px; right: 47%; color: black; }', '.green { background-color: #5CD08E; color: white;}', '.yellow { background-color: #FEBA00; color: white;}', '.red { background-color: #F66C86; color: white;}', '.text {font-size: 14px;}']
})

export class BulkCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor() { }

	agInit(params: any): void {
		this.params = params;
		console.log("refressing.")
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	viewMatches(ev: any) {
		this.params['label'] = 'viewMatches';
		this.params.onClick(this.params);
	}

}