<app-header (langChange)="changeLanguage($event)" [title]="title"></app-header>
<main class="p1">
    <section>
        <!--KEY PRODUCT HIGHLIGHTS -->
        <app-key-products></app-key-products>
        <!-- SEARCH FOR REPORT -->
        <article id="searchDetailsId" class="before-selection">
            <div class="zanna_div row">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'home.searchForReport' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'searchForReport.subInfo' | translate }}
                </p>
            </div>
            <div class="zanna_div row">
                <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                    <article class="zanna_table search-table" style="margin-bottom: 0px !important;">
                        <div class="zanna_div table-head gradient"></div>
                        <div class="zanna_div row">
                            <div class="d_4_12 col">
                                <label>{{ 'resultForm.country' | translate }}*</label><br />
                                <ng-select name="country" class="f-s-2" (change)="changeCountry()"
                                    formControlName="countryCode" [items]="countryList" bindLabel="countryName"
                                    bindValue="countryCode" [searchFn]="customSearchFn">
                                </ng-select>
                                <div class="zanna_div" style="margin-left: 10px;" *ngIf="selectedCountry != undefined">
                                    <div
                                        [hidden]="selectedCountry == undefined ||selectedCountry.countryIncludes == null || selectedCountry.countryIncludes == ''">
                                        <strong> Includes {{ selectedCountry.countryIncludes }}
                                        </strong>
                                    </div>
                                </div>
                            </div>

                            <div class="d_4_12 col p-t-12">
                                <label>{{ 'resultForm.productCategory' | translate }}*</label><br />
                                <ng-select name="product" class="f-s-2" (change)="changeProduct()"
                                    formControlName="categoryId" [items]="productList"
                                    placeholder="Please select a product" bindLabel="categoryName"
                                    bindValue="categoryId">
                                </ng-select>
                                <div class="zanna_div sub-links" *ngIf="!isMobile">
                                    <a class="get-info" *ngIf="searchForm.controls['categoryId'].value === 1"
                                        style="display: inline-block; cursor: pointer;"
                                        (click)="sampleReport('SGPEXT')">{{ 'home.getInfo' | translate }}</a>
                                    <a class="get-info" *ngIf="searchForm.controls['categoryId'].value === 4"
                                        style="display: inline-block; cursor: pointer;"
                                        (click)="sampleReport('AFGROC')">{{ 'home.getInfo' | translate }}</a>
                                </div>
                                <div class="zanna_div sub-links" *ngIf="isMobile">
                                    <a id="blob-src-test-mobile" class="get-info"
                                        *ngIf="searchForm.controls['categoryId'].value === 1"
                                        style="display: inline-block; cursor: pointer;"
                                        (click)="sampleReportForMoile('SGPEXT')" download="">{{ 'home.getInfo' |
                                        translate }}</a>
                                    <a id="blob-src-test-mobile" class="get-info"
                                        *ngIf="searchForm.controls['categoryId'].value === 4"
                                        style="display: inline-block; cursor: pointer;"
                                        (click)="sampleReportForMoile('AFGROC')" download="">{{ 'home.getInfo' |
                                        translate }}</a>
                                </div>
                                <span class="error"
                                    *ngIf="isSearchSubmitted && searchForm.controls['categoryId'].errors">
                                    *{{ 'formError.fieldRequired' | translate }}
                                </span>
                                <div *ngIf="searchForm.controls['categoryId'].value===7" style="width: 200%;">
                                    <i>
                                        {{'home.note' | translate}}
                                    </i>
                                </div>
                            </div>

                            <div class="d_4_12 col desktop-view"
                                *ngIf="(bulkuploadLinkStatus?.bulkOrderStatus != 'Processing' && bulkuploadLinkStatus?.bulkOrderStatus != 'Uploaded') || bulkuploadLinkStatus == null">
                                <div class="float-right">
                                    <a class=" browse pos mt-12" (click)="bulkUpload()">
                                        <mat-icon class="download-icon">upload</mat-icon>
                                        <div class=" float-right upload-link">
                                            {{ 'bulk.upload.orders' | translate }}
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div class="zanna_div row compulsory-fields">
                            <div class="d_4_12 col" *ngFor="let cField of dynamicFields.compulsory">
                                <label class="b-top">{{cField.label}}*</label><br />
                                <div [ngSwitch]="cField.controlType">
                                    <input *ngSwitchCase="'textbox'" (blur)="checkValidation()"
                                        [formControlName]="cField.key" [name]="cField.name" [id]="cField.key"
                                        [type]="cField.type" [placeholder]="cField.placeholder">

                                    <input *ngSwitchCase="'number'" [formControlName]="cField.key" [name]="cField.name"
                                        [id]="cField.key" [type]="cField.type" [placeholder]="cField.placeholder">

                                    <select [id]="cField.key" *ngSwitchCase="'dropdown'" [formControlName]="cField.key">
                                        <option *ngFor="let opt of cField.options" [value]="opt.key">
                                            {{ opt.value }}
                                        </option>
                                    </select>
                                </div>

                                <span class="error"
                                    *ngIf="isSearchSubmitted && !max120Characters && searchForm.controls[cField.key]?.errors && !entityEnglishValidation">
                                    *{{ 'formError.fieldRequired' | translate }}
                                </span>
                                <span class="error" *ngIf="maxValidation">
                                    *{{ 'formError.charactersRequired' | translate }}
                                </span>
                                <span class="error" *ngIf="max120Characters">
                                    *{{ 'formError.max120CharsRequired' | translate }}
                                </span>
                                <span class="error" *ngIf="entityEnglishValidation">
                                    *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                                </span>
                            </div>
                        </div>

                        <div *ngIf="getAdditionalAvailability()">
                            <h3 class="additional-search">
                                <a (click)="additionalSearch()">
                                    {{ 'searchForReport.additionalFields' | translate }}
                                </a>
                            </h3>
                            <div class="zanna_div row">
                                <div class="col">
                                    <div class="zanna_div row hidden additional-search-criterias">
                                        <div class="d_4_12 col p-b-12" *ngFor="let aField of dynamicFields.additional">
                                            <label>{{aField.label}}</label><br />
                                            <div [ngSwitch]="aField.controlType">
                                                <input *ngSwitchCase="'textbox'" (blur)="checkValidation()"
                                                    [formControlName]="aField.key" [name]="aField.name"
                                                    [id]="aField.key" [type]="aField.type"
                                                    [placeholder]="aField.placeholder">

                                                <select [id]="aField.key" *ngSwitchCase="'dropdown'"
                                                    [formControlName]="aField.key">
                                                    <option *ngFor="let opt of aField.options" [value]="opt.key">
                                                        {{ opt.value }}
                                                    </option>
                                                </select>
                                            </div>

                                            <span class="error"
                                                *ngIf="alphabetValidation && searchForm.controls[aField.key].errors && aField.key == 'city_state'">
                                                *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                                            </span>
                                            <span class="error"
                                                *ngIf="maxCityValidation && searchForm.controls[aField.key].errors && aField.key == 'city_state'">
                                                *{{ 'formError.max50Characters' | translate }}
                                            </span>
                                            <span class="error"
                                                *ngIf="maxLengthFileRef && searchForm.controls[aField.key].errors && aField.key == 'entity_num'">
                                                *{{ 'formError.max20Characters' | translate }}
                                            </span>
                                            <span class="error"
                                                *ngIf="fileRefAllowOnlyAlphabets && searchForm.controls[aField.key].errors && aField.key == 'entity_num'">
                                                *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="zanna_div row m-t-2">
                            <div class="d_2_12 col">
                                <button type="submit" class="button button__primary browse browser-list"
                                    [ngClass]="{'button__disabled': isSearchSubmitted && searchForm.invalid}">
                                    <mat-icon class="material-icon">search</mat-icon>
                                    {{ 'resultForm.btn.browseList' | translate }}
                                </button>
                            </div>
                        </div>
                    </article>
                </form>
            </div>
        </article>

        <!-- ORDER DETAILS -->
        <article class="after-selection hidden">
            <div id="orderDetailsId">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{'home.orderDetails' | translate}}
                </h1>
                <p class="p-subTitle">
                    {{ 'orderDetails.subInfo' | translate }}
                </p>
            </div>
            <!--  ORDER DETAILS Table -->
            <article>
                <p style="float: right; margin-right: 5px;">
                    <a (click)="onChangeSelection()" class="a-link">
                        {{ 'orderDetails.changeSelection' | translate }}
                    </a>
                </p>
                <div class="zanna_div row selected-result">
                    <table class="table" style="width: 100%;" *ngIf="!isMobile">
                        <tr>
                            <th class="table-border-header-first" width="204">
                                {{ 'selectionTable.country' | translate }}
                            </th>
                            <th class="table-border-header" width="226">
                                {{ 'selectionTable.productCategory' | translate }}
                            </th>
                            <th class="table-border-header" width="265">
                                {{ 'selectionTable.entityName' | translate }}
                            </th>
                            <th class="table-border-header-last" width="40">
                                {{ 'selectionTable.action' | translate }}
                            </th>
                        </tr>
                        <tr>
                            <td class="table-border-data-first" *ngIf="selectedCountry != undefined">
                                {{ selectedCountry.countryName }}
                            </td>
                            <td class="table-border-data">
                                {{ selectedProductCategory.categoryName }}
                            </td>
                            <td *ngIf="!isOfflineReport" class="table-border-data">
                                {{ selectedResult.name }}
                            </td>
                            <td *ngIf="isOfflineReport" class="table-border-data" style="word-break: break-all;">
                                {{ offlineResult.entityName }}
                            </td>
                            <td class="table-border-data-last">
                                <div style="display: inline-flex; align-items: center;">
                                    <img class="img-acion-arrow"
                                        [ngClass]="{'arrow-down' : isAddressShow, 'arrow-up' : !isAddressShow }"
                                        (click)="expandRow()" src="../../assets/img/arrow-down.svg">
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div *ngIf="isAddressShow && !isMobile" class="address-row">
                        <table class="table" style="width: 100%;">
                            <tr>
                                <th style="background: #fff;" width="400">
                                    {{ 'selectionTable.address' | translate }}
                                </th>
                                <th style="background: #fff;" width="250">
                                    {{ 'selectionTable.entityContactNumber' | translate }}
                                </th>
                                <th style="background: #fff;" width="250">
                                    {{ 'selectionTable.fileRef' | translate }}
                                </th>
                            </tr>
                            <tr>
                                <td style="border: none; word-break: break-all;">
                                    <span *ngIf="isOfflineReport">
                                        {{ offlineResult.entityAddressLine }}, {{ offlineResult.city }}
                                    </span>
                                    <span *ngIf="!isOfflineReport">
                                        {{ getParsedAddress(selectedResult.address) }}
                                    </span>
                                </td>
                                <td style="border: none;">
                                    <span *ngIf="isOfflineReport">
                                        <span *ngIf="offlineResult.entityPhoneNumber">
                                            {{ offlineResult.entityPhoneNumber }}
                                        </span>
                                        <span *ngIf="!offlineResult.entityPhoneNumber">
                                            N.A.
                                        </span>
                                    </span>
                                    <span *ngIf="!isOfflineReport">
                                        N.A.
                                    </span>
                                </td>
                                <td style="border: none;">
                                    <span *ngIf="isOfflineReport">
                                        <span *ngIf="offlineResult.fileRef">
                                            {{ offlineResult.fileRef }}
                                        </span>
                                        <span *ngIf="!offlineResult.fileRef">
                                            N.A.
                                        </span>
                                    </span>
                                    <span *ngIf="!isOfflineReport">
                                        <span *ngIf="clientRefNo">
                                            {{ clientRefNo }}
                                        </span>
                                        <span *ngIf="!clientRefNo">
                                            N.A.
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="custom-table-details" *ngIf="isMobile">
                        <div class="data-container border-t-n">
                            <label for="">{{ 'selectionTable.country' | translate }}</label>
                            <div class="details">
                                {{ selectedCountry.countryName }}
                            </div>
                        </div>
                        <div class="data-container">
                            <label for="">{{ 'selectionTable.productCategory' | translate }}</label>
                            <div class="details">
                                {{ 'selectionTable.productCategory' | translate }}
                            </div>
                        </div>
                        <div class="data-container">
                            <label for="">{{ 'selectionTable.entityName' | translate }}</label>
                            <div class="details">
                                <span *ngIf="!isOfflineReport">
                                    {{ selectedResult.name }}
                                </span>
                                <span *ngIf="isOfflineReport">
                                    {{ offlineResult.entityName }}
                                </span>
                            </div>
                        </div>
                        <div class="data-container">
                            <label for="">{{ 'selectionTable.address' | translate }}</label>
                            <div class="details">
                                <span *ngIf="isOfflineReport">
                                    {{ offlineResult.entityAddressLine }}, {{ offlineResult.city }}
                                </span>
                                <span *ngIf="!isOfflineReport">
                                    {{ getParsedAddress(selectedResult.address) }}
                                </span>
                            </div>
                        </div>
                        <div class="data-container">
                            <label for="">{{ 'selectionTable.entityContactNumber' | translate }}</label>
                            <div class="details">
                                <span *ngIf="isOfflineReport">
                                    <span *ngIf="offlineResult.entityPhoneNumber">
                                        {{ offlineResult.entityPhoneNumber }}
                                    </span>
                                    <span *ngIf="!offlineResult.entityPhoneNumber">
                                        N.A.
                                    </span>
                                </span>
                                <span *ngIf="!isOfflineReport">
                                    N.A.
                                </span>
                            </div>
                        </div>
                        <div class="data-container">
                            <label for="">{{ 'selectionTable.fileRef' | translate }}</label>
                            <div class="details">
                                <span *ngIf="isOfflineReport">
                                    <span *ngIf="offlineResult.fileRef">
                                        {{ offlineResult.fileRef }}
                                    </span>
                                    <span *ngIf="!offlineResult.fileRef">
                                        N.A.
                                    </span>
                                </span>
                                <span *ngIf="!isOfflineReport">
                                    <span *ngIf="clientRefNo">
                                        {{ clientRefNo }}
                                    </span>
                                    <span *ngIf="!clientRefNo">
                                        N.A.
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ORDER DETAILS disclaimer -->
                <div class="zanna_div row report-variation" style="width: 80%;">
                    <p style="margin-right: 5px;">
                        <strong>{{ 'variationTable.selectReportType' | translate }}</strong>
                        <span *ngIf="!isOfflineReport" class="sub-title">
                            {{ 'variationTable.disclaimer' | translate }}
                        </span>
                        <span *ngIf="isOfflineReport" class="sub-title">
                            {{ 'variationTable.offlineDisclaimer' | translate }}
                        </span>
                        <a *ngIf="!isMobile && !isOfflineReport && !viewReportDetails" (click)="viewComparison()"
                            class="a-link">
                            {{ 'variationTable.viewComparison' | translate }}
                        </a>
                        <a *ngIf="!isOfflineReport && viewReportDetails" (click)="viewComparison()" class="a-link">
                            {{ 'variationTable.viewInfo' | translate }}
                        </a>
                    </p>
                    <!-- Report Variation Table -->
                    <span class="error" *ngIf="reportVariationError">
                        *{{ 'variationTable.selectOne' | translate }}
                    </span>
                    <div class="data-grid-strip" style="width: 100%;">
                        <span *ngIf="selectedOfferingId">1 {{ 'grid.rowsSelected' | translate }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr class="desktop-view">
                            <th class="table-border-header" width="42"></th>
                            <th class="table-border-header" width="195">
                                {{ 'variationTable.reportType' | translate }}
                            </th>
                            <th class="table-border-header" width="195">
                                {{ 'variationTable.turnaroundTime' | translate }}
                            </th>
                            <th class="table-border-header" width="134">
                                {{ 'variationTable.priceInSGD' | translate }} ({{currency}})
                            </th>
                            <th *ngIf="!isOfflineReport" class="table-border-header" width="195">
                                {{ 'variationTable.reportFreshness' | translate }}
                            </th>
                            <th class="table-border-header border-r-only" width="115"></th>
                        </tr>
                        <tbody *ngFor="let result of reportVariationList; let index=index">
                            <tr>
                                <td class="table-border-data" style="text-align: center;"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    <input type="radio" name="report_variation" [value]="selectedOfferingId"
                                        [checked]="result.productOfferingId == selectedOfferingId"
                                        (change)="selectReportVariation(result)" style="margin-top: 15%;" />
                                </td>
                                <td class="table-border-data desktop-view"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    {{ result.reportType }}
                                </td>
                                <td class="table-border-data mobile-view"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    {{ result.reportType }}
                                    ({{ result.speedDescription }})
                                    <span *ngIf="result.vendorDowntime">*</span>
                                    <br>
                                    ${{ result.productPrice | currency:'':'' }} {{currency}} <br>
                                    <!-- <span *ngIf="result.reportTS">{{ result.reportTS | date: 'dd MMM y' }}</span>
                                    <span *ngIf="!result.reportTS">Freshly commissioned</span> -->
                                </td>
                                <td class="table-border-data desktop-view"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    {{ result.speedDescription }}
                                    <span *ngIf="result.vendorDowntime">*</span>
                                    <span *ngIf="result.expectedDeliveryDate">
                                        <br>
                                        <span class="delivery-date-format">
                                            Expected delivery date:
                                        </span><br>
                                        <span class="delivery-date-format">
                                            {{ result.expectedDeliveryDate | date: 'dd MMM y' }}
                                        </span>
                                    </span>
                                </td>
                                <td class="table-border-data desktop-view" style="text-align: right;"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    {{ result.productPrice | currency:'':'' }}
                                </td>
                                <td *ngIf="!isOfflineReport" class="table-border-data desktop-view"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}">
                                    <span *ngIf="result.reportTS">{{ result.reportTS | date: 'dd MMM y' }}</span>
                                    <span *ngIf="!result.reportTS">Freshly commissioned</span>
                                </td>
                                <td class="table-border-data-last" style="text-align: center;"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}"
                                    *ngIf=!isMobile>
                                    <div style="display: inline-flex; align-items: center;">
                                        <img style="cursor: pointer; height: 30px; width: 16px;"
                                            src="../../assets/img/sample.svg"
                                            (click)="sampleReport(result.productOfferingId)">
                                        <span style="cursor: pointer;" class="action-sample"
                                            (click)="sampleReport(result.productOfferingId)">
                                            {{ 'variationTable.sample' | translate }}
                                        </span>
                                    </div>
                                </td>
                                <td class="table-border-data-last" style="text-align: center;"
                                    [ngClass]="{'selected-variation-row': result.productOfferingId == selectedOfferingId}"
                                    *ngIf=isMobile>
                                    <div style="align-items: center;">
                                        <img style="cursor: pointer; height: 30px; width: 16px; margin: auto;"
                                            src="../../assets/img/sample.svg"
                                            (click)="sampleReportForMoile(result.productOfferingId)">
                                        <div style="cursor: pointer;margin: 0;" class="action-sample"
                                            (click)="sampleReportForMoile(result.productOfferingId)">
                                            {{ 'variationTable.sample' | translate }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <!-- Report Variation disclaimer -->
                <div *ngIf="reportAvailability.globalVendorDowntime" class="donwTimeDisclaimer">
                    * {{ 'downTime.disclaimer1' | translate }} {{ 'downTime.disclaimer2' | translate }}
                </div>
                <!-- Translation Table-->
                <div class="zanna_div row report-variation" *ngIf="!noTranslation" style="width: 80%;">
                    <p style="width:80%; margin-right: 5px;">
                        <strong>{{ 'variationTable.selectTranslation' | translate }}</strong>
                        <span class="sub-title">
                            {{ 'variationTable.additionalCharges' | translate }}
                        </span>
                    </p>
                    <div class="data-grid-strip" style="width: 100%;">
                        <span *ngIf="selectedLanguageTranslation.length > 0">
                            {{ selectedLanguageTranslation.length }} {{ 'grid.rowsSelected' | translate }}
                        </span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" width="42"></th>
                            <th class="table-border-header" width="300">
                                {{ 'variationTable.translationLanguage' | translate }}
                            </th>
                            <th class="table-border-header desktop-view" width="200">
                                {{ 'variationTable.turnaroundTime' | translate }}
                            </th>
                            <th class="table-border-header desktop-view" width="200">
                                {{ 'variationTable.priceInSGD' | translate }} ({{currency}})
                            </th>
                            <th class="table-border-header border-r mobile-view" width="40">
                                {{ 'selectionTable.action' | translate }}
                            </th>
                        </tr>
                        <tbody *ngFor="let result of translationLanguage;let index = index">
                            <tr>
                                <td class="table-border-data" style="text-align: center;"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    <input type="checkbox" name="report_variation"
                                        [disabled]="!result.TranslationOfferingId || (!selectedReportVariation.reportType && !checkIfPresent(result))"
                                        [(ngModel)]="result.checked"
                                        (change)="selectLanguageTranslation($event,result)" />
                                </td>
                                <td class="table-border-data"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    {{ result.translationLanguage }}
                                </td>
                                <td class="table-border-data desktop-view"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    {{ result.speedDescription }}
                                </td>
                                <td *ngIf="!isPriceFormat" class="table-border-data desktop-view"
                                    style="text-align: right;"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    {{ result.price }}
                                </td>
                                <td *ngIf="isPriceFormat" class="table-border-data desktop-view"
                                    style="text-align: right;"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    {{ result.price | number }}
                                </td>
                                <td class="dot-icon mobile-view"
                                    [ngClass]="{'selected-variation-row': result.checked == true}">
                                    <div (click)="expandTranslation(index)">
                                        <mat-icon class="download-icon"
                                            *ngIf="!result.isExpandReport">keyboard_arrow_down</mat-icon>
                                        <mat-icon class="download-icon"
                                            *ngIf="result.isExpandReport ">keyboard_arrow_up</mat-icon>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="result.isExpandReport">
                                <td colspan="4">
                                    <div class="detail-section">
                                        <label for="">{{ 'variationTable.turnaroundTime' | translate }}</label>
                                        <div class="details">
                                            {{ result.speedDescription }}
                                        </div>
                                    </div>
                                    <div class="detail-section">
                                        <label for="">{{ 'variationTable.priceInSGD' | translate }}
                                            ({{currency}})</label>
                                        <div class="details">
                                            <span *ngIf="!isPriceFormat">
                                                {{ result.price }}
                                            </span>
                                            <span *ngIf="isPriceFormat">
                                                {{ result.price | number }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- note -->
                <div *ngIf="searchForm.controls['categoryId'].value===7" class="zanna_div row">
                    {{'home.note' | translate}}
                </div>
                <!--holiday disclaimer-->
                <div *ngIf="holidayList && holidayList.length > 0" class="zanna_div row">
                    <span>
                        <strong>
                            {{ 'holidayList.importantNotes' | translate }}
                        </strong><br>
                        <span class="important-notes">
                            {{ 'holidayList.disclaimer1' | translate }}:
                        </span><br>
                        <span *ngFor="let data of holidayList;index as i">
                            <span class="important-notes">{{i + 1}}. {{data.holidayDate | date: 'dd MMM y'}}
                                ({{data.holidayName}})</span><br>
                        </span>
                        <span class="important-notes">
                            {{ 'holidayList.disclaimer2' | translate }}
                        </span>
                    </span>
                </div>
                <!--Additional Remark -->
                <div *ngIf="isNotInstantReport" class="zanna_div row">
                    <p class="fs-2">
                        <strong>
                            {{ 'orderDetails.additionalRemark' | translate }}
                        </strong>
                        <span class="sub-title">
                            {{ 'additionalRemark.maxCharacters' | translate }}
                        </span>
                    </p>
                    <textarea style="width: 593px;" maxlength="300" id='editableDiv' [(ngModel)]="additionalRemark"
                        placeholder="{{ 'orderDetails.optional' | translate }}"> <!-- (keypress) = "onInputchange($event)"-->
                    </textarea>
                </div>

                <!-- Email notification -->
                <div class="row fs-2" style="margin-bottom: 20px;">
                    <p>
                        <strong>
                            {{ 'email.notification' | translate }}
                        </strong>
                        <span class="sub-title">
                            {{ 'email.maxEmail' | translate }}
                        </span>
                    </p>
                    <div class="email-div">
                        <div style="background-color: #ffffff;padding:0px">
                            <mat-form-field id="example-chip-list" appearance="outline" class="white-backgrounded-field" >
                                <span class="placeholder">{{ 'email.addEmail' | translate }}</span>
                                <mat-chip-grid #chipList style="background-color:#fff;" >
                                    <mat-chip-row  *ngFor="let email of notificationEmailList" 
                                        [removable]="removable" [ngStyle]="{ backgroundColor:'#E6EFFB', 'margin-top':'0px'}"
                                        (removed)="remove(email)">
                                        {{email}}
                                        <mat-icon matChipRemove *ngIf="removable" class="chip-close">
                                            close
                                        </mat-icon>
                                    </mat-chip-row>
                                    <input #fruitInput 
                                        [formControl]="fruitCtrl" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                        (matChipInputTokenEnd)="add($event)">
                                </mat-chip-grid>
                            </mat-form-field>     
                        </div>
                    </div>

                    <mat-error *ngIf="emailExistError">
                        {{ 'email.alreadyExist' | translate }}
                    </mat-error>
                    <mat-error *ngIf="maxLengthError">
                        {{ 'email.max50Characters' | translate }}
                    </mat-error>
                    <mat-error *ngIf="emailError">
                        {{ 'email.errorMsg' | translate }}
                    </mat-error>
                    <mat-error *ngIf="emailMaxError">
                        {{ 'email.maxEmail' | translate }}
                    </mat-error>
                    <mat-error *ngIf="emailRequiredError">
                        {{ 'email.infoMsg' | translate }}
                    </mat-error>
                    
                </div>
                <!--Add to cart button-->
                <div class="zanna_div row">
                    <span class="addToCartSpan">
                        <a (click)="addToCart()" class="button button__primary add-to-cart"
                            style="cursor: pointer;text-align: center;">
                            {{ 'home.addToCartBtn' | translate }} ({{ currency }} {{ totalPrice.toFixed(2) |
                            currency:'':'' }})
                        </a>
                    </span>
                    <span class="downtime-AddToCart" *ngIf="selectedReportVariation.vendorDowntime">
                        <span>{{ 'downTime.disclaimer1' | translate }}</span><br />
                        {{ 'downTime.disclaimer2' | translate }}
                    </span>
                </div>
            </article>
        </article>
    </section>

    <!-- BULK UPLOAD -->
    <app-bulk-upload-grid class="desktop-view"
        *ngIf=" bulkUploadDetails != null && bulkUploadDetails?.bulkOrderNumber != null && bulkUploadDetails?.bulkOrderNumber != ''"
        [validatedData]="bulkUploadDetails" (completeStatus)="getCommpleteStatus($event)"
        (getCartDetails)="getCartList()"></app-bulk-upload-grid>
    <!-- bulkUploadRunBackground == 'true' || bulkUploadRunBackground == 'completed' -->
    <!-- CART-->
    <section>
        <article class="cart-selection hidden">
            <div class="zanna_div row" id="cartDetailsId">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'home.cart' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'cart.subInfo' | translate }}
                    <span class="span-moreResults">{{ 'cart.subInfo.moreReports' | translate }}</span>
                    <span (click)="clickHereToSearch()" class="span-clickHere">{{ 'cart.subInfo.clickHere' | translate
                        }}</span>
                </p>
            </div>
            <div class="zanna_div row">
                <div *ngIf="toggleGridFlag">
                    <span class="error" *ngIf="cartList.length == 0">
                        *{{ 'cart.cartEmpty' | translate }}
                    </span>
                    <div class="data-grid-strip">
                        <span *ngIf="submitOrderResponse.length > 0">
                            {{ submitOrderResponse.length }} {{ 'grid.rowsSelected' | translate }}
                        </span>
                    </div>
                    <div class="btn-data-grid-strip border-b-n" *ngIf="isMobile && cartList.length>0">
                        <div (click)=ExpandAll(allExpand) class="arrow-container">
                            <mat-icon class="down-arrow-icon icon-color"
                                *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                            <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                            <span>{{expandCollapseButtonValue}}</span>
                        </div>
                    </div>
                    <ag-grid-angular class="ag-theme-alpine" id="cartGrid" [rowData]="cartList"
                        [columnDefs]="columnDefs" [masterDetail]="true" [detailRowHeight]="detailRowHeight"
                        [detailCellRenderer]="detailCellRenderer" [gridOptions]="gridOptions"
                        [tooltipShowDelay]="tooltipShowDelay" [icons]="icons" (rowSelected)="onRowSelected($event)"
                        [domLayout]="domLayout" (filterChanged)="onFilterChanged($event)"
                        (selectionChanged)='onSelectionChanged($event)'>
                    </ag-grid-angular>
                    <app-pagination *ngIf="cartList.length > 0" [totalRows]="gridOptions?.api?.getDisplayedRowCount()"
                        [pageSize]="PaginationPageSize" [gridOptions]="gridOptions"
                        [noOfPages]="gridOptions.api?.paginationGetTotalPages()"></app-pagination>
                </div>
                <!-- Cart Summary-->
                <div class="zanna_div row desktop-view">
                    <div class="summary-format">
                        <span class="summary">{{ 'cart.summary' | translate }}</span>
                        <div class="cart-price-format">
                            <span class="total-cart-price">
                                {{ 'cart.totalPriceIn' | translate }}
                            </span>
                            <span class="cart-price">
                                {{currency}} {{ totalCartValue | currency:'':'' }}
                            </span>
                        </div>
                        <div style="text-align: right;" *ngIf="paymentMethod == 'Prepaid'">
                            {{ 'cart.prepaidPriceTax' | translate }}
                        </div>
                        <div style="text-align: right;" *ngIf="paymentMethod == 'Postpaid'">
                            {{ 'cart.postpaidPriceTax' | translate }}
                        </div>
                        <hr>
                        <div class="cart-price-format">
                            <span class="total-cart-price">
                                {{ 'cart.paymentMethod' | translate }}
                            </span>
                            <span>{{ paymentMethod }}</span>
                        </div>
                        <div class="submit-btn-div">
                            <button (click)="submitOrder()"
                                [ngClass]="submitOrderResponse.length == 0 ? 'disabled_submit btn-submit-order' : 'btn-submit-order'">
                                {{ 'cart.submitOrderBtn' | translate }}
                            </button>
                            <span class="cart-info-text">
                                {{ 'cart.submitOrderInfo' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Cart Summary for Mobile view -->
                <div class="zanna_div row mobile-view b-c-white mobile-cart-summary">
                    <div class="summary-format">
                        <div (click)="expandCartDetail()" class="arrow-icon">
                            <mat-icon class="expand-icon" *ngIf="!isExpand">keyboard_arrow_down</mat-icon>
                            <mat-icon class="expand-icon" *ngIf="isExpand">keyboard_arrow_up</mat-icon>
                        </div>
                        <div class="price-section">
                            <div class="cart-price">
                                {{currency}} {{ totalCartValue | currency:'':'' }}
                            </div>
                            <div *ngIf="paymentMethod == 'Prepaid'">
                                {{ 'cart.prepaidPriceTax' | translate }}
                            </div>
                            <div *ngIf="paymentMethod == 'Postpaid'">
                                {{ 'cart.postpaidPriceTax' | translate }}
                            </div>
                        </div>
                        <div class="submit-btn-div">
                            <button (click)="submitOrder()"
                                [ngClass]="submitOrderResponse.length == 0 ? 'disabled_submit btn-submit-order' : 'btn-submit-order'">
                                {{ 'cart.submitOrderBtn' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="cart-price-format" *ngIf="isExpand">
                        <div class="total-cart-price">
                            {{ 'cart.paymentMethod' | translate }}
                        </div>
                        <div class="payment-method">{{ paymentMethod }}</div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</main>
<app-footer></app-footer>