import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { HomeService } from './home.service';
import { SearchResultsComponent } from '../search-results/search-results';
import { ViewComparisonComponent } from '../view-comparison/view-comparison';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { LoaderService } from '../services/loader.service';
import { CustomToolTipComponent } from '../custom-tooltip/custom-tooltip.component';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import { CustomDropDownFilter } from '../custom-dropdown-filter/custom-dropdown-filter';
import { COMMA, SPACE, ENTER, A } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import { NoResultDialogComponent } from '../no-result-dialog-box/no-result-dialog.component';
import { ConfimDialogComponent } from '../confirm-dialog-box/confirm-dialog.component';
import { PAYMENT_METHODS } from '../app.constant';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BulkUploadComponent } from '../bulk-upload/bulk-upload.component';
import { BulkUploadGridService } from '../bulk-upload-grid/bulk-upload-grid.service';
import { CartDataCellRenderer } from './cart-data-grids/cart-data.component';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { OrderDataCellRenderer } from '../thank-you/order-details-grid/order-data.component';
declare const $: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	bulkUploadDetails: any = null;
	bulkuploadLinkStatus: any;
	selectedLang: any = '';
	columnDefs: any = [];
	confirmDialogRef: MatDialogRef<ConfimDialogComponent>
	countryList: any = [];
	productList: any = [];
	domLayout: any = "autoHeight";
	dynamicFields: any = {};
	currency: any = '';
	formFields = [];
	reportVariationList: any = [];
	reportComparisonList: any = [];
	holidayList: any = [];
	selectedReportVariation: any = {};
	selectedReportTranslation: any = {};
	totalPrice: any = 0.0;
	searchForm!: UntypedFormGroup;
	isSearchSubmitted: boolean = false;
	maxValidation: boolean = false;
	alphabetValidation: boolean = false;
	maxLengthFileRef: boolean = false;
	fileRefAllowOnlyAlphabets: boolean = false;
	reportVariationError: boolean = false;
	max120Characters: boolean = false;
	maxCityValidation: boolean = false;
	selectedCountry: any = {};
	selectedCategoryId: String = "";
	notificationEmailList: any = [];
	cartList: any = [];
	selectedProductCategory: any = {};
	gridOptions: any;
	selectedCartList: any = [];
	totalCartValue: any = "0.00";
	selectedResult: any = {};
	offlineResult: any = {};
	toggleGridFlag: boolean = true;
	submitOrderResponse: any = [];
	selectedOfferingId: any = "";
	isAddressShow: boolean = false;
	isExpand: boolean = false;
	isExpandReport: boolean = false;
	icons: any;
	tooltipShowDelay: any;
	visible = true;
	uploadingFile: boolean = false;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, SPACE, COMMA];
	fruitCtrl = new UntypedFormControl();
	rulesForm!: UntypedFormGroup;
	filteredFruits: Observable<string[]> | undefined;
	fruits: string[] = [];
	emailError: boolean = false;
	emailMaxError: boolean = false;
	emailRequiredError: boolean = false;
	@ViewChild('fruitInput')
	fruitInput!: ElementRef<HTMLInputElement>;
	@ViewChild('auto')
	matAutocomplete!: MatAutocomplete;
	additionalRemark: string = '';
	detailCellRenderer: any;
	detailRowHeight: number;
	translationLanguage: any = [];
	translationMap: any = [];
	selectedLanguageTranslation: any = [];
	selectedTranslationReportType: any = {};
	resetTranslation: boolean = false;
	selectedRowData: any = {};
	reportAvailability: any = {};
	paymentMethod: any = '';
	emailExistError: boolean = false;
	maxLengthError: boolean = false;
	private paginationPageSize = 5;
	private totalPages = 0;
	public sessionData: any = {};
	clientRefNo: any;
	noTranslation: boolean = false;
	isPriceFormat: boolean = false;
	isOfflineReport: boolean = false;
	productName: any;
	isNotInstantReport: boolean = false;
	entityEnglishValidation: boolean = false;
	allExpand: boolean = false;
	expandCollapseButtonValue: String = "Expand all rows"
	title: any = "Report Buying";

	viewReportDetails: boolean;

	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(private formBuilder: UntypedFormBuilder, private homeService: HomeService,
		private dialog: MatDialog, private router: Router, private currencyPipe: CurrencyPipe,
		private activatedRoute: ActivatedRoute, private loaderService: LoaderService, public uploadService: BulkUploadGridService,
		public translateService: TranslateService) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.getColDefs();
		this.selectedLang = localStorage.getItem("lang");
		var params = { sid: localStorage.getItem('sessionID') };
		if (params.sid) {
			var sid = localStorage.getItem('sessionID');
			this.homeService.setSid({ sessionID: params.sid });

			this.getCountries(params.sid);
			this.loaderService.isLoading.next(true);
			var validatedObj = localStorage.getItem("validation");
			if (validatedObj) {
				this.sessionData = JSON.parse(validatedObj);
				let data = JSON.parse(validatedObj);
				this.loaderService.isLoading.next(false);
				if (data.rCurrency != null && data.rCurrency != '') {
					this.currency = data.rCurrency;
					localStorage.setItem('currency', this.currency);
				} else if (data.currency) {
					this.currency = data.currency;
					localStorage.setItem('currency', this.currency);
				} else {
					localStorage.removeItem('currency');
				}
				let points = "0.0"
				if (data.pointBalance) {
					points = parseFloat(data.pointBalance).toFixed(2);
				}
				localStorage.setItem('orderSource', data.orderSource);
				localStorage.setItem('balancePoint', points);
				this.homeService.setSid(data);
				localStorage.setItem('userID', this.sessionData?.userID);
				localStorage.setItem('sessionID', this.sessionData?.sessionID);
				localStorage.setItem('userEmail', this.sessionData?.email);
				localStorage.setItem('distributionCode', this.sessionData?.distributionCode);
				let paymentMethod = this.sessionData?.paymentScheme != 'null' ? this.sessionData?.paymentScheme : '';
				switch (paymentMethod) {
					case 'TRM':
						localStorage.setItem('paymentMethod', PAYMENT_METHODS.TRM);
						break;
					case 'PTS':
						localStorage.setItem('paymentMethod', PAYMENT_METHODS.PTS);
						break;
					case 'PPS':
						localStorage.setItem('paymentMethod', PAYMENT_METHODS.PPS);
						break;
				}
				this.paymentMethod = localStorage.getItem('paymentMethod');
				if (this.sessionData) {
					this.getCartList();
				}
			}
		} else {
			this.loaderService.isLoading.next(false);
			this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile ? 'auto' : '37vw',
				data: {
					errorMessage: "Troubleshooting please refresh screen with session",
					tryAgain: false
				}
			});
		}

		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				this.gridOptions.api?.sizeColumnsToFit();
				this.gridOptions.api.forEachNode((node: any) => node.setSelected(true));
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
				let columnDefs = this.gridOptions.api.getColumnDefs();
				this.translateService.get(['cart.price']).subscribe(value => {
					columnDefs.find((obj: any) => {
						if (obj.ColId == 'price_col') {
							obj.headerName = value['cart.price'] + ' (' + (this.currency ? this.currency : '') + ')';
						}
					});
				});
				this.gridOptions.api.setColumnDefs(columnDefs);
				this.gridOptions.api.refreshHeader({ forrce: true });
				this.gridAPI.resetRowHeights();
				if (this.cartList.length > 4) {
					this.gridAPI.setDomLayout('normal');
					let element: any = document.getElementById('cartGrid');
					if (element) {
						element.style.height = '60vh';
					}
				}

			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			rowSelection: 'multiple',
			pagination: true,
			animateRows: true,
			suppressCellSelection: true,
			suppressMenuHide: true,
			paginationPageSize: 10,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 200;
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"myDetailCellRenderer": CartDataCellRenderer,
			"customTooltip": CustomToolTipComponent,
			"customFloatingFilter": CustomFilterComponent,
			"customSelectFilter": CustomDropDownFilter
		};
		this.viewReportDetails = false;
	}

	ngOnInit(): void {
		this.countryList = [];
		this.productList = [];

		this.searchForm = this.formBuilder.group({
			countryCode: ['', Validators.required],
			categoryId: ['', Validators.required]
		});
		this.getBulkUploadDetails();
	}

	customSearchFn(term: string, item: any) {
		term = term.toLowerCase();
		let splitTerm = term.split(' ').filter(t => t);
		let isWordThere: boolean[] = [];
		splitTerm.forEach(arr_term => {
			let byName = item['countryName'].toLowerCase();
			let includes = item['countryIncludes']
			if (includes != null) {
				let byIncludes = includes.toLowerCase();
				isWordThere.push(byIncludes.indexOf(arr_term) != -1 || byName.indexOf(arr_term) != -1);
			} else {
				isWordThere.push(byName.indexOf(arr_term) != -1);
			}
		});
		const all_words = (this_word: any) => this_word;
		return isWordThere.every(all_words);
	}

	getColDefs() {
		this.translateService.get(['cart.identification', 'cart.product', 'cart.turnaroundTime',
			'cart.country', 'cart.language', 'cart.price', 'cart.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: '',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						suppressMenu: true,
						suppressSorting: true,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'justify-content': 'center' }
					},
					{
						field: 'entityName',
						headerName: value['cart.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						minWidth: this.isMobile ? 220 : 290,
						maxWidth: this.isMobile ? 500 : 290,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile ? '13px 0 13px 13px' : '13px 13px 13px 20px', 'font-size': '14px' }
					},
					{
						field: 'productDescription',
						headerName: value['cart.product'],
						width: 180,
						minWidth: 180,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'speedDescription',
						headerName: value['cart.turnaroundTime'],
						width: 150,
						minWidth: 150,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							return param.data.expectedDeliveryDate ? (param.data.speedDescription + '<br/><span style="font-size:13px; color: #8C8C8C">Expected delivery date:</span><br/>' + moment(param.data.expectedDeliveryDate).format('DD MMM YYYY')) : param.data.speedDescription;
						},
						comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any, isInverted: any) => {
							if (nodeA.data.turnAroundTimeBySupplier == nodeB.data.turnAroundTimeBySupplier) return 0;
							return (nodeA.data.turnAroundTimeBySupplier > nodeB.data.turnAroundTimeBySupplier) ? 1 : -1;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'countryName',
						headerName: value['cart.country'],
						tooltipField: 'address',
						width: 110,
						minWidth: 110,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							return param.data?.parentCountryName ? param.data?.parentCountryName : param.data?.countryName;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'productLanguage',
						headerName: value['cart.language'],
						width: 100,
						minWidth: 100,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'sellingPrice',
						headerName: value['cart.price'],
						ColId: "price_col",
						width: 100,
						minWidth: 100,
						hide: this.isMobile,
						suppressMenu: false,
						filter: 'agNumberColumnFilter',
						floatingFilter: this.isMobile ? false : true,
						filterParams: {
							allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
							numberParser: (text: any) => {
								return text == null ? null : parseFloat(text.replace(','));
							}
						},
						cellStyle: { 'direction': 'rtl', 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' },
						valueFormatter: (params: any) => {
							return this.currencyFormatter(params.data?.sellingPrice)
						}
					},
					{
						headerName: this.isMobile ? '' : value['cart.action'],
						maxWidth: this.isMobile ? 60 : 80,
						sortable: false,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.cartActionItems.bind(this),
							label: ''
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'font-size': '14px', 'padding': this.isMobile ? '0px' : "13px 17px" }
					}
				]
				this.columnDefs.find((obj: any) => {
					if (obj.ColId == 'price_col') {
						obj.headerName = value['cart.price'] + ' (' + (this.currency ? this.currency : '') + ')';
					}
				});
				setTimeout(() => {
					this.gridOptions.api.setColumnDefs(this.columnDefs);
					this.gridOptions.api.refreshHeader({ forrce: true });
					// if(!this.isMobile) {
					this.gridOptions.api?.sizeColumnsToFit();
					// }
				}, 1000);
			});
	}

	getCountries(sessionId: any) {
		this.loaderService.isLoading.next(true);
		this.homeService.getCountries(sessionId).subscribe((resp: any) => {
			this.loaderService.isLoading.next(false);
			if (resp.success) {
				let data = resp.data;
				this.countryList = data;
				if (this.countryList.length > 0) {
					this.searchForm.controls['countryCode'].setValue(this.countryList[0].countryCode);
					this.selectedCountry = this.countryList[0];
					this.changeCountry();
				}
			}
		}, err => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					disableClose: true,
					width: this.isMobile ? 'auto' : '37vw',
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.getCountries(localStorage.getItem('sessionID'));
					}
				});
			})
		});
	}

	changeCountry() {
		this.resetForm();
		var country = this.searchForm.value.countryCode;
		if (country != null && country != undefined && country != '') {
			this.loaderService.isLoading.next(true);
			this.selectedCountry = this.countryList.find((obj: any) => (obj.countryCode === this.searchForm.value.countryCode));
			this.homeService.getProductsByCountry(country).subscribe((resp: any) => {
				if (resp.success) {
					let data = resp.data;
					this.productList = data;
					if (this.sessionData?.iIRGDNReportAccess == 'N') {
						this.productList = this.productList.filter((rec: any) => rec.categoryId != 1)
					}
					if (this.sessionData?.iIRROCReportAccess == 'N') {
						this.productList = this.productList.filter((rec: any) => rec.categoryId != 4)
					}
					if (this.sessionData?.iirGlobalCreditReportAccess == 'N') {
						this.productList = this.productList.filter((rec: any) => rec.categoryId != 5)
					}
					if (this.sessionData?.iirGlobalMonitoringAccess == 'N') {
						this.productList = this.productList.filter((rec: any) => rec.categoryId != 6)
					} if (this.sessionData?.iirGlobalUBOAccess == 'N') {
						this.productList = this.productList.filter((rec: any) => rec.categoryId != 7)
					}
					this.loaderService.isLoading.next(false);
					if (this.productList.length == 0) {
						this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
							this.dialog.open(ErrorDialogComponent, {
								disableClose: true,
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									errorMessage: value['errorMsg.pleaseTryAgain']
								}
							});
						});
					}
					// if (this.productList.length > 0) {
					// 	let item = this.productList[0];
					// 		this.searchForm.controls['categoryId'].setValue(item.categoryId);
					// 		this.productName = item.categoryName;
					// 		this.formFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;
					// 		this.dynamicFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;
					// 		this.searchForm = this.createDynamicForm();
					// 		this.selectedProductCategory = item;
					// 		this.selectedCategoryId = item.categoryId;
					// }
				}
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.changeCountry();
						}
					});
				});
			});
		}

	}

	resetForm() {
		var data = this.searchForm.controls['countryCode'].value;
		this.searchForm.reset();
		this.searchForm.controls['countryCode'].setValue(data);
		this.dynamicFields = [];
		this.isSearchSubmitted = false;
		this.maxValidation = false;
		this.max120Characters = false;
		this.alphabetValidation = false;
		this.maxLengthFileRef = false;
		this.productList = [];
	}

	changeProduct() {
		var formValue = this.searchForm.value;
		this.productList.forEach((item: any) => {
			if (item.categoryId == formValue.categoryId) {
				this.formFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;
				this.dynamicFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;//this.formFields;
				this.searchForm = this.createDynamicForm();
				this.selectedProductCategory = item;
				this.selectedCategoryId = item.categoryId;
				this.viewReportDetails = [4, 5, 6, 7].includes(item.categoryId);
				this.productName = item.categoryName;
			}
		});
	}

	getAdditionalAvailability() {
		var aFields = this.dynamicFields.additional;
		if (aFields != undefined) {
			return aFields.length > 0;
		} else {
			return false;
		}
	}

	additionalSearch() {
		var element = document.getElementsByClassName("additional-search-criterias")[0];
		var res = element.classList.contains("hidden");
		if (res) {
			element.classList.remove("hidden");
		} else {
			element.classList.add("hidden");
		}
	}

	createDynamicForm() {
		const group: any = {};
		group['countryCode'] = new UntypedFormControl(this.searchForm.value.countryCode, Validators.required);
		group['categoryId'] = new UntypedFormControl(this.searchForm.value.categoryId, Validators.required);

		if (this.dynamicFields.compulsory != undefined && this.dynamicFields.compulsory != null) {
			this.dynamicFields.compulsory.forEach((item: any) => {
				group[item.key] = new UntypedFormControl(item.value || '', [Validators.required, Validators.maxLength(120)]); //  Validators.pattern('[\x00-\xFF]*'),
			});
		}
		if (this.dynamicFields.additional != undefined && this.dynamicFields.additional != null) {
			this.dynamicFields.additional.forEach((item: any) => {
				if (item.key == 'city_state') {
					group[item.key] = new UntypedFormControl(item.value || '', [Validators.maxLength(50)]); // item.value || '', [Validators.pattern('[\x00-\xFF]*')]   Validators.pattern('[^{}<>&]*')
				} else if (item.key == 'entity_num') {
					group[item.key] = new UntypedFormControl(item.value || '', [Validators.maxLength(20)]); // item.value || '', [Validators.pattern('[\x00-\xFF]*'), Validators.maxLength(20)]  Validators.pattern('[^{}<>&]*'),
				}
			});
		}
		return this.formBuilder.group(group)
	}

	checkValidation() {
		if (this.searchForm.get('client_ref_num')?.status === 'INVALID') {
			if ((this.searchForm.value.client_ref_num).length != 0) {
				// this.entityEnglishValidation = true;
			}
		}
		if (this.searchForm.get('client_ref_num')?.status === 'VALID') {
			// this.entityEnglishValidation = false;
		}
		if (this.searchForm.get('entity_num')?.status === 'INVALID') {
			if ((this.searchForm.value.entity_num).length > 20) {
				this.maxLengthFileRef = true;
				// this.fileRefAllowOnlyAlphabets = false;
			} else {
				this.maxLengthFileRef = false;
				// this.fileRefAllowOnlyAlphabets = true;
			}
		}
		if (this.searchForm.get('entity_num')?.status === 'VALID') {
			this.maxLengthFileRef = false;
			// this.fileRefAllowOnlyAlphabets = false;
		}
		if (this.searchForm.get('city_state')?.status === 'INVALID') {
			// this.alphabetValidation = true;
			this.maxCityValidation = true;
		}
		if (this.searchForm.get('city_state')?.status === 'VALID') {
			// this.alphabetValidation = false;
			this.maxCityValidation = false;
		}
		if ((this.searchForm.value.client_ref_num).length >= 6) {
			this.maxValidation = false;
		} if ((this.searchForm.value.client_ref_num).length <= 120) {
			this.max120Characters = false;
		}
		if ((this.searchForm.value.client_ref_num).length > 120) {
			this.max120Characters = true;
		}
	}

	onSubmit() {
		this.isSearchSubmitted = true;
		if (this.searchForm.invalid) {
			var errMsg: any;
			this.translateService.get(['formError.allowAlphabets', 'formError.mandatoryFields',
				'formError.max20Characters', 'formError.max200CharsRequired']).subscribe(value => {
					if (this.alphabetValidation) {
						errMsg = value['formError.allowAlphabets'];
					} else if (this.maxLengthFileRef) {
						errMsg = value['formError.max20Characters'];
					} else if (this.max120Characters) {
						errMsg = value['formError.max120CharsRequired']
					} else {
						errMsg = value['formError.mandatoryFields'];
					}
					this.dialog.open(ErrorDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: errMsg,
							isOk: true,
							tryAgain: false
						}
					});
					return;
				});
		} else {
			this.getSearchResultList();
		}
	}

	getSearchResultList() {
		let payload = {
			countryCode: this.searchForm.value.countryCode,
			name: this.searchForm.value.client_ref_num,
			applicationReference: "search",
		}
		let offlinePayload = {
			country: this.selectedCountry.countryName,
			name: this.searchForm.value.client_ref_num,
			productName: this.productName,
			categoryId: this.searchForm.value.categoryId,
			manualSearch: false,
			fileReference: this.searchForm?.value?.entity_num ? this.searchForm?.value?.entity_num : '',
			city: this.searchForm?.value?.city_state ? this.searchForm?.value?.city_state : ''
		}
		if (payload.name && (payload.name.length >= 6 && payload.name.length <= 120)) {
			this.loaderService.isLoading.next(true);
			this.homeService.searchCompanies(payload, this.searchForm.value.categoryId).subscribe((res: any) => {
				if (res) {
					this.loaderService.isLoading.next(false);
					if (res.errors?.length > 0) {
						if (res.errors[0]?.errorCode == 'S004') {
							// company not found and error code S004
							let obj = {
								results: res.data,
								offineReport: true,
								formData: offlinePayload
							}
							this.openSearchResultPopup(obj);
						} else {
							// company not found and gdn down
							this.translateService.get(['errorMsg.gdnDown']).subscribe(value => {
								let dialogRef = this.dialog.open(NoResultDialogComponent, {
									width: this.isMobile ? 'auto' : '37vw',
									disableClose: true,
									data: {
										infoMessage: value['errorMsg.gdnDown'],
										manualSearch: true,
										gdnFailed: true
									}
								});
								dialogRef.afterClosed().subscribe((res: any) => {
									if (res != undefined && res != null) {
										if (res == 'proceed') {
											offlinePayload.manualSearch = true;
											let obj = {
												results: res.data,
												offineReport: true,
												formData: offlinePayload
											}
											this.openSearchResultPopup(obj);
										}
									}
								});
							});
						}
					} else {
						//if Search Result Found
						if (res.data.length > 0) {
							let obj = {
								results: res.data,
								formData: offlinePayload,
								offineReport: false
							}
							this.openSearchResultPopup(obj);

						} else {
							//if Search Result Found but data empty
							let obj = {
								results: res.data,
								offineReport: true,
								formData: offlinePayload
							}
							this.openSearchResultPopup(obj);

						}
					}
				} else {
					this.loaderService.isLoading.next(false);
				}
				this.getTranslations(this.selectedCategoryId);
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						disableClose: true,
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe((res: any) => {
						if (res) {
							this.getSearchResultList();
						}
					});
				});
			});
		} else {
			if (payload.name.length < 6) {
				this.maxValidation = true;
			} else if (payload.name.length > 200) {
				this.max120Characters = true;
			}
		}
	}

	openSearchResultPopup(data: any) {
		let dialogRef = this.dialog.open(SearchResultsComponent, {
			disableClose: true,
			width: this.isMobile ? '91vw' : '81vw',
			panelClass: 'search-result-dialog',
			data: data
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res != undefined && res != null) {
				if (res.type == 'offline') {
					this.isOfflineReport = true;
					this.offlineResult = res;
				} else {
					this.selectedResult = res;
				}
				this.clientRefNo = this.searchForm?.value?.entity_num ? this.searchForm?.value?.entity_num : '';
				if (!(Object.keys(res).length === 0 && res.constructor === Object)) {
					$('.after-selection').removeClass('hidden').siblings('.after-selection').addClass('hidden');
					$('.before-selection').addClass('hidden');
					this.reportVariationList = [];
					this.reportComparisonList = [];
					this.holidayList = [];
					this.getAvailibities();
					$('html, body').animate({ scrollTop: $('#orderDetailsId').position().top }, 'slow');
				}
			}
		});
	}

	/****************************ORDER DETAILS********************** */

	getAvailibities() {
		var requestJson = {};
		var reportType;
		if (this.isOfflineReport) {
			reportType = "offline";
			requestJson = {
				countrycode: this.searchForm.value.countryCode,
				companyname: this.offlineResult.entityName,
				address1: this.offlineResult.entityAddressLine,
				city: this.offlineResult.city,
				currency: "",
				phone: this.offlineResult.entityPhoneNumber,
				additionalinfo: this.offlineResult.fileRef
			}
		} else {
			reportType = "online";
			requestJson = {
				"countryCode": this.selectedResult.address ? this.selectedResult.address.countryCode : this.searchForm.value.countryCode,
				"scr": this.selectedResult.scr ? this.selectedResult.scr : '',
				"gbin": "",
				"applicationReference": "pa"
			}
		}
		const stringifyData = JSON.stringify(requestJson);
		var formData: any = new FormData();
		formData.append("vendorCategory", this.selectedCategoryId);
		formData.append("requestJson", stringifyData);

		this.loaderService.isLoading.next(true);
		$('.search-result').removeClass('hidden').find('.results').addClass('hidden');
		this.homeService.getAvailibities(formData, reportType).subscribe((data: any) => {
			if (data.errors?.length > 0) {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.getAvailibities();
						} else {
							this.onChangeSelection();
						}
					});
				})
			} else {
				if (data.success) {
					this.reportAvailability = data.data;
					this.reportVariationList = data.data.reportsAvailability;
					for (var i = 0; i > this.reportVariationList.length; i++) {
						this.reportVariationList[i].isExpandReport = false
					}
					this.reportComparisonList = data.data.reportsComparison;
					this.holidayList = data.data?.holidayList.sort((b: any, a: any) => new Date(b.holidayDate).getTime() - new Date(a.holidayDate).getTime());;
					this.resetAfterSelection();
					this.ajaxVariation(this.selectedResult);
					this.loaderService.isLoading.next(false);
					this.notificationEmailList.push(this.sessionData?.email);
				}
			}
		}, err => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: this.isMobile ? 'auto' : '37vw',
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.getAvailibities();
					} else {
						this.onChangeSelection();
					}
				});
			})
		});
	}

	expandCartDetail() {
		this.isExpand = !this.isExpand;
	}
	expandRow() {
		this.isAddressShow = !this.isAddressShow;
	}
	expandReportType(index: any) {
		this.reportVariationList[index].isExpandReport = !this.reportVariationList[index].isExpandReport
	}
	expandTranslation(index: any) {
		this.translationLanguage[index].isExpandReport = !this.translationLanguage[index].isExpandReport
	}

	viewComparison() {
		let dialogRef = this.dialog.open(ViewComparisonComponent, {
			disableClose: true,
			panelClass: 'view-comparison-dialog',
			data: { value: this.reportComparisonList, currency: this.currency, categoryId: this.selectedCategoryId }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res != undefined && res != null && res != false) {
				this.selectReportVariation(res);
			}
		});
	}

	resetAfterSelection() {
		$('.after-selection')
			.find('tr').removeClass('selected')
			.find('input[type=radio], input[type=checkbox]').prop('checked', false);

		$('.amt').html('($0.00)');
	}

	ajaxVariation(result: any) {
		var $reportVariation = $('.report-variation');
		var $reportTranslation = $('.report-translation');
		var $selectedResult = $('.selected-result');

		$reportVariation.find('table').find('.populated').remove();
		$reportTranslation.find('table').find('.populated').remove();
		$selectedResult.find('table').find('.populated').remove();
	}

	sampleReport(offeringId: any) {
		this.loaderService.isLoading.next(true);
		this.homeService.sampleReport(offeringId).subscribe(res => {
			this.dialog.open(DocumentViewComponent, {
				width: '80vw',
				data: {
					response: res,
					format: 'PDF'
				}
			});
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	sampleReportForMoile(offeringId: any) {
		this.loaderService.isLoading.next(true);
		this.homeService.sampleReport(offeringId).subscribe(res => {
			const blob = new Blob([res], { type: "application/pdf" });
			let url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('download', 'Report.pdf');
			a.setAttribute('href', url);
			a.click();
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	selectReportVariation(data: any) {
		if (data.speedDescription != 'Instant') {
			this.isNotInstantReport = true;
			setTimeout(() => {
				document.getElementById('editableDiv')?.addEventListener('paste', this.handlePaste);
			}, 1000);

		} else {
			this.isNotInstantReport = false;
		}
		this.reportVariationError = false;
		$('.table-variation').parent().removeClass('field-error');
		this.translationMap = [];
		this.selectedReportVariation = data;
		this.selectedOfferingId = data.productOfferingId;
		if (this.selectedReportTranslation.price) {
			this.totalPrice = (parseFloat(data.productPrice ? data.productPrice : 0.0) + parseFloat(this.selectedReportTranslation.price));
		} else {
			this.totalPrice = parseFloat(data.productPrice ? data.productPrice : 0.0);
		}
		let relatedLanguage: any = {};
		this.translationLanguage.forEach((element: any) => {
			let filteredRes = element.reportTypeData.find((res: any) => (this.selectedReportVariation.productOfferingId) == (res.ReportOfferingId));
			if (filteredRes) {
				relatedLanguage[element.translationLanguage] = filteredRes;
				filteredRes.ProductPrice = filteredRes.ProductPrice;
				this.isPriceFormat = true;
				element.price = filteredRes.ProductPrice;
				element['TranslationOfferingId'] = filteredRes.TranslationOfferingId;
				element.speedDescription = filteredRes.SpeedDescription;
				element['expectedDeliveryDate'] = filteredRes.expectedDeliveryDate;
				element.checked = false;
			} else {
				relatedLanguage[element.translationLanguage] = filteredRes;
				this.isPriceFormat = false;
				element.price = '';
				element['TranslationOfferingId'] = null;
				element.speedDescription = '';
				element['expectedDeliveryDate'] = '';
				element.checked = false;
			}
		});
		this.selectedLanguageTranslation = [];
		this.selectedTranslationReportType = relatedLanguage;
	}

	onChangeSelection() {
		this.totalPrice = 0.0;
		this.selectedOfferingId = '';
		$('.after-selection').addClass('hidden').siblings('.after-selection').removeClass('hidden');
		$('.before-selection').removeClass('hidden');
		this.selectedResult = {};
		this.offlineResult = {};
		this.isOfflineReport = false;
		this.notificationEmailList = [];
		localStorage.removeItem("cartInserted");
	}

	getTranslations(categoryId: any) {
		this.translationLanguage = [];
		let country = '';
		if (this.selectedResult.address) {
			country = this.selectedResult.address.countryCode;
		} else {
			country = this.searchForm?.value?.countryCode;
		}
		if (country != '' && country != null && country != undefined) {
			this.homeService.getTranslations(country, categoryId).subscribe((resp: any) => {
				if (!resp.errors) {
					if (resp.success) {
						resp.data.find((obj: any) => {
							obj['checked'] = false;
							obj.reportTypeData = JSON.parse(obj.reportTypeData);
						})
						this.translationLanguage = resp.data;
						if (this.translationLanguage.length > 0) {
							for (var i = 0; i > this.translationLanguage.length; i++) {
								this.translationLanguage[i].isExpandReport = false
							}
							this.noTranslation = false;
						} else {
							this.noTranslation = true;
						}
					} else {
						this.noTranslation = true;
					}
				}

			})
		}
	}

	add(event: MatChipInputEvent): void {
		const input = event.chipInput;
		const value = event.value;

		if ((value || '').trim()) {
			if (this.notificationEmailList.length == 5) {
				this.emailMaxError = true;
				setTimeout(() => {
					this.emailMaxError = false;
				}, 1500);
			} else {
				this.emailMaxError = false;
			}
			if (this.validateEmail(value.trim())) {
				this.emailError = false;
				this.emailExistError = false;
				this.maxLengthError = false;
				if (value.length <= 50) {
					if (this.notificationEmailList.length < 5) {
						if (this.notificationEmailList.indexOf(value.trim()) !== -1) {
							this.emailExistError = true;
							setTimeout(() => {
								this.emailExistError = false;
							}, 1500);
						} else {
							this.emailRequiredError = false;
							this.emailExistError = false;
							this.notificationEmailList.push(value.trim());
						}
					}
				} else {
					this.maxLengthError = true;
					setTimeout(() => {
						this.maxLengthError = false;
					}, 1500);
				}
			} else {
				this.emailError = true;
				this.emailExistError = false;
				this.maxLengthError = false;
				setTimeout(() => {
					this.emailError = false;
				}, 1500);
			}
		}

		// Reset the input value
		if (input) {
			input.clear();
		}
		this.fruitCtrl.setValue(null);
	}

	validateEmail(email: any) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	remove(fruit: string): void {
		this.emailMaxError = false;
		const index = this.notificationEmailList.indexOf(fruit);
		if (index >= 0) {
			this.notificationEmailList.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.notificationEmailList.push(event.option.viewValue);
		this.fruitInput.nativeElement.value = '';
		this.fruitCtrl.setValue(null);
	}

	checkIfPresent(value: any) {
		return this.selectedTranslationReportType[value.translationLanguage];
	}

	selectLanguageTranslation(ev: any, data: any) {
		this.translationLanguage[0].checked = ev.target.checked;
		if (ev.target.checked) {
			var obj: any
			if (this.isOfflineReport) {
				obj = {
					"UserId": localStorage.getItem('userID'),
					"EntityName": this.offlineResult.entityName ? this.offlineResult.entityName : this.searchForm.value.name,
					"categoryId": this.selectedResult.categoryId ? this.selectedResult.categoryId : this.searchForm.value.categoryId,
					"ProductOfferingId": this.selectedTranslationReportType[data.translationLanguage]?.TranslationOfferingId,
					"OrderType": "SINGLE",
					"SellingPrice": parseFloat((this.selectedTranslationReportType[data.translationLanguage]?.ProductPrice)?.replace(',', '')),
					"scr": this.selectedResult.scr ? this.selectedResult.scr : '',
					"gbin": '',
					"fileRef": this.offlineResult.fileRef ? this.offlineResult.fileRef : '',
					"city": this.offlineResult?.city ? this.offlineResult?.city : '',
					"Address": this.offlineResult.entityAddressLine ? this.offlineResult.entityAddressLine : '',
					"NotifyEmail": this.notificationEmailList.join(',')
				}
			} else {
				obj = {
					"UserId": localStorage.getItem('userID'),
					"EntityName": this.selectedResult.name ? this.selectedResult.name : this.searchForm.value.name,
					"categoryId": this.selectedResult.categoryId ? this.selectedResult.categoryId : this.searchForm.value.categoryId,
					"ProductOfferingId": this.selectedTranslationReportType[data.translationLanguage]?.TranslationOfferingId,
					"OrderType": "SINGLE",
					"SellingPrice": parseFloat((this.selectedTranslationReportType[data.translationLanguage]?.ProductPrice)?.replace(',', '')),
					"scr": this.selectedResult.scr ? this.selectedResult.scr : '',
					"gbin": this.selectedResult.gbin ? this.selectedResult.gbin : '',
					"fileRef": this.searchForm?.value?.entity_num ? this.searchForm?.value?.entity_num : '',
					"city": this.searchForm?.value?.city_state ? this.searchForm?.value?.city_state : '',
					"Address": this.selectedResult.address ? this.getParsedAddress(this.selectedResult.address) : '',
					"NotifyEmail": this.notificationEmailList.join(',')
				}
			}
			this.selectedLanguageTranslation.push(obj);
			this.totalPrice = parseFloat(this.selectedTranslationReportType[data.translationLanguage]?.ProductPrice) ? this.totalPrice + parseFloat((this.selectedTranslationReportType[data.translationLanguage]?.ProductPrice).replace(',', '')) : this.totalPrice;
		} else {
			this.selectedLanguageTranslation.forEach((res: any, index: any) => {
				for (const key of Object.keys(this.selectedTranslationReportType)) {
					if (res.ProductOfferingId == this.selectedTranslationReportType[key].TranslationOfferingId) {
						this.selectedLanguageTranslation.splice(index, 1);
						this.totalPrice = this.totalPrice - parseFloat(this.selectedTranslationReportType[key].ProductPrice);
					}
				}
			});
		}
	}

	handlePaste(e: any) {
		// var ENGLISH: any = {};
		// "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_~`!@#$%^*/+=|(){};:.,?[]'\" ".split("").forEach(function (ch) {
		// 	ENGLISH[ch] = true;
		// });

		var clipboardData, pastedData;
		clipboardData = e.clipboardData;
		pastedData = clipboardData.getData('Text');

		// var index;

		if (pastedData == '>' || pastedData == '<' || pastedData == '&') {
			e.preventDefault();
			return false;
		} else if (pastedData.indexOf('<') > -1 || pastedData.indexOf('>') > -1 || pastedData.indexOf('&') > -1) {
			e.preventDefault();
			return false;
		} else {
			return true;
		}

		// for (index = pastedData.length - 1; index >= 0; --index) {
		// 	if (!ENGLISH[pastedData.substring(index, index + 1)]) {
		// 		e.preventDefault();
		// 		return false;
		// 	}
		// }
	}

	onInputchange(e: any) {
		// var inp = String.fromCharCode(e.keyCode);

		// if (e.code == 'Enter' || e.key == ".") {
		// 	return true;
		// }
		if (e.key == '<' || e.key == '>' || e.key == '&') {
			e.preventDefault();
			return false;
		}
		// if (/[a-zA-Z0-9-_~`!@#$%^*/+=|(){};:.,"? ]/.test(inp)) {
		// 	return true;
		// }
		// else {
		// 	e.preventDefault();
		// 	return false;
		// }
	}

	/******************************CART FUNCTIONALITY**************************** */

	addToCart() {
		if ($('.table-variation').find('input[type=radio]:checked').length > 0) {
			this.reportVariationError = false;
			$('.table-variation').parent().removeClass('field-error');
			if (this.notificationEmailList.length == 0) {
				this.emailRequiredError = true;
				return;
			} else {
				this.emailRequiredError = false;
			}
			this.loaderService.isLoading.next(true);
			this.selectedLanguageTranslation.forEach((item: any) => {
				item.NotifyEmail = this.notificationEmailList.join(',')
			});
			var payload: any;
			if (this.isOfflineReport) {
				payload = [{
					"UserId": localStorage.getItem('userID'),
					"EntityName": this.offlineResult.entityName,
					"categoryId": this.selectedReportVariation.categoryId,
					"ProductOfferingId": this.selectedReportVariation.productOfferingId,
					"OrderType": "SINGLE",
					"SellingPrice": parseFloat(this.selectedReportVariation.productPrice),
					"scr": this.selectedReportVariation.scr ? this.selectedReportVariation.scr : '',
					"gbin": '',
					"fileRef": this.offlineResult.fileRef ? this.offlineResult.fileRef : '',
					"city": this.offlineResult?.city ? this.offlineResult?.city : '',
					"Address": this.offlineResult.entityAddressLine ? this.offlineResult.entityAddressLine : '',
					"NotifyEmail": this.notificationEmailList.join(','),
					"Children": this.selectedLanguageTranslation,
					"Remark": this.additionalRemark,
					"CompanyContactNo": this.offlineResult.entityPhoneNumber ? this.offlineResult.entityPhoneNumber : '',
					"CountryCode": this.searchForm.value.countryCode
				}];
			} else {
				payload = [{
					"UserId": localStorage.getItem('userID'),
					"EntityName": this.selectedResult.name,
					"categoryId": this.selectedResult.categoryId,
					"ProductOfferingId": this.selectedReportVariation.productOfferingId,
					"OrderType": "SINGLE",
					"SellingPrice": parseFloat(this.selectedReportVariation.productPrice),
					"scr": this.selectedResult.scr ? this.selectedResult.scr : '',
					"gbin": this.selectedResult.gbin,
					"fileRef": this.searchForm?.value?.entity_num ? this.searchForm?.value?.entity_num : '',
					//	"city": this.searchForm?.value?.city_state ? this.searchForm?.value?.city_state : '',
					"city": this.selectedResult.address.city ? this.selectedResult.address.city : this.searchForm?.value?.city_state,
					"Address": this.getParsedAddress(this.selectedResult.address),
					"NotifyEmail": this.notificationEmailList.join(','),
					"Children": this.selectedLanguageTranslation,
					"Remark": this.additionalRemark,
					"CompanyContactNo": '',
					"CountryCode": this.searchForm.value.countryCode
				}];
			}
			
			this.homeService.addToCart(payload).subscribe((data: any) => {
				if (data) {
					this.getCartList();
					this.changeCountry();
					localStorage.setItem("cartInserted", "true");
					this.notificationEmailList = [];
					this.reportVariationList = [];
					this.reportComparisonList = [];
					this.holidayList = [];
					this.selectedReportVariation = {};
					this.isNotInstantReport = false;
					this.selectedOfferingId = '';
					this.selectedLanguageTranslation = [];
					this.selectedTranslationReportType = {};
					$('.cart-selection').removeClass('hidden');
					$('.after-selection').addClass('hidden');
					$('.before-selection').removeClass('hidden');
					this.resetForm();
				} else {
					this.loaderService.isLoading.next(false);
				}
				this.isOfflineReport = false;
				this.additionalRemark = '';
				this.clientRefNo = '';
				this.totalPrice = 0.0;
				$('html, body').animate({ scrollTop: $('#cartDetailsId').position().top }, 'slow');
			}, err => {
				//harshad
				this.totalPrice = 0.0;
				//harshad
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.addToCartFailed']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.addToCart();
						} else {
							this.onChangeSelection();
							this.additionalRemark = '';
						}
					});
				});
			});
		} else {
			$('.table-variation').parent().addClass('field-error');
			this.reportVariationError = true;
		}
	}

	clickHereToSearch() {
		$('.after-selection').addClass('hidden').siblings('.after-selection').removeClass('hidden');
		$('.before-selection').removeClass('hidden');
		this.selectedResult = {};
		this.offlineResult = {};
		this.isOfflineReport = false;
		this.notificationEmailList = [];
		$('html, body').animate({ scrollTop: $('#searchDetailsId').position().top }, 'slow');
	}

	getCartList() {
		this.loaderService.isLoading.next(true);
		this.homeService.getCartList().subscribe((data: any) => {
			if (data.success) {
				this.toggleGridFlag = false;
				this.cartList = data.data.map((record: any) => ({
					...record,
					expanded: false,
				}));
				if (this.cartList?.length > 0) {
					this.notificationEmailList = [];
					this.reportVariationList = [];
					this.reportComparisonList = [];
					this.holidayList = [];
					this.selectedReportVariation = {};
					this.isNotInstantReport = false;
					$('.cart-selection').removeClass('hidden');
					$('.after-selection').addClass('hidden');
					$('.before-selection').removeClass('hidden');
				}
				this.gridOptions.rowData = []
				this.totalCartValue = "0.00";
				this.selectedCartList = [];
				this.gridOptions.rowData = this.cartList;
				let self = this;
				setTimeout(() => {
					self.toggleGridFlag = true;
					if (!this.uploadingFile)
						self.loaderService.isLoading.next(false);
				}, 500);
			} else {
				if (!this.uploadingFile)
					this.loaderService.isLoading.next(false);
				this.dialog.open(ErrorDialogComponent, {
					width: this.isMobile ? 'auto' : '37vw',
					data: {
						errorMessage: data?.errors[0].errorMsg
					}
				});
			}
		}, err => {
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				this.loaderService.isLoading.next(false);
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: this.isMobile ? 'auto' : '37vw',
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.getCartList();
					}
				});
			});
		});
	}

	onSelectionChanged(params: any) {
		let self = this;
		setTimeout(() => {
			let rows = self.gridOptions.api.getSelectedNodes();
			if (self.selectedRowData.data.parentCartSeqNo) {
				let flag = rows.some((obj: any) => (obj.data.cartSeqNo == self.selectedRowData.data.cartSeqNo));
				let subFlag = self.cartList.some((obj: any) => (obj.cartSeqNo == self.selectedRowData.data.parentCartSeqNo));
				let subParentFlag = rows.some((obj: any) => (obj.data.cartSeqNo == self.selectedRowData.data.parentCartSeqNo));
				rows.filter(function (value: any) {
					if (flag) {
						if (value.data.cartSeqNo == self.selectedRowData.data.parentCartSeqNo) {
							self.selectedRowData.node.setSelected(true);
						}
						if (subFlag && subParentFlag) {
							self.selectedRowData.node.setSelected(true);
						} else if (subFlag && !subParentFlag) {
							self.selectedRowData.node.setSelected(false);
						} else if (!subFlag && !subParentFlag) {
							self.selectedRowData.node.setSelected(true);
						}
					}
				});
			} else {
				let flag = rows.some((obj: any) => (obj.data.cartSeqNo == self.selectedRowData.data.cartSeqNo));
				if (!flag) {
					rows.filter(function (value: any) {
						if (value.data.parentCartSeqNo == self.selectedRowData.data.cartSeqNo) {
							value.setSelected(false);
						}
					});
				}
			}
		}, 50);
	}

	onRowSelected(params: any) {
		this.selectedRowData = params;
		let rows = this.gridOptions.api.getSelectedNodes()
		let self = this;
		self.selectedCartList = [];
		self.submitOrderResponse = [];
		rows.filter(function (value: any) {
			self.selectedCartList.push(value.data);
			self.submitOrderResponse.push(value.data.cartSeqNo);
		});
		setTimeout(() => {
			self.calculateTotalCartValue();
		}, 500);
	}

	onFilterChanged(params: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.forEachNode((node: any) => node.setSelected(false));
		this.selectedRowData = {};
		let self = this;
		self.selectedCartList = [];
		self.submitOrderResponse = [];
		setTimeout(() => {
			self.calculateTotalCartValue();
		}, 500);
	}

	calculateTotalCartValue() {
		let price = 0.00;
		this.selectedCartList.find((obj: any) => {
			price += parseFloat(obj.sellingPrice);
		})
		this.totalCartValue = price.toFixed(2);
	}

	cartActionItems(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
		if (ev.label === 'delete') {
			this.confirmDialogRef = this.dialog.open(ConfimDialogComponent, {
				disableClose: true,
				width: this.isMobile ? 'auto' : '37vw'
			});
			this.translateService.get(['confirmAction.confirmMessage']).subscribe(value => {
				this.confirmDialogRef.componentInstance.confirmMessage = value['confirmAction.confirmMessage'];
			});
			this.confirmDialogRef.afterClosed().subscribe(result => {
				this.loaderService.isLoading.next(true);
				if (result) {
					this.homeService.deleteCartItem(ev.data.cartSeqNo).subscribe((data: any) => {
						this.getCartList();
						let self = this;
						self.selectedCartList = [];
						self.submitOrderResponse = [];
					}, err => {
						this.loaderService.isLoading.next(false);
						this.translateService.get(['errorMsg.failedToDeleteCart']).subscribe(value => {
							let dialogRef = this.dialog.open(ErrorDialogComponent, {
								disableClose: true,
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									errorMessage: value['errorMsg.failedToDeleteCart']
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								if (res) {
									this.cartActionItems(ev);
								}
							});
						});
					});
				} else {
					this.loaderService.isLoading.next(false);
				}
			});
		}
		if (ev.label === 'jp_translation') {
			let country = this.countryList.find((obj: any) => (obj.countryName == ev.data.countryName));
			this.loaderService.isLoading.next(true);
			let categoryId = ev.data.categoryId;
			this.homeService.getTranslations(country.countryCode, categoryId).subscribe((data: any) => {
				if (data.data != null) {
					let translateObject = data.data.find((item: any) => (item.translationLanguage == "Japanese"));
					translateObject.reportTypeData = JSON.parse(translateObject?.reportTypeData);
					translateObject.reportTypeData.find((res: any) => {
						if ((ev.data.productOfferingId) == (res.ReportOfferingId)) {
							let obj = {
								"ProductOfferingId": res.TranslationOfferingId,
								"OrderType": "SINGLE",
								"SellingPrice": parseFloat(res.ProductPrice.replace('', '')),
							}
							let arr = [];
							arr.push(obj);
							this.loaderService.isLoading.next(true);
							this.homeService.addChildToCart(arr, ev.data.cartSeqNo).subscribe((data: any) => {
								if (data) {
									this.getCartList();
								} else {
									this.loaderService.isLoading.next(false);
								}
								this.additionalRemark = '';
							}, err => {
								this.loaderService.isLoading.next(false);
								this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
									this.dialog.open(NoResultDialogComponent, {
										width: this.isMobile ? 'auto' : '37vw',
										data: {
											errorMessage: value['errorMsg.addToCartFailed']
										}
									});
								});
							});
						}
					})
					if (translateObject.reportTypeData.length == 0) {
						this.loaderService.isLoading.next(false);
						this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
							this.dialog.open(NoResultDialogComponent, {
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									errorMessage: value['errorMsg.pleaseTryAgain']
								}
							});
						});
					}
				} else {
					this.loaderService.isLoading.next(false);
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						this.dialog.open(NoResultDialogComponent, {
							width: this.isMobile ? 'auto' : '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
					});
				}
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					this.dialog.open(NoResultDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
				});
			});
		}
	}

	getParsedAddress(address: any) {
		if (address) {
			return Object.keys(address).map(function (k) { return address[k] }).join(",");
		} else {
			return '';
		}
	}

	submitOrder() {
		this.loaderService.isLoading.next(true);
		let balancePointRef = localStorage.getItem('balancePoint');
		let balancePoint = 0.0;
		if (balancePointRef) {
			balancePoint = parseFloat(balancePointRef);
		}
		if (balancePoint < parseFloat(this.totalCartValue) && this.paymentMethod != 'Postpaid') {
			let obj: any = { balance: balancePoint.toFixed(2), totalPrice: this.totalCartValue }
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.balancePoint']).subscribe(value => {
				this.dialog.open(ErrorDialogComponent, {
					disableClose: true,
					width: this.isMobile ? 'auto' : '37vw',
					data: {
						errorMessage: value['errorMsg.balancePoint'],
						balanceInfo: obj,
						tryAgain: false,
						isOk: true
					}
				});
			});
		} else {
			this.homeService.submitOrder(this.submitOrderResponse).subscribe((data: any) => {
				if (data.errors?.length > 0) {
					this.loaderService.isLoading.next(false);
					if (data?.errors[0].errorObj) {
						let obj: any = { balance: data?.errors[0].errorObj?.BalancePoints ? data?.errors[0].errorObj?.BalancePoints : 0, totalPrice: this.totalPrice }
						if (data?.errors[0].errorObj) {
							if (!data?.errors[0].errorObj?.BalancePoints) {
								obj = null;
							}
						}
						this.dialog.open(ErrorDialogComponent, {
							disableClose: true,
							width: this.isMobile ? 'auto' : '37vw',
							data: {
								errorMessage: data?.errors[0].errorMsg, //'Insufficient points to proceed your search',
								balanceInfo: obj,
								tryAgain: obj != null ? false : true
							}
						});
					} else {
						this.translateService.get(['errorMsg.submitOrder']).subscribe(value => {
							this.dialog.open(ErrorDialogComponent, {
								disableClose: true,
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									errorMessage: value['errorMsg.submitOrder'],
									tryAgain: false
								}
							});
						});
					}
				} else {
					if (data.success) {
						this.loaderService.isLoading.next(false);
						localStorage.removeItem("cartInserted");
						this.router.navigate(['/thank-you'], { state: { orderResponse: data.data.items, balanceDetails: data.data.points, paymentMethod: this.paymentMethod, dataList: this.selectedCartList, countryList: this.countryList } });
					} else {
						this.loaderService.isLoading.next(false);
						this.translateService.get(['errorMsg.submitOrder']).subscribe(value => {
							let dialogRef = this.dialog.open(ErrorDialogComponent, {
								disableClose: true,
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									errorMessage: value['errorMsg.submitOrder'],
									tryAgain: false,
									isOk: true
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								this.getCartList();
							});
						});
					}
				}
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.submitOrder']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.submitOrder'],
							tryAgain: false,
							isOk: true
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						this.getCartList();
					});
				});
			});
		}
	}

	onPageSizeChanged() {
		var value = document.getElementById('page-size');
		this.gridOptions.api.paginationSetPageSize(Number(value));
	}

	currencyFormatter(currency: any) {
		var sansDec = currency.toFixed(2);
		var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formatted}`;
	}

	changeLanguage(ev: any) {
		this.getColDefs();
		if (!$('.before-selection').hasClass('hidden')) {
			this.selectedLang = ev;
			this.productList.forEach((item: any) => {
				this.searchForm.controls['categoryId'].setValue(item.categoryId);
				this.formFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;
				this.dynamicFields = this.selectedLang == 'jp' ? item.formFieldJpn : item.formField;
			});
		}
	}

	//===========================Bulk Uploade ======================	

	bulkUpload() {
		this.uploadingFile = true;
		let dialogRef = this.dialog.open(BulkUploadComponent, {
			disableClose: true,
			width: "680px",
			height: "510px",
			panelClass: 'advanced-search-dialog',
		});
		dialogRef.afterClosed().subscribe(res => {
			this.uploadingFile = false;
			if (res) {
				this.bulkUploadDetails = null;
				this.bulkUploadDetails = res;
				this.bulkuploadLinkStatus = res;
			}
		});
	}

	getBulkUploadDetails() {
		this.loaderService.isLoading.next(true);
		this.homeService.getBulkUploadDetails().subscribe((resp: any) => {
			this.loaderService.isLoading.next(false);
			if (resp.success) {
				if (resp.data?.bulkOrderStatus == 'Uploaded') {
					this.uploadService.cancelBulkUpload(resp.data.bulkOrderNumber).subscribe(
						(res: any) => {
							this.bulkUploadDetails = res.data;
							this.bulkuploadLinkStatus = res.data;
						}
					);
				} else {
					this.bulkUploadDetails = resp.data;
					this.bulkuploadLinkStatus = resp.data;
				}
			}
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	getCommpleteStatus(event: any) {
		this.bulkuploadLinkStatus = event;
	}

	ExpandAll(data: any) {
		if (!data) {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		} else {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}
}
