<app-header (langChange)="changeLanguage($event)" [title]="title"></app-header>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'app.orderHistory' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'orderHistory.collectOrView' | translate }}
                </p>
            </div>

            <div class="zanna_div row desktop-view">
                <div class="col" style="padding: 0px; display: inline-flex;">
                    <div class="order-status-bar">
                        <a data-filter="uncollected" [ngClass]="selectedStatusBar == 'uncollected' ? 'selected' : ''"
                            style="cursor: pointer;" (click)="changeStatusBar('uncollected')">
                            <span>
                                {{ 'orderHistory.unCollectedOrders' | translate }}
                                <span>{{ uncollectedCount }}</span>
                            </span>
                        </a>
                        <a data-filter="collected" [ngClass]="selectedStatusBar == 'collected' ? 'selected' : ''"
                            style="cursor: pointer;" (click)="changeStatusBar('collected')">
                            <span>{{ 'orderHistory.collectedOrders' | translate }}</span>
                        </a>
                        <a data-filter="processing" [ngClass]="selectedStatusBar == 'processing' ? 'selected' : ''"
                            style="cursor: pointer;" (click)="changeStatusBar('processing')">
                            <span>{{ 'orderHistory.processing' | translate }}</span>
                        </a>
                        <a data-filter="archived" [ngClass]="selectedStatusBar == 'archived' ? 'selected' : ''"
                            style="cursor: pointer;" (click)="changeStatusBar('archived')">
                            <span>{{ 'orderHistory.archived' | translate }}</span>
                        </a>
                        <a data-filter="" style="cursor: pointer;"
                            [ngClass]="selectedStatusBar == 'all' ? 'selected' : ''" (click)="changeStatusBar('all')">
                            <span>{{ 'orderHistory.allOrders' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="mobile-view">
                <select name="" id="tab-section" [(ngModel)]="option" (change)="changeStatusBar(option)">
                    <option *ngFor="let opt of options" [value]="opt.key">
                        {{ opt.value | translate }}
                        <span class="tab-notification-badge" *ngIf="opt.key=='uncollected'">
                            ({{ uncollectedCount }})
                        </span>
                    </option>
                </select>
            </div>
            <div class="zanna_div row">
                <div class="data-grid-strip">
                </div>
                <div class="btn-data-grid-strip border-b-n">
                    <div (click)=ExpandAll(allExpand) class="arrow-container"
                        *ngIf="isMobile && orderHistoryList.length>0">
                        <mat-icon class="down-arrow-icon icon-color" *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                        <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                        <span>{{expandCollapseButtonValue}}</span>
                    </div>
                </div>
                <ag-grid-angular class="ag-theme-alpine" id="orderHistoryGrid" [rowData]="orderHistoryList"
                    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay" (filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout" [icons]="icons" [masterDetail]="true" [detailRowHeight]="detailRowHeight"
                    [detailCellRenderer]="detailCellRenderer">
                </ag-grid-angular>
                <app-pagination *ngIf="orderHistoryList.length > 0" [pageSize]="PaginationPageSize"
                    [gridOptions]="gridOptions" [noOfPages]="gridOptions.api?.paginationGetTotalPages()"
                    [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
        </article>
    </section>
</main>
<app-footer></app-footer>