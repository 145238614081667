import { Component, Inject, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { CustomFilterComponent } from '../../../custom-filter/custom-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { GridCellRendererComponent } from 'src/app/grid-cell-renderer/grid-cell-renderer.component';
import { SearchResultDataCellRenderer } from 'src/app/search-results/search-result-grid-data/search-result-grid-data.component';
import { MonitorResultDataCellRenderer } from './monitor-result-grid-data/monitor-result-grid-data.component';
import { MyNoRowsComponent } from './no-overlay.component';
import { MonitoringService } from '../../monitoring.service';
import { NameRendererComponent } from './name-renderer/name-renderer.component';
declare const $: any;
import * as moment from 'moment';

@Component({
	selector: 'app-monitor-search',
	templateUrl: './monitor-search.html',
	styleUrls: ['./monitor-search.scss'],
	encapsulation: ViewEncapsulation.None
})

export class MonitoringSearchComponent implements OnInit, AfterViewInit, OnDestroy {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColumnDefs();
		} else {
			this.isMobile = true;
			this.getColumnDefs();
		}
	}
	detailCellRenderer:any;
	detailRowHeight:any;
	searchColumnDefs: any = [];
	gridOptions: any;
	disabledFlag: boolean = false;
	errorMsgFlag: boolean = false;
	isRowSelected: boolean = false;
	offineReport: boolean = false;
	max120Characters: boolean = false;
	max200Characters: boolean = false;
	max50Characters: boolean = false;
	allowOnlyAlphabets: boolean = false;
	fileRefAllowOnlyAlphabets: boolean = false;
	max20Characters: boolean = false;
	max20Numbers: boolean = false;
	entityNameRequired: boolean = false;
	cityRequired: boolean = false;
	addressRequired: boolean = false;
	isValidNumber: boolean = false;
	manualSearch: boolean = false;
	entityEnglishValidation: boolean = false;
	addressEnglishValidation: boolean = false;
	selectedResult: any = {};
	searchResultList: any = [];
	gridColumnApi: any;
	icons: any;
	domLayout: any = "autoHeight";
	searchForm!: UntypedFormGroup;
	formData: any;
	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	constructor(public matDialogRef: MatDialogRef<MonitoringSearchComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: UntypedFormBuilder,
		public translateService: TranslateService, public service: MonitoringService) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.getColumnDefs();

		if (this.data.results) {
			this.searchResultList = this.data.results;
			console.log("this.searchResultList:", this.searchResultList);
			this.searchResultList.map((rec: any) => {
				// if(rec.isFmrNam != 'N') {
					console.log("see this;", rec.oldNames);
				// }
			})
		}

		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				this.gridOptions.api?.sizeColumnsToFit();
				if ((this.data.results).length > 3) {
					this.gridAPI.setDomLayout('normal');
					let element: any = document.getElementById('searchResultGrid');
					if (element) {
						element.style.height = '37vh';
					}
				}
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',// "<h4> No Search results found.<a id='manual' class='pointer-events: all; pointer: cursor;'> Add Manually</a>",
			noRowsOverlayComponentParams: {
				"component": "search-result"
			},
			rowSelection: 'single',
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true
			}
		};

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = this.isMobile? 160:250;
		this.gridOptions.frameworkComponents = {
			"customFloatingFilter": CustomFilterComponent,
			"cellRenderer": GridCellRendererComponent,
			"myDetailCellRenderer": MonitorResultDataCellRenderer,
			 myNoRowsComponent: MyNoRowsComponent,
			 "nameRenderer": NameRendererComponent,
		};
	}

	ngOnInit() {
		this.service.manualSubscriber.subscribe((manual: any) => { 
			if(manual == true) {
				this.matDialogRef.close('true');
			}
		});
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
	}

	addressFormator(param: any) {
		return "BLK "+ param.data.blkHse+ " "+ param.data.strNam + " # "+param.data.flr + "-"+ param.data.unt+ " "+ param.data.bld + " " + param.data.posCod;						
	}

	refresh() {
	}

	nameGetter(params: any) {
		if(params.data?.nam) {
			var name: any;
			params.data.oldNames?.map((rec: any) => {
				name = rec.nam;
			})
			return params.data.nam + name + params.data.entNo;
		} else {
			return params.data.entNo;
		}
	}

	getColumnDefs() {
		this.translateService.get(['monitor.entityIdentification', 'selectionTable.address', 'selectionTable.entityStatus', 'selectionTable.expiryDate']).subscribe(value => {
			this.searchColumnDefs = [
				{
					field: 'RowSelect',
					headerName: ' ',
					checkboxSelection: true,
					suppressSorting: true,
					sortable: false,
					maxWidth: 45,
					width: 45,
					cellStyle: { 'overflow': 'visible', 'justify-content': 'center', 'padding-top': '5px'}
				},
				{
					field: 'nam',
					valueGetter: this.nameGetter,
					headerName: value['monitor.entityIdentification'],					
					minWidth: this.isMobile? 200 : 300,
					width: this.isMobile? 200 : 300,
					floatingFilter: this.isMobile? false: true,
					filter: 'agTextColumnFilter',
					cellRenderer: "nameRenderer"
				},
				{
					field: 'nam',
					headerName: value['selectionTable.address'],
					minWidth: 300,
					width: 300,
					hide: this.isMobile,
					floatingFilter: this.isMobile? false : true,
					filter: 'agTextColumnFilter',
					valueGetter: this.addressFormator,
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px','font-size': '14px'}
				},
				{
					field: 'stt',
					headerName: value['selectionTable.entityStatus'],
					minWidth: 160,
					width: 160,
					hide: this.isMobile,
					floatingFilter: this.isMobile? false : true,
					filter: this.isMobile? false : true,
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
				},
				{
					field: 'expDat',
					valueGetter: this.dateGetter,
					headerName: value['selectionTable.expiryDate'],
					minWidth: 160,
					width: 160,
					hide: this.isMobile,
					filter: false,
					comparator: this.dateComparator,
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
				},
				{
					headerName: '',
					width: 60,
					maxWidth: 60,
					sortable: false,
					hide:this.isMobile? false:true,
					cellRenderer: "cellRenderer",
					cellRendererParams: {
						onClick: this.searchResultAction.bind(this),
						label: 'monitor-search-result'
					},
					cellStyle: {'font-size': '14px'}
				}

			];
		})
	}
	searchResultAction(ev: any) {
		if (ev.label === 'expand') {
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
	}

	dateGetter(params: any) {
		if(params.data.expDat) {
			return moment(params.data.expDat, 'DD/MM/YYYY').format('DD/MM/YYYY');
		} else {
			return " ";
		}
	}

	dateComparator(date1: any, date2: any) {
		// var date1: any = moment(date11).format('DD/MM/YYYY');
		// var date2: any = moment(date22).format('DD/MM/YYYY');
		var result1: any;
		var result2: any;
		if (date1 === undefined || date1 === null || date1.length !== 10) {
			result1 = null;
		} else{
			var yearNumber1: any = date1.substring(6, 10);
			var monthNumber1: any = date1.substring(3, 5);
			var dayNumber1: any = date1.substring(0, 2);
			result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
		}
		
		if (date2 === undefined || date2 === null || date2.length !== 10) {
			result2 = null;
		} else{
			var yearNumber2: any = date2.substring(6, 10);
			var monthNumber2: any = date2.substring(3, 5);
			var dayNumber2: any = date2.substring(0, 2);
			result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
		}
		if (result1 === null && result2 === null) {
			  return 0;
		}
		if (result1 === null) {
			return -1;
		}
		if (result2 === null) {
			return 1;
		}
		return result1 - result2;
	}

	onSelectionChanged(event: any) {
		let rows = this.gridOptions.api.getSelectedNodes();
		if (rows.length == 0) {
			this.isRowSelected = false;
			this.disabledFlag = true;
		}
	}

	onRowSearchSelected(params: any) {
		if (params.node.selected) {
			this.disabledFlag = false;
			this.isRowSelected = true;
			this.errorMsgFlag = false;
			$('.ag-theme-alpine').removeClass('chkbox');
			$('.ag-checkbox-input-wrapper').removeClass('chkbox');
			this.selectedResult = params.data;
		}
		if (!params.node.selected) {
			if (this.selectedResult) {
				if (this.selectedResult.gbin) {
					this.selectedResult = {};
				}
			}
		}
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
	}

	getParsedAddress(address: any) {
		if (address) {
			return Object.keys(address).map(function (k) { return address[k] }).join(", ");
		} else {
			return '';
		}
	}

	resultSelect() {
		if (this.isRowSelected) {
			this.selectedResult['type'] = 'online';
			this.matDialogRef.close(this.selectedResult);
		} else {
			this.errorMsgFlag = true;
			this.disabledFlag = true;
			$('.ag-theme-alpine').addClass('chkbox');
			$('.ag-checkbox-input-wrapper').addClass('chkbox');
		}
	}

	searchResults(value: any) {
		if (this.searchForm.invalid) {
			if (this.searchForm.get('entityName')?.status === 'INVALID') {
				this.entityNameRequired = true;
			}
			if (this.searchForm.get('city')?.status === 'INVALID') {
				this.cityRequired = true;
			}
			if (this.searchForm.get('entityAddressLine')?.status === 'INVALID') {
				this.addressRequired = true;
			}
			return;
		} else {
			if (this.max120Characters || this.max200Characters || this.max20Characters || this.max50Characters || this.allowOnlyAlphabets || this.max20Numbers || this.addressEnglishValidation || this.fileRefAllowOnlyAlphabets || this.entityEnglishValidation) {
				return false;
			} else {
				value['type'] = 'offline'
				this.selectedResult = value;
				this.matDialogRef.close(value);
			}
		}
	}

	ngOnDestroy() {
		$('.ag-theme-alpine').removeClass('chkbox');
		$('.ag-checkbox-input-wrapper').removeClass('chkbox');
	}
}