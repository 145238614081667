import { Component, OnInit, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MonitoringService } from '../../monitoring.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-export-all-alert',
  templateUrl: './export-all-alert.component.html',
  styleUrls: ['./export-all-alert.component.scss']
})
export class ExportAllAlertComponent implements OnInit {
  isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
  years: any = [];
  month: any = [];
  export: any;
  currentYear: any;
  currentMonth: any;
  summary: any;
  alertType: any;
  alertList: any;
  BWSExportList: any;
  exportOtherInfoList: any;
  DNSAExportList: any;
  proceedToNBBExcel: boolean = false;
  proceedToCMSExcel: boolean = false;
  exportNBLList: any;
  exportNBBList: any;
	exportCmsPayment: any;
  intervalSubscription: Subscription;
	exportCmsLitigationBasic: any;
	exportCmsLitigationEnhanced: any;
  exportLmsAlerts: any;
  noAlerts: boolean = false;
  showTip: boolean = true;
  currentDate: any = moment(new Date().setDate(new Date().getDate() - 1)).format('DD MMM YYYY');
  options: any = [
    // {
    //   "key": moment(new Date()).format('DD MMM YYYY'),
    //   "value": "Today's Alerts"
    // },
    {
      "key": moment(new Date()).format('DD MMM YYYY'),
      "value": "Yesterday's Alerts"
    },
    {
      "key": moment(new Date()).format('DD MMM YYYY'),
      "value": "Last 7 Days' Alerts"
    },
    {
      "key": moment(new Date()).format('DD MMM YYYY'),
      "value": "Last 30 Days' Alerts"
    },
    {
      "key": moment(new Date()).format('DD MMM YYYY'),
      "value": "Last 90 Days' Alerts"
    }
  ];
  constructor(public matDialogRef: MatDialogRef<ExportAllAlertComponent>, public router: Router, public loaderService: LoaderService, public translateService: TranslateService,   
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, public monitoringService: MonitoringService, private dialog: MatDialog,) { 
      if(window.screen.width > 1000) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }    
      
      this.export = this.options[0].key;
      this.alertList = data;
      if(this.alertList?.length > 0)
      this.alertType = this.alertList[0]?.monitoringType;
      var summaryData = localStorage.getItem("summary");
      if (summaryData != undefined && summaryData != null) {
        var parsed = JSON.parse(summaryData);
        parsed?.map((res: any) => {
          if(res.monitoringType == this.alertType && (this.alertType == 'BWS' || this.alertType == 'CMS')) {
            this.summary = res;
            console.log("summary", this.summary);
          }
        });
      }
      this.loaderService.isLoading.next(true);
      this.monitoringService.getUserExportPreferenceDetail().subscribe(
        (res: any) => {
          this.loaderService.isLoading.next(false);
          if(res?.success && res?.data.length > 0) {
            this.alertType = res.data[0].alertType;
            var value: any = res.data[0].alertDuration;
            this.options.map((option: any) => {
              if(value == option.value) {
                this.export = option.key;
              }
            })
            console.log("saved", res, this.alertType, this.export);
            if (summaryData) {
              var parsed = JSON.parse(summaryData);
              parsed?.map((res: any) => {
                if(res.monitoringType == this.alertType && (this.alertType == 'BWS' || this.alertType == 'CMS')) {
                  this.summary = res;
                  console.log("summary", this.summary);
                }
              });
            }
          }
        },
        (err: any) => {
          this.loaderService.isLoading.next(false);
          console.log("fetching error");
        }
      );

      this.options[0].key = moment(new Date().setDate(new Date().getDate() - 1)).format('DD MMM YYYY');
      this.options[1].key = moment(new Date().setDate(new Date().getDate() - 7)).format('DD MMM YYYY');
      this.options[2].key= moment(new Date().setDate(new Date().getDate() - 30)).format('DD MMM YYYY');
      this.options[3].key= moment(new Date().setDate(new Date().getDate() - 90)).format('DD MMM YYYY');
      
  }

  ngOnInit(): void {
    const now = new Date().getUTCFullYear();
    this.currentYear = now;
    this.years = Array(now - (now - 20)).fill('').map((v, idx) => now - idx).reverse();
    this.month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.currentMonth = this.month[new Date().getMonth() + 1];
  }

  view() {
    localStorage.removeItem("entityId");
    if(this.export == this.options[0].key)
    this.currentDate = this.export; 
    localStorage.setItem('dateFrom', moment(this.export).format('YYYY-MM-DD'));
    localStorage.setItem('dateTo', moment(this.currentDate).format('YYYY-MM-DD'));
    window.open('#'+ this.router.url+'/'+ this.alertType, "_blank");
    var duration: any;
    this.options.map((option: any) => {
      if(this.export == option.key) {
        duration = option.value;
      }
    });
    this.monitoringService.saveUserExportPreferenceDetail(this.alertType, duration).subscribe(
      (res: any) => {
        console.log("success");
      }
    );
    this.matDialogRef.close(false)
  }

  exportAlerts() {
    var duration: any;
    this.options.map((option: any) => {
      if(this.export == option.key) {
        duration = option.value;
      }
    })
    this.monitoringService.saveUserExportPreferenceDetail(this.alertType, duration).subscribe(
      (res: any) => {
        console.log("success");
      }
    );
    this.exportAsExcelFile(moment(this.export).format('YYYY-MM-DD'), moment(this.currentDate).format('YYYY-MM-DD'));
  }

	openTryAgainExportPopup(startDate: any, endDate: any) {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.exportAsExcelFile(endDate, startDate);
				}
			});
		})
	}

  alertTypeChanged($event: any) {
    var summaryData = localStorage.getItem("summary");
    this.summary = null;
    if (summaryData) {
      var parsed = JSON.parse(summaryData);
      parsed?.map((res: any) => {
        if(res.monitoringType == this.alertType && (this.alertType == 'BWS' || this.alertType == 'CMS')) {
          this.summary = res;
          console.log("summary", this.summary);
        }
      });
    }
  }

	exportAsExcelFile(startDate: any, endDate: any): void {
    switch(this.alertType) {
      case 'BWS': 
          this.exportBWS(startDate, endDate);
          break;
      case 'LMS': 
          this.exportLMS(startDate, endDate);
          break;
      case 'DNSA':
            this.exportDNSA(startDate, endDate)
          break;
      case 'NBB':
          this.exportNBB(startDate, endDate);
          break;
      case 'CMS':
          this.exportCMS(startDate, endDate);
          break;
      default:
          break;
    }
	}

  exportBWS(startDate: any, endDate: any) {
    this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel(this.alertType , endDate, startDate, "ALL").subscribe((data: any) => {
        if (data?.success) {
          if(data?.data?.Alerts[0]?.BWS?.length || data?.data?.Alerts[1]?.BWS_OTHER_INFORMATION?.length) {
            this.noAlerts = false;
            this.BWSExportList = data?.data?.Alerts[0]?.BWS;
            this.exportOtherInfoList = data?.data?.Alerts[1]?.BWS_OTHER_INFORMATION;
            setTimeout(() => {
              this.loaderService.isLoading.next(false);
              let element = document.getElementById('excel-table');
              const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
              var wscols = [
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30}
              ];	
              ws['!cols'] = wscols;
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'BWS');
              console.log("this.exportOtherInfoList:", this.exportOtherInfoList);
              if(this.exportOtherInfoList?.length > 0) {
                console.log("this.exportOtherInfoList:", this.exportOtherInfoList);
                let elementOtherInfo = document.getElementById('other-info-excel-table');
                const otherInfo: XLSX.WorkSheet = XLSX.utils.table_to_sheet(elementOtherInfo, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                var wscols = [
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30}
                ];	
                otherInfo['!cols'] = wscols;
                XLSX.utils.book_append_sheet(wb, otherInfo, 'BWS Other Information');
              }
              if(this.summary) {
                let element1 = document.getElementById('summary-excel-table');
                const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                var wscolsExport = [
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30},
                  {wch:30}
                ];	
                wsSummary['!cols'] = wscolsExport;
                XLSX.utils.book_append_sheet(wb, wsSummary, 'BWS Summary');
              }
              XLSX.writeFile(wb, 'BWS_ExcelSheet.xlsx');
            }, 3000);
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        } else {
          this.loaderService.isLoading.next(false);
          this.noAlerts = true;
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.exportBWS(startDate, endDate);
            }
          });
        })
      });
    
      // this.matDialogRef.close(false)
  }

  exportLMS(startDate: any, endDate: any) {
    this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel(this.alertType , endDate, startDate, "ALL").subscribe((data: any) => {
        if (data?.success) {
          if(data?.data?.LMS) {
            this.noAlerts = false;
            this.exportLmsAlerts = data?.data?.LMS;
            setTimeout(() => {
              this.loaderService.isLoading.next(false);
              let element = document.getElementById('excel-table-lms');
              const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
              var wscols = [
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30}
              ];	
              ws['!cols'] = wscols;
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'LMS');
              XLSX.writeFile(wb, 'LMS_ExcelSheet.xlsx');
            }, 3000);
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        }else {
          this.loaderService.isLoading.next(false);
          this.noAlerts = true;
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.exportLMS(startDate, endDate);
            }
          });
        })
      });
    
      // this.matDialogRef.close(false)
  }

  exportDNSA(startDate: any, endDate: any) {
    this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel(this.alertType , endDate, startDate, "ALL").subscribe((data: any) => {
        if (data?.success) {
          if(data?.data?.DNSA?.length) {
            this.noAlerts = false;
            this.DNSAExportList = data?.data?.DNSA;
            setTimeout(() => {
              this.loaderService.isLoading.next(false);
              let element = document.getElementById('dnsa-excel-table');
              const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
              var wscols = [
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30}
              ];	
              ws['!cols'] = wscols;
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'DNSA');
              XLSX.writeFile(wb, 'DNSA_ExcelSheet.xlsx');
            }, 3000);
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        }else {
          this.loaderService.isLoading.next(false);
          this.noAlerts = true;
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.exportDNSA(startDate, endDate);
            }
          });
        })
      });
  }

  exportNBB(startDate: any, endDate: any) {
    var alertDetails = this.alertList.find((type: any) => type.monitoringType == 'NBB');

    if(alertDetails?.entitlement == 'Detailed') {
      this.proceedToNBBExcel = false;
        this.loaderService.isLoading.next(true);
        this.monitoringService.exportToExcel("NBB", endDate, startDate, "ALL").subscribe((data: any) => {
          this.proceedToNBBExcel = true;
          if (data?.success) {
            this.exportNBBList = data?.data?.NBB_DETAILED;
          }
        },
        (err: any) => {
          this.proceedToNBBExcel = true;
        });
        this.monitoringService.exportToExcel("NBB", endDate, startDate, "NBL").subscribe((data: any) => {
          if (data?.success) {
            this.noAlerts = false;
            this.exportNBLList = data?.data?.NBB_NBL;
            if(this.proceedToNBBExcel == false) {
              this.intervalSubscription = interval(10000).subscribe((x => {
                if(this.proceedToNBBExcel == true) {
                  this.intervalSubscription.unsubscribe();
                  setTimeout(() => {
                    this.loaderService.isLoading.next(false);
                    if( (this.exportNBLList && this.exportNBLList.length > 0) || (this.exportNBBList && this.exportNBBList.length > 0)) {
                    let element = document.getElementById('excel-table-nbb');
                      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols = [
                        {wch:50},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      ws['!cols'] = wscols;
                      const wb: XLSX.WorkBook = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, 'Payment(Detailed)');
                      let element1 = document.getElementById('excel-table-nbl');
                      const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols1 = [
                        {wch:40},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      ws1['!cols'] = wscols1;
                      XLSX.utils.book_append_sheet(wb, ws1, 'Payment(NL)');
                      XLSX.writeFile(wb, 'NBB_ExcelSheet.xlsx');
                    } else {
                      this.noAlerts = true;
                    }
                  }, 5000);
                }
              }));
            } else {
                setTimeout(() => {
                this.loaderService.isLoading.next(false);
                if( (this.exportNBLList && this.exportNBLList.length > 0) || (this.exportNBBList && this.exportNBBList.length > 0)) {
                let element = document.getElementById('excel-table-nbb');
                  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols = [
                    {wch:50},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30}
                  ];	
                  ws['!cols'] = wscols;
                  const wb: XLSX.WorkBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, 'Payment(Detailed)');
                  let element1 = document.getElementById('excel-table-nbl');
                  const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols1 = [
                    {wch:40},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30}
                  ];	
                  ws1['!cols'] = wscols1;
                  XLSX.utils.book_append_sheet(wb, ws1, 'Payment(NL)');
                  XLSX.writeFile(wb, 'NBB_ExcelSheet.xlsx');
                } else {
                  this.noAlerts = true;
                }
              }, 5000);
            }
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        },
        (err: any) => {
          this.loaderService.isLoading.next(false);
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              disableClose: true,
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.exportNBB(startDate, endDate);
              }
            });
          })
        });
        

		} else {
			this.loaderService.isLoading.next(true);
        this.monitoringService.exportToExcel("NBB", endDate, startDate, "ALL").subscribe((data: any) => {
          if (data?.success) {
            this.noAlerts = false;
            this.exportNBBList = data?.data?.NBB_BASIC;
            setTimeout(() => {
              this.loaderService.isLoading.next(false);
              if(this.exportNBBList && this.exportNBBList.length > 0) {
                let element = document.getElementById('excel-table-basic');
                const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                var wscols = [
                  {wch:30},
                  {wch:30},
                  {wch:30}
                ];	
                ws['!cols'] = wscols;
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Payment(Basic)');
                XLSX.writeFile(wb, 'NBB_ExcelSheet.xlsx');
              } else {
                this.noAlerts = true;
              }
            }, 1000);
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        },
        (err: any) => {
          this.loaderService.isLoading.next(false);
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              disableClose: true,
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.exportNBB(startDate, endDate);
              }
            });
          })
        });
        
		}	
  }

  exportCMS(startDate: any, endDate: any) {
    var alertDetails = this.alertList.find((type: any) => type.monitoringType == 'CMS');

    if(alertDetails?.entitlement == 'Enhanced') {
        this.loaderService.isLoading.next(true);
        this.proceedToCMSExcel = false;
        this.monitoringService.exportToExcel("CMS", endDate, startDate, "PAYMENT").subscribe((data: any) => {
          this.proceedToCMSExcel = true;
          if (data?.success) {
            this.exportCmsPayment = data?.data?.CMS_PAYMENT;
          }
        },
        (err: any) => {
          this.proceedToCMSExcel = true;
        });
        this.monitoringService.exportToExcel("CMS", endDate, startDate, "LITIGATION").subscribe((data: any) => {
          if (data?.success) {
            this.noAlerts = false;
            this.exportCmsLitigationEnhanced = data?.data?.CMS_LIT_ENHANCED;
            if(this.proceedToCMSExcel == false) {
              this.intervalSubscription = interval(10000).subscribe((x => {
                if(this.proceedToCMSExcel == true) {
                  this.intervalSubscription.unsubscribe();
                  setTimeout(() => {
                    this.loaderService.isLoading.next(false);
                    if( (this.exportCmsPayment && this.exportCmsPayment.length > 0) || (this.exportCmsLitigationEnhanced && this.exportCmsLitigationEnhanced.length > 0)) {
                      let element = document.getElementById('excel-table-cms-enhanced');
                      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      ws['!cols'] = wscols;
                      const wb: XLSX.WorkBook = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
                      let element1 = document.getElementById('excel-table-cms-payment');
                      const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols1 = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}				
                      ];	
                      ws1['!cols'] = wscols1;
                      XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
                      let elements3 = document.getElementById('summary-excel-table');
                      const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(elements3, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscolsExport = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                      ];	
                      wsSummary['!cols'] = wscolsExport;
                      XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
                      XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
                    } else {
                      this.noAlerts = true;
                    }
                  }, 5000);
                }
              }));
            } else {
              setTimeout(() => {
                this.loaderService.isLoading.next(false);
                if( (this.exportCmsPayment && this.exportCmsPayment.length > 0) || (this.exportCmsLitigationEnhanced && this.exportCmsLitigationEnhanced.length > 0)) {
                  let element = document.getElementById('excel-table-cms-enhanced');
                  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols = [
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30}
                  ];	
                  ws['!cols'] = wscols;
                  const wb: XLSX.WorkBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
                  let element1 = document.getElementById('excel-table-cms-payment');
                  const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols1 = [
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30}				
                  ];	
                  ws1['!cols'] = wscols1;
                  XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
                  if(this.summary) {
                    let element3 = document.getElementById('summary-excel-table');
                    const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element3, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                    var wscolsExport = [
                      {wch:30},
                      {wch:30},
                      {wch:30},
                      {wch:30},
                      {wch:30}
                    ];	
                    wsSummary['!cols'] = wscolsExport;
                    XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
                  }
                  XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
                } else {
                  this.noAlerts = true;
                }
              }, 5000);
            }
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        },
        (err: any) => {
          this.loaderService.isLoading.next(false);
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              disableClose: true,
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.exportCMS(startDate, endDate);
              }
            });
          })
        });
		} else {
			this.loaderService.isLoading.next(true);
      this.proceedToCMSExcel = false;
        this.monitoringService.exportToExcel("CMS", endDate, startDate, "PAYMENT").subscribe((data: any) => {
          this.proceedToCMSExcel = true;
          this.loaderService.isLoading.next(false);
          if (data?.success) {
            this.exportCmsPayment = data?.data?.CMS_PAYMENT;
          }
        },
        (err: any) => {
          this.proceedToCMSExcel = true;
        });
        this.monitoringService.exportToExcel("CMS", endDate, startDate, "LITIGATION").subscribe((data: any) => {
          if (data?.success) {
            this.noAlerts = false;
            this.exportCmsLitigationBasic = data?.data?.CMS_LIT_BASIC;
            if(this.proceedToCMSExcel == false) {
              this.intervalSubscription = interval(10000).subscribe((x => {
                if(this.proceedToCMSExcel == true) {
                  this.intervalSubscription.unsubscribe();
                  setTimeout(() => {
                    this.loaderService.isLoading.next(false);
                    if( (this.exportCmsPayment && this.exportCmsPayment.length > 0) || (this.exportCmsLitigationBasic && this.exportCmsLitigationBasic.length > 0)) {
                      let element = document.getElementById('excel-table-cms-basic');
                      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      ws['!cols'] = wscols;
                      const wb: XLSX.WorkBook = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
                      let element1 = document.getElementById('excel-table-cms-payment');
                      const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscols1 = [
                        {wch:30},
                        {wch:30},
                        {wch:30}				
                      ];	
                      ws1['!cols'] = wscols1;
                      XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
                      let elements3 = document.getElementById('summary-excel-table');
                      const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(elements3, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscolsExport = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      wsSummary['!cols'] = wscolsExport;
                      XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
                      XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
                    } else {
                      this.noAlerts = true;
                    }
                  }, 5000);
                }
              }));
            } else {
              setTimeout(() => {
                this.loaderService.isLoading.next(false);
                if( (this.exportCmsPayment && this.exportCmsPayment.length > 0) || (this.exportCmsLitigationBasic && this.exportCmsLitigationBasic.length > 0)) {
                  let element = document.getElementById('excel-table-cms-basic');
                  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols = [
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30}
                  ];	
                  ws['!cols'] = wscols;
                  const wb: XLSX.WorkBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
                  let element1 = document.getElementById('excel-table-cms-payment');
                  const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                  var wscols1 = [
                    {wch:30},
                    {wch:30},
                    {wch:30}				
                  ];	
                  ws1['!cols'] = wscols1;
                  XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
                  let elements3 = document.getElementById('summary-excel-table');
                  const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(elements3, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
                      var wscolsExport = [
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30},
                        {wch:30}
                      ];	
                      wsSummary['!cols'] = wscolsExport;
                      XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
                  XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
                } else {
                  this.noAlerts = true;
                }
              }, 5000);
            }
          } else {
            this.loaderService.isLoading.next(false);
            this.noAlerts = true;
          }
        },
        (err: any) => {
          this.loaderService.isLoading.next(false);
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              disableClose: true,
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.exportCMS(startDate, endDate);
              }
            });
          })
        });  
		}	
  }

  hideTip() {
    this.showTip = false;
  }
}
