import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { MonitoringService } from '../../monitoring.service';

export interface ICustomHeaderParams {
  menuIcon: string;
}

@Component({
  selector: 'app-custom-header',
  template: `
  <ng-template #approvePopup>
	<ul>
		<li class="update-delete-popover" (click)="selectAll($event)">
			<a class="alignment">Select All Results</a>
		</li>
		<li class="update-delete-popover" (click)="onThisPage($event)">
			<a class="alignment">Select All From This Page</a>
		</li>
	</ul>
</ng-template>  
  <div class = "header float-left icon">
    <mat-icon container="body" [ngbPopover]="approvePopup" placement="bottom-left" >keyboard_arrow_down</mat-icon>
  </div>
  <div class="text"> Select </div>
  `,
  styles: [
    `
                     .customHeaderMenuButton,
                     .customHeaderLabel,
                     .customSortDownLabel,
                     .customSortUpLabel,
                     .customSortRemoveLabel {
                       float: left;
                       margin: 0 0 0 3px;
                     }
                   .header {
                       overflow: hidden;
                       text-overflow: ellipsis;
                       white-space: nowrap;
                   }
                   .update-delete-popover:hover{
                       background-color: #E6EFFB !important;
                   }
                   
                   .alignment {
                       vertical-align: super;
                       margin-left: 5px;
                   }
               
                   .float-left {
                       float: left;
                   }
               
                   .update-delete-popover {
                       padding: 10px 61px 10px 10px !important;
                       cursor: pointer;
                       color: #426DA9;
                       font-weight: 500;
                   }
               
                   .icon {
                       margin-right: 5px;
                       margin-top: 2px;
                   }
               
                   .text {
                       margin-top: 5px;
                   }
               
                   .customSortUpLabel {
                       margin: 0;
                   }
               
                     .customSortRemoveLabel {
                       font-size: 11px;
                     }
               
                     .active {
                       color: cornflowerblue;
                     }
                   `,
  ],
})
export class SelectAllHeader implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;
  
  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
  }
  
  constructor( public monitoringService: MonitoringService) {

  }

  onThisPage(event: any) {
    this.monitoringService.selection.next("select-current-page");
  }

  selectAll(event: any) {
    this.monitoringService.selection.next("select-all");
  }

  refresh(params: IHeaderParams) {
    return false;
  }
}