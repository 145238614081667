import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-cart-action-bottom-sheet',
  templateUrl: './cart-action-bottom-sheet.component.html',
  styleUrls: ['./cart-action-bottom-sheet.component.scss']
})
export class CartActionBottomSheet implements OnInit {
  params: any;
  showTranslation: boolean = false;
  constructor(private _bottomSheetRef: MatBottomSheetRef<CartActionBottomSheet>, @Inject(MAT_BOTTOM_SHEET_DATA) public sheetData: any) { }

  ngOnInit(): void {
    this.params = this.sheetData;
    console.log(this.params.template)
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  agInit(params: any): void {
    this.params = params;
    if (!this.params.data.parentCartSeqNo) {
      this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
    }
  }

  onDelete(ev: any) {
    this._bottomSheetRef.dismiss("deleted");
  }

  checkValid(data: any) {
    return data.translation;
  }

  validJSONCopy(data: any) {
    var text = (data.productDescription).split(" ").splice(-1);
    if (text == 'Translation') {
      return false;
    } else {
      return true;
    }
  }

  checkForGUBO(data: any): boolean {
    const validIds = ['GUBO25', 'GUBO10'];
    return validIds.includes(data.productOfferingId);
  }

  requestToJp() {
    this._bottomSheetRef.dismiss("jp_translation");
  }

  downloadReport(event: any) {
    this._bottomSheetRef.dismiss(event);
  }

  downloadReportForMobile(event: any) {
    this._bottomSheetRef.dismiss(event);
  }

  onUpdate(ev: any) {
    this._bottomSheetRef.dismiss("updated");
  }

  downloadExcelForMobile(event: any) {
    this._bottomSheetRef.dismiss(event);
  }
}