
<span id="print-section" >
<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                    {{ 'monitor.lms.title' | translate }}
                    <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true" printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">printer</mat-icon>
                        &nbsp;{{ 'monitor.cms.print_page' | translate }}
                    </button>
                    <button type="button" class="button button__primary" [ngClass] = "count == 0 ? 'disabled' : 'userManual-btn'"  (click)="exportAsExcelFile()" *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">get_app</mat-icon>
                        &nbsp;{{ 'monitor.bws.export' | translate }}
                    </button>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.cms.subtitle' | translate }} {{ count }} {{ 'monitor.cms.subtitle1' | translate }} {{ currentDate }}
                </p>
                <p class="error">
                    {{ 'monitor.cms.export_note' | translate }}
                </p> 
            </div> 
            
            <div class="expand-btn-area"  *ngIf="isMobile && (newDefendantList?.length > 1 || newPlantiffList.length > 1)">
                <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(allExpand)" [disabled]="btnDisabled">
                    <mat-icon *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                    {{expandCollapseButtonValue}}
                </button>
            </div> 
            <div class="zanna_div row" *ngIf="newDefendantList?.length > 0 || newPlantiffList?.length > 0 || count == 0">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{ 'monitor.lms.lms_alerts' | translate }}
                    </span>        
                </div>
                <mat-card appearance="outlined" *ngIf="count == 0">
                    {{ 'monitor.defendant_section' | translate }}
                </mat-card>
                <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newDefendantList" [columnDefs]="columnDefs" [animateRows]="true"
                    [masterDetail]="true" [detailRowHeight]="LMSNewDefendantHeight" [detailCellRenderer]="LMSNewDefendant" (firstDataRendered)="onFirstDataRendered1($event)"
                    (gridReady)="onNewDefendantGridReady($event)" [gridOptions]="newDefendantGridOptions" 
                    [domLayout]="domLayout" *ngIf=" count == 0">
                </ag-grid-angular>
                <mat-card appearance="outlined" *ngIf="newDefendantList?.length > 0" class="fs-2">
                    {{ 'monitor.defendant_section' | translate }}
                </mat-card>
                <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newDefendantList" [columnDefs]="columnDefs" [animateRows]="true"
                    [masterDetail]="true" [detailRowHeight]="LMSNewDefendantHeight" [detailCellRenderer]="LMSNewDefendant" (firstDataRendered)="onFirstDataRendered1($event)"
                    (gridReady)="onNewDefendantGridReady($event)" [gridOptions]="newDefendantGridOptions" 
                    [domLayout]="domLayout" *ngIf="newDefendantList?.length > 0">
                </ag-grid-angular>
                <mat-card appearance="outlined" *ngIf="newPlantiffList?.length > 0">
                    {{ 'monitor.plaintiff_section' | translate }}
                </mat-card>  
                <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newPlantiffList" [columnDefs]="columnDefs" [animateRows]="true"
                [masterDetail]="true" [detailRowHeight]="LMSNewPlandiffHeight" [detailCellRenderer]="LMSNewPlandiff" (firstDataRendered)="onFirstDataRendered2($event)"
                    (gridReady)="onNewPlantiffGridReady($event)" [gridOptions]="newPlantiffGridOptions" 
                    [domLayout]="domLayout" *ngIf="newPlantiffList?.length > 0">
                </ag-grid-angular>
            </div>
        </article>
        <article>
            <!-- <div class="zanna_div row" *ngIf="!entityId && summary">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{'cart.summary' | translate}}
                    </span>        
                </div>
                <mat-card style="padding: 20px;background: white;">
                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.total_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.totalNamesSubscribed}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.number_of_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.monitoredNames}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.remaining_Balance' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.remainingBalance}} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;">{{ 'monitor.bws.revision_name' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.sub_info' | translate }}  </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> </div>
                        </div>
                    </div>
                </mat-card>
            </div> -->
        </article>
    </section>
</main>
</span>
<div style="display: none;" >   
    <table id="excel-table-cms-enhanced">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Client File Ref. </th>
            <th> Entity Type </th>
            <th> Alert Trigger</th>
            <th> Court Type </th>
            <th> Year </th>
            <th> Case No </th>
            <th> Transfer </th>
            <th> Cause </th>
            <th> Party Nature </th>
            <th> Amount </th>
            <th> Defendant/Plaintiff/Claimant Name </th>
            <th> Date of Alert </th>
        </tr>
        <tr *ngFor="let item of exportCmsLitigationEnhanced">
            <td> {{ item.EntityId }} </td>
            <td> {{ item.EntityName }} </td>
            <td> {{ item.FileReference }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.ChangeType }} </td>
            <td> {{ item.CourtType }} </td>
            <td> {{ item.CaseYear }} </td>
            <td> {{ item.CaseNo }} </td>
            <td> {{ item.Transfer }} </td>
            <td> {{ item.Cause }} </td>
            <td> {{ item.PartyNature }} </td>
            <td> {{ item.Amount }} </td>
            <td> {{ item.PartyName }} </td>
            <td> {{ item.ChangeDate }} </td>
        </tr>
    </table>
  </div>

<app-footer></app-footer>