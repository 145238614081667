import { Component, Inject, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { SearchResultDataCellRenderer } from './search-result-grid-data/search-result-grid-data.component';
declare const $: any;

@Component({
	selector: 'app-search-results',
	templateUrl: './search-results.html',
	styleUrls: ['./search-results.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SearchResultsComponent implements OnInit, AfterViewInit, OnDestroy {
	isMobile: boolean = false;
	rowHeight: number;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColumnDefs();
		} else {
			this.isMobile = true;
			this.getColumnDefs();
		}
	}
	isExpand: boolean = false;
	searchColumnDefs: any = [];
	gridOptions: any;
	disabledFlag: boolean = false;
	errorMsgFlag: boolean = false;
	isRowSelected: boolean = false;
	offineReport: boolean = false;
	max120Characters: boolean = false;
	max200Characters: boolean = false;
	max50Characters: boolean = false;
	allowOnlyAlphabets: boolean = false;
	fileRefAllowOnlyAlphabets: boolean = false;
	max20Characters: boolean = false;
	max20Numbers: boolean = false;
	entityNameRequired: boolean = false;
	cityRequired: boolean = false;
	addressRequired: boolean = false;
	isValidNumber: boolean = false;
	manualSearch: boolean = false;
	entityEnglishValidation: boolean = false;
	addressEnglishValidation: boolean = false;
	selectedResult: any = {};
	searchResultList: any = [];
	icons: any;
	domLayout: any = "autoHeight";
	searchForm!: UntypedFormGroup;
	formData: any;
	detailCellRenderer: any;
	detailRowHeight: number;
	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	constructor(public matDialogRef: MatDialogRef<SearchResultsComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: UntypedFormBuilder,
		public translateService: TranslateService) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.formData = this.data.formData;

		this.getColumnDefs();

		// this.rowHeight = 50;
		if (this.data.results) {
			this.searchResultList = this.data.results;
		}

		if (this.data.offineReport) {
			this.offineReport = true;
		} else {
			this.offineReport = false;
		}

		this.manualSearch = this.formData.manualSearch;

		this.searchResultList.forEach((element: any) => {
			element['address2'] = this.getParsedAddress(element.address);
			element['expanded'] = false;
		});

		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				this.gridOptions.api?.sizeColumnsToFit();
				if ((this.data.results).length > 3) {
					this.gridAPI.setDomLayout('normal');
					let element: any = document.getElementById('searchResultGrid');
					if (element) {
						element.style.height = '51vh';
					}
				}
			},
			overlayNoRowsTemplate: '<h4> No Search results found. </h4>',
			rowSelection: 'single',
			animateRows: true,
			suppressCellSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true
			}
		};

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		if (this.formData != undefined && this.formData.categoryId == 4) {
			this.detailRowHeight = 130;
		} else {
			this.detailRowHeight = 100;
		}
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"myDetailCellRenderer": SearchResultDataCellRenderer,
			"customFloatingFilter": CustomFilterComponent
		};
	}
	expand() {
		this.isExpand = !this.isExpand;
	}
	ngOnInit() {
		this.offlineSearchForm();
	}

	ngAfterViewInit(): void {
		let self = this;
		if (!this.isMobile) {
			this.gridAPI.resetRowHeights();
			setTimeout(() => {
				self.gridAPI?.resetRowHeights();
			}, 150)
		};
	}

	offlineSearchForm() {
		this.searchForm = this.formBuilder.group({
			entityName: [this.formData.name, [Validators.required, this.noWhitespaceValidator]], // Validators.pattern('[\x00-\xFF]*')]
			city: [this.formData.city, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]], // Validators.pattern('[^{}<>&]*'),  Validators.pattern('[\x00-\xFF]*'), 
			entityAddressLine: ['', [Validators.required, this.noWhitespaceValidator]], // Validators.pattern('[^{}<>&]*'), Validators.pattern('[\x00-\xFF]*'),
			entityPhoneNumber: ['', [Validators.pattern(/^-?(0|[0-9]\d*)?$/), Validators.maxLength(20)]],
			fileRef: [this.formData.fileReference, [Validators.maxLength(20)]] // Validators.pattern('[^{}<>&]*'), Validators.pattern('[\x00-\xFF]*'), 
		});

		setTimeout(() => {
			let element: any = document.getElementById("myInput");
			if (element) {
				element.blur();
			}
		}, 500);

	}

	getColumnDefs() {
		let displayStatusandRegistrationNo: boolean = false;
		this.translateService.get(['searchResults.identification', 'selectionTable.address', 'selectionTable.status', 'selectionTable.regnr']).subscribe(value => {
			if ([4, 5, 6, 7].includes(this.formData.categoryId)) {
				displayStatusandRegistrationNo = true
			} else {
				displayStatusandRegistrationNo = false
			}
			if (this.formData != undefined && displayStatusandRegistrationNo) {
				this.searchColumnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						suppressSorting: true,
						sortable: false,
						width: this.isMobile ? 35 : 45,
						minWidth: this.isMobile ? 35 : 45,
					},
					{
						field: 'name',
						headerName: value['searchResults.identification'],
						width: this.isMobile ? 220 : 250,
						// minWidth: this.isMobile? 220 : 300,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						wrapText: this.isMobile ? true : false,
						autoHeight: this.isMobile ? true : false,
						cellStyle: { 'font-size': '14px', 'padding': '10px' }
					},
					{
						field: 'address2',
						headerName: value['selectionTable.address'],
						// minWidth: 300,
						width: 250,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						cellStyle: { 'font-size': '14px' }
					},
					{
						field: 'status',
						headerName: value['selectionTable.status'],
						minWidth: 100,
						width: 100,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						cellStyle: { 'padding-top': '10px' }
					},
					{
						field: 'regnr',
						headerName: value['selectionTable.regnr'],
						minWidth: 150,
						width: 150,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
					},
					{
						headerName: '',
						width: 40,
						maxWidth: 40,
						sortable: false,
						hide: this.isMobile ? false : true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.searchResultAction.bind(this),
							label: 'search-result'
						},
						cellStyle: { 'font-size': '14px' }
					}
				];
			} else {
				this.searchColumnDefs = [
					{
						field: 'RowSelect',
						headerName: '',
						checkboxSelection: true,
						suppressSorting: true,
						sortable: false,
						minWidth: this.isMobile ? 35 : 45,
						width: this.isMobile ? 35 : 45
					},
					{
						field: 'name',
						headerName: value['searchResults.identification'],
						// minWidth: this.isMobile? 220 : 300,
						width: this.isMobile ? 220 : 250,
						floatingFilter: this.isMobile ? false : true,
						wrapText: this.isMobile ? true : false,
						autoHeight: this.isMobile ? true : false,
						filter: 'agTextColumnFilter',
						cellStyle: { 'font-size': '14px', 'padding': '10px' }
					},
					{
						field: 'address2',
						headerName: value['selectionTable.address'],
						// minWidth: 300,
						width: 250,
						hide: this.isMobile,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						cellStyle: { 'font-size': '14px' }
					},
					{
						headerName: '',
						width: 40,
						maxWidth: 40,
						sortable: false,
						hide: this.isMobile ? false : true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.searchResultAction.bind(this),
							label: 'search-result'
						},
						cellStyle: { 'font-size': '14px' }
					}
				];
			}

		})
	}

	searchResultAction(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
	}

	onSelectionChanged(event: any) {
		let rows = this.gridOptions.api.getSelectedNodes();
		if (rows.length == 0) {
			this.isRowSelected = false;
			this.disabledFlag = true;
		}
	}

	onRowSearchSelected(params: any) {
		if (params.node.selected) {
			this.disabledFlag = false;
			this.isRowSelected = true;
			this.errorMsgFlag = false;
			$('.ag-theme-alpine').removeClass('chkbox');
			$('.ag-checkbox-input-wrapper').removeClass('chkbox');
			this.selectedResult = params.data;
		}
		if (!params.node.selected) {
			if (this.selectedResult) {
				this.selectedResult = {};
			}
		}
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
	}

	getParsedAddress(address: any) {
		if (address) {
			return Object.keys(address).map(function (k) { return address[k] }).join(", ");
		} else {
			return '';
		}
	}

	resultSelect() {
		if (this.isRowSelected) {
			this.selectedResult['type'] = 'online';
			this.matDialogRef.close(this.selectedResult);
		} else {
			this.errorMsgFlag = true;
			$('.ag-theme-alpine').addClass('chkbox');
			$('.ag-checkbox-input-wrapper').addClass('chkbox');
			this.disabledFlag = true;
		}
	}

	checkValidation() {
		this.searchForm.controls['entityName'].setValue((this.searchForm?.value?.entityName).trim());
		this.searchForm.controls['city'].setValue((this.searchForm?.value?.city).trim());
		this.searchForm.controls['entityAddressLine'].setValue((this.searchForm?.value?.entityAddressLine).trim());
		this.searchForm.controls['entityPhoneNumber'].setValue((this.searchForm?.value?.entityPhoneNumber).trim());
		this.searchForm.controls['fileRef'].setValue((this.searchForm?.value?.fileRef).trim());

		if (this.searchForm.get('entityName')?.status === 'INVALID') {
			if ((this.searchForm.value.entityName).length == 0) {
				this.entityNameRequired = true;
				// this.entityEnglishValidation = false;
			} else {
				this.entityNameRequired = false;
				// this.entityEnglishValidation = true;
			}
		}
		if (this.searchForm.get('entityName')?.status === 'VALID') {
			this.entityNameRequired = false;
			// this.entityEnglishValidation = false;
		}
		if (this.searchForm.get('city')?.status === 'INVALID') {
			if ((this.searchForm.value.city).length == 0) {
				this.cityRequired = true;
			} else {
				//this.allowOnlyAlphabets = true;
			}
		}
		if (this.searchForm.get('city')?.status === 'VALID') {
			this.cityRequired = false;
			// this.allowOnlyAlphabets = false;
		}
		if (this.searchForm.get('entityAddressLine')?.status === 'VALID') {
			this.addressRequired = false;
			// this.addressEnglishValidation = false;
		}
		if (this.searchForm.get('entityAddressLine')?.status === 'INVALID') {
			if ((this.searchForm.value.entityAddressLine).length == 0) {
				this.addressRequired = true;
				// this.addressEnglishValidation = false;
			} else {
				this.addressRequired = false;
				// this.addressEnglishValidation = true;
			}
		}
		if (this.searchForm.get('fileRef')?.status === 'VALID') {
			this.max20Characters = false;
			// this.fileRefAllowOnlyAlphabets = false;
		}
		if (this.searchForm.get('fileRef')?.status === 'INVALID') {
			if ((this.searchForm.value.fileRef).length > 20) {
				this.max20Characters = true;
				// this.fileRefAllowOnlyAlphabets = false;
			} else {
				this.max20Characters = false;
				// this.fileRefAllowOnlyAlphabets = true;
			}
		}
		if (this.searchForm.get('entityPhoneNumber')?.status === 'VALID') {
			this.isValidNumber = false;
		}
		if (this.searchForm.get('entityPhoneNumber')?.status === 'INVALID') {
			this.isValidNumber = true;
		}
		if ((this.searchForm.value.entityName).length <= 200) {
			this.max120Characters = false;
		}
		if ((this.searchForm.value.entityName).length > 200) {
			this.max120Characters = true;
		}
		if ((this.searchForm.value.entityAddressLine).length <= 200) {
			this.max200Characters = false;
		}
		if ((this.searchForm.value.entityAddressLine).length > 200) {
			this.max200Characters = true;
		}
		if ((this.searchForm.value.city).length <= 50) {
			this.max50Characters = false;
		}
		if ((this.searchForm.value.city).length > 50) {
			this.max50Characters = true;
		}
		if ((this.searchForm.value.entityPhoneNumber)?.length <= 20) {
			this.max20Numbers = false;
		}
		if ((this.searchForm.value.entityPhoneNumber)?.length > 20) {
			this.max20Numbers = true;
		}
		if ((this.searchForm.value.fileRef)?.length <= 20) {
			this.max20Characters = false;
		}
		if ((this.searchForm.value.fileRef)?.length > 20) {
			this.max20Characters = true;
		}
	}

	public noWhitespaceValidator(control: UntypedFormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}

	searchResults(value: any) {
		if (this.searchForm.invalid) {
			if (this.searchForm.get('entityName')?.status === 'INVALID') {
				this.entityNameRequired = true;
			}
			if (this.searchForm.get('city')?.status === 'INVALID') {
				this.cityRequired = true;
			}
			if (this.searchForm.get('entityAddressLine')?.status === 'INVALID') {
				this.addressRequired = true;
			}
			return;
		} else {
			if (this.max120Characters || this.max200Characters || this.max20Characters || this.max50Characters || this.allowOnlyAlphabets || this.max20Numbers || this.addressEnglishValidation || this.fileRefAllowOnlyAlphabets || this.entityEnglishValidation) {
				return false;
			} else {
				value['type'] = 'offline'
				this.selectedResult = value;
				this.matDialogRef.close(value);
			}
		}
	}

	goToOffline() {
		this.manualSearch = true;
		this.offineReport = true;
	}

	getIsROCCondition() {
		if (this.formData != undefined && this.formData.categoryId == 4) {
			return true;
		} else {
			return false;
		}
	}

	ngOnDestroy() {
		$('.ag-theme-alpine').removeClass('chkbox');
		$('.ag-checkbox-input-wrapper').removeClass('chkbox');
	}
}