import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HomeService } from './home/home.service';
import { LoaderService } from './services/loader.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public iIRROCReportAccess = new BehaviorSubject<boolean>(false);
    public iIRMonitoringAccess = new BehaviorSubject<boolean>(false);
    public iirGlobalMonitoringAccess = new BehaviorSubject<boolean>(false);
    public monitoringServiceAccess: boolean;
    public globalMonitoringServiceAccess: boolean;
    public homeServiceAccess: boolean;
    public validated: boolean = false;

    redirectTo: any;
    constructor(private _router: Router, public homeService: HomeService, private loaderService: LoaderService,) {
        this.loaderService.isLoading.next(true);
        this.globalMonitoringServiceAccess = false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.getData();
        this.redirectTo = state.url;
        return true;
    }


    async getData() {
        if (!this.validated) {
            this.loaderService.isLoading.next(true);
             this.homeService.validateSession(localStorage.getItem('sessionID')).subscribe((res: any) => {
                if (res.success) {
                    this.validated = true;
                    localStorage.setItem("validation", JSON.stringify(res.data));
                    if (res.data.iIRROCReportAccess == 'Y' || res.data.iIRGDNReportAccess == 'Y' || res.data.iirGlobalCreditReportAccess == 'Y' || res.data.iirGlobalUBOAccess == 'Y' || res.data.iirGlobalMonitoringAccess == 'Y') {
                        this.iIRROCReportAccess.next(true);
                        this.homeServiceAccess = true;
                        if (res.data.iIRMonitoringAccess == 'Y') {
                            this.iIRMonitoringAccess.next(true);
                            this.monitoringServiceAccess = true;
                        } else {
                            this.iIRMonitoringAccess.next(false);
                            this.monitoringServiceAccess = false;
                        }
                        if (res.data.iirGlobalMonitoringAccess == 'Y') {
                            this.iirGlobalMonitoringAccess.next(true);
                            this.globalMonitoringServiceAccess = true;
                        } else {
                            this.iirGlobalMonitoringAccess.next(false);
                            this.globalMonitoringServiceAccess = false;
                        }
                        if (this.redirectTo != '/') {
                            this._router.navigate([this.redirectTo]);
                        } else {
                            this._router.navigate(['/home']);
                        }
                    } else if (res.data.iIRMonitoringAccess == 'Y') {
                        this.iIRMonitoringAccess.next(true);
                        this.iirGlobalMonitoringAccess.next(false);
                        this.iIRROCReportAccess.next(false);
                        this.monitoringServiceAccess = true;
                        this.homeServiceAccess = false;
                        this.globalMonitoringServiceAccess = false;
                        if (this.redirectTo != '/') {
                            this._router.navigate([this.redirectTo]);
                        } else {
                            this._router.navigate(['/monitor']);
                        }
                    } else {
                        this._router.navigate(['/session-expired']);
                    }
                } else {
                    this._router.navigate(['/session-expired']);
                }

                return of(true);
            }, (err: any) => {
                this.loaderService.isLoading.next(false);
                this._router.navigate(['/session-expired']);
                return of(false);
            })
        }
    }

    isHome(): boolean {
        return this.homeServiceAccess;
    }

    isMonitor(): boolean {
        return this.monitoringServiceAccess;
    }

    isGlobalMonitor(): boolean {
        return this.globalMonitoringServiceAccess;
    }


}


