import { Component, HostListener } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CartActionBottomSheet } from '../action-bottom-sheet/cart-action-bottom-sheet.component';

@Component({
	selector: 'app-grid-cell-renderer',
	templateUrl: './grid-cell-renderer.component.html',
	styleUrls: ['./grid-cell-renderer.component.scss']
})

export class GridCellRendererComponent implements ICellRendererAngularComp {
	isMobile: boolean = false;
	GUBO: boolean;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;
	template: String
	constructor(
		private _bottomSheet: MatBottomSheet,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer
	) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		iconRegistry
			.addSvgIcon('payment', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payment.svg'))
			.addSvgIcon('shopping', sanitizer.bypassSecurityTrustResourceUrl('assets/img/shopping.svg'))
			.addSvgIcon('payment-green', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-green.svg'))
			.addSvgIcon('payment-grey', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-grey.svg'))
			.addSvgIcon('icon-excel', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons8-excel.svg'));

	}

	openBottomSheet(data: any): void {
		this.params.template = data;
		const bottomSheetRef = this._bottomSheet.open(CartActionBottomSheet, {
			panelClass: '100%',
			data: this.params,
		});
		bottomSheetRef.afterDismissed().subscribe((result: any) => {
			if (result == "deleted") {
				this.onDelete("event");
			} else if (result == "jp_translation") {
				this.requestToJp()
			} else if (result == "downloadReportForMobile") {
				this.downloadReportForMobile(result)
			} else if (result == "billing" || result == "downlaod" || result == "json") {
				this.downloadReport(result)
			}
			else if (result == "xlsx") {
				this.downloadExcel(result)
			}
			console.log("message", result)
			// Restore focus to an appropriate element for the user's workflow here.
		});
	}

	agInit(params: any): void {
		this.params = params;
		if (!this.params.data.parentCartSeqNo) {
			this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
		}
		this.GUBO = (this.params.data.productOfferingId === 'GUBO25' || this.params.data.productOfferingId === 'GUBO10');
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	getClassForListItem(): string {
		const { itemStatus, archived, productOfferingId } = this.params.data;
		if (itemStatus === 'Processing' || itemStatus === 'Cancelled' || archived ||
			productOfferingId === 'GUBO10' || productOfferingId === 'GUBO25') {
			return 'popover-list disable-option';
		}
		return 'popover-list';
	}

	validJSONCopy(data: any) {
		var text = (data.productDescription).split(" ").splice(-1);
		if (text == 'Translation') {
			return false;
		} else {
			return true;
		}
	}

	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	onRemove(ev: any) {
		this.params['label'] = 'remove';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}

	viewMatches(ev: any) {
		this.params['label'] = 'viewMatches';
		this.params.onClick(this.params);
	}

	expand(ev: any) {
		this.params['label'] = 'expand';
		this.params.onClick(this.params);
	}

	onUpdate(ev: any) {
		this.params['label'] = 'update';
		this.params.onClick(this.params);
	}

	onRestore(ev: any) {
		this.params['label'] = 'restore';
		this.params.onClick(this.params);
	}

	requestToJp() {
		this.params['label'] = 'jp_translation';
		this.params.onClick(this.params);
	}

	downloadReport(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

	downloadReportForMobile(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}
	downloadExcel(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

}