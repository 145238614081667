import { E } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { $ } from 'protractor';
import { Observable, Subscription } from 'rxjs';
import { ConfimDialogComponent } from '../../confirm-dialog-box/confirm-dialog.component';
import { CustomToolTipComponent } from '../../custom-tooltip/custom-tooltip.component';
import { ErrorDialogComponent } from '../../error-dialog-box/error-dialog.component';
import { GridCellRendererComponent } from '../../grid-cell-renderer/grid-cell-renderer.component';
import { HomeService } from '../../home/home.service';
import { LoaderService } from '../../services/loader.service';
import { FilterPopUpComponent } from '../monitor/filter-popup/filter-popup.component';
import { MonitoringService } from '../monitoring.service';
import { ExpandGridCellRendererComponent } from '../shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
import { ExportAllAlertComponent } from '../shared-module/export-all-alert/export-all-alert.component';
import { GridLoaderComponent } from '../shared-module/loader/loader.component';
import { DetailCellRenderer } from '../shared-module/master-data-grids/cell-renderer.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';
import { AlertTriggerCellRendererComponent } from './grid-cell-renderer.component';
import { ReportsDetailCellRenderer } from './master-data-grids/cell-renderer.component';
import dayjs from 'dayjs';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	$langEventSubscription: Subscription;
	$getDataSubscription: Subscription;
	@Input() changeLangEvent: Observable<void>;
	@Input() alertType: any;
	@Input() buttonValue: any;
	@Input() buttonExpand: any;
	@Input() availableAlerts: any;
	@Input() monitoringTypeClassification: any;
	@Output() filterEvent = new EventEmitter();
	@ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;
	@ViewChild(DaterangepickerDirective) picker1: DaterangepickerDirective;
	confirmDialogRef: MatDialogRef<ConfimDialogComponent>;
	columnDefs: any = [];
	selected = [];
	gridOptions: any;
	gridColumnApi: any;
	startDate: any = undefined;
	endDate: any = undefined;
	format: any = "DD MMM YYYY";
	alwaysShowCalendars: boolean;
	loadingOverlayComponent: any;
	currentDate: any = moment().format('DD/MM/YYYY');
	loadingOverlayComponentParams: any;
	loadMessage: string = "Loading 1000 Records..."
	locale: any = {
		format: "DD MMM YYYY"
	};
	startMinDate: any = moment(new Date()).subtract(89, 'days');
	minDate: any;
	maxDate: any = moment(new Date().setDate(new Date().getDate() - 1));
	ranges: any = {
		//'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(7, 'days')],
		'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(30, 'days')],
		'Last 90 Days': [moment().subtract(89, 'days'), moment().subtract(89, 'days')]
	}
	invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
	alerts: any = ['ALL'];
	alertTrigger: any;
	selectedAlert: any = "ALL";
	searchByEntityName: any;
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	mySubscription: Subscription
	private paginationPageSize = 5;
	private totalPages = 0;
	api: any;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	alertList: any = [];
	alertListCopy: any = [];
	detailCellRenderer: any;
	detailRowHeight: number;
	minimize: any = true;
	loadNew: boolean = false;
	pagesCount: number = 1;
	defaultRowsCount: number = 1000;
	pageNumber: number = 1;
	totalRecords: number;
	filterValue: any;
	avalilableResults: number = 0;
	showing: any;
	selectedAlertTrigger: any;
	from: boolean = false;
	to: boolean = false;
	filter: boolean = false;
	showTip: boolean = true;
	allExpand: boolean;
	gridId: String;
	expandCollapseButtonValue: String = "Expand all rows"

	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	// filterParams = {
	// 	comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
	// 	var date1: any = moment(filterLocalDateAtMidnight).format('DD/MM/YYYY');
	// 	var date2: any = moment(cellValue).format('DD/MM/YYYY');
	// 	var result1: any;
	// 	var result2: any;
	// 	if (date1 === undefined || date1 === null || date1.length !== 10) {
	// 		result1 = null;
	// 	} else{
	// 		var yearNumber1: any = date1.substring(6, 10);
	// 		var monthNumber1: any = date1.substring(3, 5);
	// 		var dayNumber1: any = date1.substring(0, 2);
	// 		result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
	// 	}

	// 	if (date2 === undefined || date2 === null || date2.length !== 10) {
	// 		result2 = null;
	// 	} else{
	// 		var yearNumber2: any = date2.substring(6, 10);
	// 		var monthNumber2: any = date2.substring(3, 5);
	// 		var dayNumber2: any = date2.substring(0, 2);
	// 		result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
	// 	}
	// 	if (result1 === null && result2 === null) {
	// 		  return 0;
	// 	}
	// 		if (result1 === null) {
	// 		  return -1;
	// 		}
	// 		if (result2 === null) {
	// 		  return 1;
	// 		}
	// 		return result2 - result1;
	// 	},
	// 	browserDatePicker: true,
	// };

	dateComparator(date11: any, date22: any) {
		var date1: any = moment(date11).format('DD/MM/YYYY');
		var date2: any = moment(date22).format('DD/MM/YYYY');
		var result1: any;
		var result2: any;
		if (date1 === undefined || date1 === null || date1.length !== 10) {
			result1 = null;
		} else {
			var yearNumber1: any = date1.substring(6, 10);
			var monthNumber1: any = date1.substring(3, 5);
			var dayNumber1: any = date1.substring(0, 2);
			result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
		}

		if (date2 === undefined || date2 === null || date2.length !== 10) {
			result2 = null;
		} else {
			var yearNumber2: any = date2.substring(6, 10);
			var monthNumber2: any = date2.substring(3, 5);
			var dayNumber2: any = date2.substring(0, 2);
			result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
		}
		if (result1 === null && result2 === null) {
			return 0;
		}
		if (result1 === null) {
			return -1;
		}
		if (result2 === null) {
			return 1;
		}
		return result1 - result2;
	}

	monthToComparableNumber(date: any) {
		if (date === undefined || date === null || date.length !== 10) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}

	constructor(public monitoringService: MonitoringService, public dialog: MatDialog,
		public loaderService: LoaderService, public translateService: TranslateService,
		private homeService: HomeService, public router: Router) {
		this.allExpand = false;
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.getColDefs();
		localStorage.removeItem("dateFrom");
		localStorage.removeItem("dateTo");
		localStorage.removeItem("entityId");
		// window.scroll(0, 0);
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "previously-deleted"
			},
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 200;
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"customTooltip": CustomToolTipComponent,
			"expandCellRenderer": ExpandGridCellRendererComponent,
			"AlertTriggerCellRendererComponent": AlertTriggerCellRendererComponent,
			myDetailCellRenderer: DetailCellRenderer,
			customLoadingOverlay: GridLoaderComponent,
			myNoRowsComponent: MyNoRowsComponent
		};

		this.loadingOverlayComponent = 'customLoadingOverlay';
		this.loadingOverlayComponentParams = {
			loadingMessage: this.loadMessage
		};
		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.monitoringService.setSid(localStorage.getItem('sessionID'))
		this.alwaysShowCalendars = true;
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.alertType?.currentValue) {
			this.checkFilter();
		}
		if (changes?.availableAlerts?.currentValue) {
			var obj: any = {
				"MonitoringType": [],
				"AlertClassification": []
			};
			this.availableAlerts.map((alert: any) => {
				this.alerts.push(alert.monitoringType);
				if (alert.monitoringType != 'ALL')
					obj.MonitoringType.push(alert.monitoringType);
			});
			if (!this.filter && this.availableAlerts.length > 0)
				this.findAlertTriggers(obj);
		}
	}

	ngOnInit(): void {
		this.$langEventSubscription = this.changeLangEvent.subscribe(() => this.changeLanguage());
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	alertTriggerSelect(event: any) {
		this.selectedAlertTrigger = event.alertTrigger;
	}



	checkFilter() {
		if (this.alertType == "ALL") {
			this.alertList = this.alertListCopy.map((record: any) => ({
				...record,
				expanded: false,
			}));
			if (this.alertList.length > 0) {
				this.gridId = "alartsGrid"
			} else {
				this.gridId = "alartsGridMobile"
			}
			this.avalilableResults = this.alertList.length;
			this.gridAPI?.refreshCells();
			this.allExpand = this.buttonExpand;
			this.expandCollapseButtonValue = this.buttonValue;
		} else {
			this.alertList = this.alertListCopy.map((record: any) => ({
				...record,
				expanded: false,
			}));
			this.alertList = this.alertList.filter((res: any) => res.AlertType == this.alertType);
			if (this.alertList.length > 0) {
				this.gridId = "alartsGrid"
			} else {
				this.gridId = "alartsGridMobile"
			}
			this.avalilableResults = this.alertList.length;
			this.gridAPI.refreshCells();
			this.allExpand = this.buttonExpand;
			this.expandCollapseButtonValue = this.buttonValue;
		}
	}

	loadNextRecords() {
		this.loadNew = true;
		this.loadRecords(this.defaultRowsCount, ++this.pageNumber);
	}

	exportAllAlerts() {
		let dialogRef = this.dialog.open(ExportAllAlertComponent, {
			disableClose: true,
			data: this.availableAlerts,
			width: this.isMobile ? "auto" : "60vw",
			panelClass: 'advanced-search-dialog'
		});
		dialogRef.afterClosed().subscribe(res => {
		});
	}

	open(event: any) {
		this.picker.open();
	}
	openE(event: any) {
		this.picker1.open();
	}

	loadRecords(defaultRowsCount: any, pageNumber: any) {
		this.api.showLoadingOverlay();
		this.$getDataSubscription = this.monitoringService.getAllAlarts(defaultRowsCount, pageNumber, this.filterValue).subscribe((data: any) => {
			if (data?.success) {
				var newData = data.data;
				if (newData.records.length > 0) {
					var oldPage = this.loadNew ? this.gridOptions.api?.paginationGetTotalPages() : null;
					var arr = this.alertListCopy;
					if (this.loadNew) {
						newData.records.map((row: any, index: number) => {
							arr.push(row);
							if (index == newData.records.length - 1) {
								this.alertListCopy = arr;
								this.totalRecords = newData.recordCount;
								this.checkFilter();
								// this.gridOptions.api.setRowData(this.records);
								this.loadNew = false;
							}
						});
					} else {
						this.alertListCopy = newData.records;
						this.totalRecords = newData.recordCount;
						this.checkFilter();
					}
					this.pagesCount = Math.floor(this.totalRecords / this.defaultRowsCount);
					if ((this.totalRecords / this.defaultRowsCount) % 1 > 0) {
						this.pagesCount = this.pagesCount + 1;
					}
					var from = (pageNumber - 1) * defaultRowsCount + 1;
					var to = pageNumber * defaultRowsCount;
					if (pageNumber == this.pagesCount) {
						to = this.totalRecords;
					}
					this.showing = {
						'from': from,
						'to': to,
						'loadPage': oldPage
					}
					this.api.hideOverlay();
				} else {
					this.alertListCopy = [];
					this.alertList = [];
					if (this.alertList.length > 0) {
						this.gridId = "alartsGrid"
					} else {
						this.gridId = "alartsGridMobile"
					}
					this.api.hideOverlay();
				}
			} else {
				this.alertListCopy = [];
				this.alertList = [];
				if (this.alertList.length > 0) {
					this.gridId = "alartsGrid"
				} else {
					this.gridId = "alartsGridMobile"
				}
				this.api.hideOverlay();
				this.openTryAgainPopup();
			}
		}, (err: any) => {
			this.alertListCopy = [];
			this.alertList = [];
			if (this.alertList.length > 0) {
				this.gridId = "alartsGrid"
			} else {
				this.gridId = "alartsGridMobile"
			}
			this.api.hideOverlay();
			this.openTryAgainPopup();
		});
	}

	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile ? '91vw' : '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords(this.defaultRowsCount, this.pageNumber);
				}
			});
		})
	}

	loadPreviousRecords() {
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.deselectAll();
	}

	getColDefs() {
		this.translateService.get(['monitor.select', 'monitor.entityIdentification', 'monitor.clientFileRef',
			'monitor.alertType', 'monitor.alertTriggers', 'monitor.dateOfAlert',
			'monitor.action']).subscribe(value => {
				this.columnDefs = [
					// {
					// 	field: 'select',
					// 	headerName: value['monitor.select'],
					// 	width: 60,
					// 	maxWidth: 110,
					// 	sortable: false,
					// 	checkboxSelection: true,
					// 	headerCheckboxSelection: true,
					// 	cellStyle: { 'overflow': 'visible', 'justify-content': 'center'}
					// },
					{
						field: 'EntityName',
						headerName: value['monitor.entityIdentification'],
						width: 220,
						minWidth: 220,
						cellRenderer: function (param: any) {

							if (param.data.EntityType == "I") {
								var row = "<p style='line-height:50px;'></p>" + "NRIC / Passport No. " + param.data.EntityId + '<br/>';
								if (param.data.EntityName)
									row = row + param.data.EntityName + "</br> <p style='line-height:50px;'></p>";
								return row;
							} else {
								var row = "<p style='line-height:50px;'></p>" + "Entity Number. " + param.data.EntityId + '<br/>';
								if (param.data.EntityName)
									row = row + param.data.EntityName + "</br> <p style='line-height:50px;'></p>";
								return row;
							}

						},
						cellStyle: { 'overflow': 'visible', 'justify-content': 'left', 'font-size': '14px' }
					},
					{
						field: 'FileReference',
						headerName: value['monitor.clientFileRef'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						width: 110,
						minWidth: 110,
						hide: this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'AlertFullName',
						headerName: value['monitor.alertType'],
						width: 150,
						minWidth: 150,
						hide: this.isMobile,
						cellRenderer: function (param: any) {
							if (param.data.AlertFullName && param.data.EntitlementDescription)
								return param.data.AlertFullName + " " + param.data.EntitlementDescription;
							else
								return param.data.AlertFullName;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' },
						suppressMenu: false,
					},
					{
						field: 'AlertList',
						headerName: value['monitor.alertTriggers'],
						width: 230,
						minWidth: 230,
						hide: this.isMobile,
						suppressMenu: false,
						cellRenderer: "AlertTriggerCellRendererComponent",
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'AlertDate',
						valueGetter: this.dateGetter,
						headerName: value['monitor.dateOfAlert'],
						width: 115,
						minWidth: 115,
						hide: this.isMobile,
						suppressMenu: false,
						comparator: this.dateComparator,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: this.isMobile ? 60 : 120,
						maxWidth: this.isMobile ? 60 : 130,
						sortable: false,
						headerName: this.isMobile ? " " : value['monitor.action'],
						cellRenderer: "expandCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'monitor-records'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px', 'justify-content': 'center', 'font-size': '14px' }
					},
				]
			});
	}


	ngOnDestroy(): void {
		if (this.mySubscription) {
			this.mySubscription.unsubscribe();
		}
		if (this.$getDataSubscription) {
			this.$getDataSubscription.unsubscribe();
		}
	}

	changeLanguage() {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}

	endDateUpdated() {
		if (this.endDate?.startDate && this.from) {
			this.startDate = {
				startDate: this.startDate.startDate,
				endDate: this.endDate?.startDate
			}
			if (this.from) {
				this.to = true;
			}
		} else {
			this.startDate = undefined;
			this.endDate = undefined;
		}
	}

	startDateUpdated() {
		if (this.startDate?.startDate && !this.from && !this.to) {
			this.minDate = moment(this.startDate.startDate);
			if (this.startDate?.endDate && moment(this.startDate.endDate).format('L') === moment().subtract(1, 'days').format('L')) {
				this.endDate = {
					startDate: this.startDate.endDate,
					endDate: this.startDate.endDate
				};
				this.startDate = {
					startDate: this.startDate.startDate,
					endDate: this.startDate.endDate
				};
			} else {
				this.endDate = {
					startDate: new Date(),
					endDate: new Date()
				};
				this.startDate = {
					startDate: this.startDate.startDate,
					endDate: new Date(new Date().setDate(new Date().getDate() - 1))
				};
			}
			this.from = true;
			this.to = false;
		} else if (!this.from && !this.to) {
			this.startDate = undefined;
			this.endDate = undefined;
		} else if (this.startDate?.startDate) {
			this.minDate = moment(this.startDate.startDate);
			if (this.startDate?.endDate && moment(this.startDate.endDate).format('L') === moment().subtract(1, 'days').format('L')) {
				this.endDate = {
					startDate: this.startDate.endDate,
					endDate: this.startDate.endDate
				};
				this.startDate = {
					startDate: this.startDate.startDate,
					endDate: this.startDate.endDate
				};
			} else {
				this.startDate = {
					startDate: this.startDate.startDate,
					endDate: new Date(new Date().setDate(new Date().getDate() - 1))
				};
				this.endDate = {
					startDate: new Date(),
					endDate: new Date(new Date().setDate(new Date().getDate() - 1))
				};
			}
		}
	}
	applyFilter() {
		var alertTriggers: any = "";
		this.selectedAlertTrigger?.map((res: any, index: number) => {
			if (index == 0) {
				alertTriggers = alertTriggers + res.alertTrigger;
			} else {
				alertTriggers = alertTriggers + "*" + res.alertTrigger
			}
		});
		this.pageNumber = 1;
		this.filterValue = {
			entityCommonFilter: this.searchByEntityName ? this.searchByEntityName : "",
			monitoringTypeFilter: null,
			alertTriggerFilter: alertTriggers ? alertTriggers : "",
			alertDateFromFilter: this.startDate?.startDate ? dayjs(this.startDate.startDate).format('YYYY-MM-DD') : "",
			alertDateToFilter: this.endDate?.startDate ? dayjs(this.endDate.startDate).format('YYYY-MM-DD') : "",
			entityAlertClassificationFilter: null,
		};
		this.loadNew = false;
		this.alertType = "ALL";
		this.filter = true;
		if (this.selected.length > 0) {
			this.selected?.map((rec: any, index: number) => {
				if (rec?.monitoringClassification) {
					if (!this.filterValue.entityAlertClassificationFilter) {
						this.filterValue.entityAlertClassificationFilter = rec.monitoringType + " - " + rec?.monitoringClassification;
					} else {
						this.filterValue.entityAlertClassificationFilter = this.filterValue.entityAlertClassificationFilter + "," + rec.monitoringType + " - " + rec?.monitoringClassification;
					}
				} else {
					if (!this.filterValue.monitoringTypeFilter) {
						this.filterValue.monitoringTypeFilter = rec.monitoringType;
					} else {
						this.filterValue.monitoringTypeFilter = this.filterValue.monitoringTypeFilter + "," + rec.monitoringType;
					}
				}
			})
		} else {
			this.filterValue.entityAlertClassificationFilter = null;
		}
		this.filterEvent.emit();
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	removeFilter() {
		this.pageNumber = 1;
		this.filterValue = null;
		this.loadNew = false;
		this.alertType = "ALL";
		this.filter = false;
		this.searchByEntityName = "";
		this.selectedAlert = "ALL";
		this.selected = [];
		this.selectedAlertTrigger = null;
		this.startDate = null;
		this.endDate = null;
		this.filterEvent.emit();
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
		var obj: any = {
			"MonitoringType": [],
			"AlertClassification": []
		};
		this.availableAlerts.map((alert: any) => {
			this.alerts.push(alert.monitoringType);
			if (alert.monitoringType != 'ALL')
				obj.MonitoringType.push(alert.monitoringType);
		});
		if (!this.filter && this.availableAlerts.length > 0)
			this.findAlertTriggers(obj);
	}

	ExpandAll(data: any) {
		if (!data) {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		} else {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}

	onActionClick(ev: any) {
		if (ev.label === 'expand-data') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}

		if (ev.label === 'delete') {
			this.gridAPI.redrawRows();
		}

		if (ev.label === 'expand') {
			localStorage.setItem('dateFrom', moment(ev.data?.AlertDate).format('YYYY-MM-DD'));
			localStorage.setItem('dateTo', moment(ev.data?.AlertDate).format('YYYY-MM-DD'));
			localStorage.setItem('entityId', ev.data.EntityId);
			window.open('#' + this.router.url + '/' + ev.data.AlertType, "_blank");
			this.gridAPI.redrawRows();
		}

		if (ev.label === 'update') {
			this.gridAPI.redrawRows();
		}
	}

	onAdd($event: any) {
		this.alterTypeFilter();
	}

	alterTypeFilter() {
		if (this.selected.length > 0) {
			var obj: any = {
				"MonitoringType": [],
				"AlertClassification": []
			};
			this.selected.map((rec: any) => {
				if (rec?.monitoringClassification) {
					obj.AlertClassification.push(rec.monitoringType + " - " + rec?.monitoringClassification);
				} else {
					obj.MonitoringType.push(rec.monitoringType);
				}
			});
			// this.filterEvent.emit();
			this.findAlertTriggers(obj);

		} else {
			var obj: any = {
				"MonitoringType": [],
				"AlertClassification": []
			};
			this.availableAlerts.map((alert: any) => {
				this.alerts.push(alert.monitoringType);
				if (alert.monitoringType != 'ALL')
					obj.MonitoringType.push(alert.monitoringType);
			});
			this.findAlertTriggers(obj);
		}
	}

	findAlertTriggers(obj: any) {
		this.selectedAlertTrigger = null;
		this.loaderService.isLoading.next(true);
		this.monitoringService.getAlertTypesAndAlertTriggers(obj).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			data = JSON.parse(data);
			if (data?.success) {
				this.alertTrigger = data?.data.alertTtriggers;
				let sortedArray = this.alertTrigger.sort(function (a: any, b: any) {
					return a.alertTrigger.localeCompare(b.alertTrigger);
				});
				this.alertTrigger = sortedArray;
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
		});
	}

	openFilterPopup() {
		let dialogRef = this.dialog.open(FilterPopUpComponent, {
			disableClose: true,
			width: this.isMobile ? '91vw' : '37vw',
			height: '80%',
			panelClass: 'search-result-pop-up',
			data: {
				monitoringTypeClassification: this.monitoringTypeClassification,
				alerts: this.alerts,
				alertTrigger: this.alertTrigger,
				label: 'monitor-alert-filter-popup'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res != undefined) {
				this.applyPopoupFilter(res.data);
			}
		})
	}

	hideTip() {
		this.showTip = false;
	}

	applyPopoupFilter(data: any) {
		this.pageNumber = 1;
		this.filterValue = data;
		this.loadNew = false;
		this.alertType = "ALL";
		this.filter = true;
		this.filterEvent.emit();
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}
}
