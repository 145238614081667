import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { MonitoringService } from '../../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { GridApi, GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/home/home.service';
import { Router } from '@angular/router';
import { A, C } from '@angular/cdk/keycodes';
import { ThemePalette } from '@angular/material/core';
import { element } from 'protractor';
import dayjs from 'dayjs';

@Component({
	selector: 'app-filter-popup',
	templateUrl: './filter-popup.component.html',
	styleUrls: ['./filter-popup.component.scss']
})

export class FilterPopUpComponent implements OnInit {
	isMobile: boolean = false;
	cellData: any;
	label: any;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	$langEventSubscription: Subscription;
	$getDataSubscription: Subscription;
	@Input() changeLangEvent: Observable<void>;
	@Input() alertType: any;
	@Input() availableAlerts: any;
	@Output() filterEvent = new EventEmitter();
	@ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;
	@ViewChild(DaterangepickerDirective) picker1: DaterangepickerDirective;
	columnDefs: any = [];
	isExpand: boolean = false;
	isExpandTrigger: boolean = false;
	selected = [];
	MonitoringTypeList: String[] = [];
	selectedAlertTrigger: any;
	gridOptions: any;
	gridColumnApi: any;
	startDate: any = undefined;
	endDate: any = undefined;
	format: any = "DD MMM YYYY";
	alwaysShowCalendars: boolean;
	loadingOverlayComponent: any;
	currentDate: any = moment(new Date()).format('DD/MM/YYYY');
	loadingOverlayComponentParams: any;
	loadMessage: string = "Loading 1000 Records..."
	locale: any = {
		format: "DD MMM YYYY"
	};
	startMinDate: any = moment(new Date()).subtract(89, 'days');
	minDate: any;
	maxDate: any = moment(new Date());
	ranges: any = {
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment().subtract(6, 'days')],
		'Last 30 Days': [moment().subtract(29, 'days'), moment().subtract(29, 'days')],
		'Last 90 Days': [moment().subtract(89, 'days'), moment().subtract(89, 'days')]
	}
	invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
	alerts: any = [];
	alertTrigger: any;
	selectedAlert: any = "ALL";
	searchByEntityName: any;
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	mySubscription: Subscription
	private paginationPageSize = 5;
	private totalPages = 0;
	api: any;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	alertList: any = [];
	alertListCopy: any = [];
	detailCellRenderer: any;
	detailRowHeight: number;
	minimize: any = true;
	loadNew: boolean = false;
	pagesCount: number = 1;
	defaultRowsCount: number = 1000;
	pageNumber: number = 1;
	totalRecords: number;
	filterValue: any;
	avalilableResults: number = 0;
	showing: any;
	selectedAlertTriggerArray: any = [];
	from: boolean = false;
	to: boolean = false;
	filter: boolean = false;
	popupData: any;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}


	allAlerts: any = [];
	allMonitoringAlerts: any = [];
	allComplete: boolean = false;



	dateComparator(date11: any, date22: any) {
		var date1: any = moment(date11).format('DD/MM/YYYY');
		var date2: any = moment(date22).format('DD/MM/YYYY');
		var result1: any;
		var result2: any;
		if (date1 === undefined || date1 === null || date1.length !== 10) {
			result1 = null;
		} else {
			var yearNumber1: any = date1.substring(6, 10);
			var monthNumber1: any = date1.substring(3, 5);
			var dayNumber1: any = date1.substring(0, 2);
			result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
		}

		if (date2 === undefined || date2 === null || date2.length !== 10) {
			result2 = null;
		} else {
			var yearNumber2: any = date2.substring(6, 10);
			var monthNumber2: any = date2.substring(3, 5);
			var dayNumber2: any = date2.substring(0, 2);
			result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
		}
		if (result1 === null && result2 === null) {
			return 0;
		}
		if (result1 === null) {
			return -1;
		}
		if (result2 === null) {
			return 1;
		}
		return result1 - result2;
	}

	monthToComparableNumber(date: any) {
		if (date === undefined || date === null || date.length !== 10) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}
	constructor(public dialogRef: MatDialogRef<FilterPopUpComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, public monitoringService: MonitoringService, public dialog: MatDialog,
		public loaderService: LoaderService, public translateService: TranslateService, private homeService: HomeService, public router: Router) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		window.scroll(0, 0);
	}

	ngOnInit(): void {
		if (this.data.label == "monitor-alert-filter-popup") {
			this.alerts = this.data.monitoringTypeClassification;
			if (this.alerts.length > 0) {
				// Tree structure for making an array having common monitoring type and its different classfications.
				const current = Object.create(null);
				const finalArr: any = [];
				this.alerts.forEach(function (obj: any) {
					if (!current[obj.monitoringType]) {
						current[obj.monitoringType] = [];
						finalArr.push({ monitoringType: obj.monitoringType, monitoringClassification: current[obj.monitoringType] });
					}
					current[obj.monitoringType].push(obj.monitoringClassification);
				});
				this.allAlerts = finalArr;

				for (let index = 0; index < this.allAlerts.length; index++) {
					this.allAlerts[index].isSelected = false;
					if (this.allAlerts[index].monitoringClassification[0] != null) {
						for (let i = 0; i < this.allAlerts[index].monitoringClassification.length; i++) {
							var obj = {
								monitoringClassification: this.allAlerts[index].monitoringClassification[i],
								isSelected: false
							}
							this.allAlerts[index].monitoringClassification[i] = obj
						}
					}
				}
			}

			this.alertTrigger = this.data.alertTrigger;
			let sortedArray = this.alertTrigger.sort(function (a: any, b: any) {
				return a.alertTrigger.localeCompare(b.alertTrigger);
			});
			this.alertTrigger = sortedArray;
			if (this.alertTrigger != null) {
				this.alertTrigger.forEach(function (element: any) {
					element.isSelected = false
				})
			} else {
				this.alertTrigger = [];
			}
		}
	}

	monitoringObj: any = {
		"MonitoringType": [],
		"AlertClassification": []
	};

	//Click event on parent checkbox  
	parentCheck(parentObj: any) {
		if (parentObj.isSelected) {
			this.monitoringObj.MonitoringType.push(parentObj.monitoringType)
			if (parentObj.monitoringClassification[0] != null) {
				for (let index = 0; index < parentObj.monitoringClassification.length; index++) {
					var index1 = this.monitoringObj.AlertClassification.indexOf(parentObj.monitoringType + " - " + parentObj.monitoringClassification[index].monitoringClassification);
					if (index1 != -1) {
						this.monitoringObj.AlertClassification.splice(index1, 1);
					}
				}
			}
		} else {
			var index = this.monitoringObj.MonitoringType.indexOf(parentObj.monitoringType);
			this.monitoringObj.MonitoringType.splice(index, 1)
		}
		if (parentObj.monitoringClassification[0] != null) {
			for (var i = 0; i < parentObj.monitoringClassification.length; i++) {
				parentObj.monitoringClassification[i].isSelected = parentObj.isSelected;
			}
		}
		this.findAlertTriggers(this.monitoringObj);
	}

	//Click event on child checkbox  
	childCheck(parentObj: any, childObj: any) {
		parentObj.isSelected = childObj.every(function (itemChild: any) {
			return itemChild.isSelected == true;
		})
		if (parentObj.isSelected) {
			this.monitoringObj.MonitoringType.push(parentObj.monitoringType);
			for (let j = 0; j < parentObj.monitoringClassification.length; j++) {
				if (parentObj.monitoringClassification[j].isSelected) {
					var index1 = this.monitoringObj.AlertClassification.indexOf(parentObj.monitoringType + " - " + parentObj.monitoringClassification[j].monitoringClassification);
					if (index1 != -1) {
						this.monitoringObj.AlertClassification.splice(index1, 1);
					}
				}
			}
		} else {
			var index = this.monitoringObj.MonitoringType.indexOf(parentObj.monitoringType);
			if (index != -1) {
				this.monitoringObj.MonitoringType.splice(index, 1)
			}
			for (let j = 0; j < parentObj.monitoringClassification.length; j++) {
				if (parentObj.monitoringClassification[j].isSelected) {
					this.monitoringObj.AlertClassification.push(parentObj.monitoringType + " - " + parentObj.monitoringClassification[j].monitoringClassification)
				} else {
					var index1 = this.monitoringObj.AlertClassification.indexOf(parentObj.monitoringType + " - " + parentObj.monitoringClassification[j].monitoringClassification);
					if (index1 != -1) {
						this.monitoringObj.AlertClassification.splice(index1, 1);
					}
				}
			}
		}
		this.monitoringObj.AlertClassification = Array.from(new Set(this.monitoringObj.AlertClassification));
		this.findAlertTriggers(this.monitoringObj);
	}

	alertTriggerSelect(alertTrigger: any, event: any) {
		var obj: any = {
			alertTrigger: alertTrigger
		}
		if (event.target.checked) {
			this.selectedAlertTriggerArray.push(obj);
		} else {
			var alertTriggerIndex = this.selectedAlertTriggerArray.findIndex((x: any) => x.alertTrigger === alertTrigger);
			if (alertTriggerIndex != -1) {
				this.selectedAlertTriggerArray.splice(alertTriggerIndex, 1);
			}
		}
		this.selectedAlertTrigger = this.selectedAlertTriggerArray;
	}

	findAlertTriggers(obj: any) {
		console.log("final obj", obj);
		this.selectedAlertTrigger = null;
		this.loaderService.isLoading.next(true);
		this.monitoringService.getAlertTypesAndAlertTriggers(obj).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			data = JSON.parse(data);
			if (data?.success) {
				this.alertTrigger = data?.data.alertTtriggers;
				let sortedArray = this.alertTrigger.sort(function (a: any, b: any) {
					return a.alertTrigger.localeCompare(b.alertTrigger);
				});
				this.alertTrigger = sortedArray;
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
		});
	}

	open(event: any) {
		this.picker.open();
	}
	openE(event: any) {
		this.picker1.open();
	}


	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}

	endDateUpdated() {
		if (this.endDate?.startDate && this.from) {
			this.startDate = {
				startDate: this.startDate.startDate,
				endDate: this.endDate?.startDate
			}
			if (this.from) {
				this.to = true;
			}
		} else {
			this.startDate = undefined;
			this.endDate = undefined;
		}
	}

	startDateUpdated() {
		if (this.startDate?.startDate && !this.from && !this.to) {
			this.minDate = moment(this.startDate.startDate)
			this.endDate = {
				"startDate": new Date(),
				"endDate": new Date()
			}
			this.startDate = {
				startDate: this.startDate.startDate,
				endDate: new Date()
			}
			this.from = true;
			this.to = false;
		} else if (!this.from && !this.to) {
			this.startDate = undefined;
			this.endDate = undefined;
		} else if (this.startDate?.startDate) {
			this.minDate = moment(this.startDate.startDate);
			this.startDate = {
				startDate: this.startDate.startDate,
				endDate: new Date()
			};
			this.endDate = {
				startDate: new Date(),
				endDate: new Date()
			};
		}
	}

	applyFilter(data: any) {
		var selectedAlert = [];
		for (var i = 0; i < data.length; i++) {
			if (data[i].isSelected) {
				selectedAlert.push(data[i].monitoringType);
			}
		}
		var alertTriggers: any = "";
		this.selectedAlertTrigger?.map((res: any, index: number) => {
			if (index == 0) {
				alertTriggers = alertTriggers + res.alertTrigger;
			} else {
				alertTriggers = alertTriggers + "*" + res.alertTrigger
			}
		});
		this.filterValue = {
			"entityCommonFilter": this.searchByEntityName ? this.searchByEntityName : "",
			"monitoringTypeFilter": selectedAlert ? selectedAlert : "",
			"alertTriggerFilter": alertTriggers ? alertTriggers : "",
			"alertDateFromFilter": this.startDate?.startDate ? dayjs(this.startDate.startDate).format('YYYY-MM-DD') : "",
			"alertDateToFilter": this.endDate?.startDate ? dayjs(this.endDate.startDate).format('YYYY-MM-DD') : "",
			"entityAlertClassificationFilter": null,
		}
		this.dialogRef.close({ data: this.filterValue });
	}

	removeFilter() {
		this.pageNumber = 1;
		this.filterValue = null;
		this.loadNew = false;
		this.alertType = "ALL";
		this.filter = false;
		this.searchByEntityName = "";
		this.selectedAlert = "ALL";
		this.selected = [];
		this.selectedAlertTrigger = null;
		this.startDate = null;
		this.endDate = null;
		this.filterEvent.emit();
		this.dialogRef.close({ data: this.filterValue });
	}

	close() {
		this.dialogRef.close();
	}
	refresh(params: any): boolean {
		return false;
	}

	expandAlertList() {
		this.isExpand = !this.isExpand;
	}

	expandAlertTriggerList() {
		this.isExpandTrigger = !this.isExpandTrigger;
	}

	ApplyFilter() {
		this.dialogRef.close({ data: this.filterValue });
	}

	RemoveFilter() {
		this.dialogRef.close({ data: '' });
	}
}