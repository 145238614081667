<article>
    <div class="zanna_div row">
        <div class="data-grid-strip">
            <div class="end">
                <!-- <div  class="first" >
                    <mat-icon style="color: white">remove_red_eye</mat-icon> <span>View Alerts</span>
                </div> -->
                <div class="middle-button" (click)="exportAllAlerts()" >
                   <div class="align-btn">
                        <mat-icon style="font-size: 21px; margin-right: 3px;" class="fs-3" *ngIf="!isMobile">get_app</mat-icon>
                        <mat-icon style="font-size: 21px; margin-right: 3px;" class="fs-3" *ngIf="isMobile">remove_red_eye</mat-icon>                        
                    </div>
                    <div style="float: right;">
                        <span class="fs-2" *ngIf="!isMobile">{{ 'monitor.export_view' | translate }}</span>
                        <span class="fs-2" *ngIf="isMobile">{{ 'monitor.export_view_mobile' | translate }}</span>
                    </div>
                </div>
                <div class="last" (click)="minimize = !minimize" *ngIf="!isMobile">
                    <i class="fa fa-sliders content-align font-14" aria-hidden="true"></i>
                    <span class="font-14">{{ 'monitor.filter' | translate }}</span>
                    <i class="fa fa-angle-down content-align font-18" aria-hidden="true" *ngIf="minimize"></i>
                    <i class="fa fa-angle-up content-align font-18" aria-hidden="true" *ngIf="!minimize"></i>
                </div>
            </div>
        </div>
        <mat-card appearance="outlined" [style.min-width.px]="'1000'" *ngIf="!minimize" >
            <div *ngIf="showTip">   
                <div class="toast sticky">
                    <div class="success-color">
                        <mat-icon class="icon-success">
                          info_outline
                        </mat-icon>
                    </div>
                    <div class="text"> <strong class="tip">{{ 'monitor.action_tip' | translate }}:</strong> <div style="margin: 0px 10px 0px 6px;">You can search for entities through Identification / Entity Name or Client File Ref. Filter by Alert Type, Alert Triggers or use the date selector to select a certain timeframe to view. To sort the list use the arrows found at the header of the table to sort the entire data table.</div></div><mat-icon class="close" (click)="hideTip()">close</mat-icon>
                </div>
            </div>  
            <div fxLayout="row">
                <div fxFlex="48.5" style="margin: 10px;">
                    <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
                    <input matInput type="text" [(ngModel)]="searchByEntityName">
                    <mat-icon matSuffix class="search-input-icon">search</mat-icon>
                </div>
            </div>
            <div fxLayout="row">
                <!-- <div fxFlex="25" style="margin: 10px;">
                    <label>{{ 'monitor.alertType' | translate }}</label><br />
                    <ng-select name="alerts" [items]="alerts" [(ngModel)]="selectedAlert" (change)="findAlertTriggers($event)">
                    </ng-select>
                </div> -->
                <div fxFlex="25" style="margin: 10px;">
                    <label>{{ 'monitor.alertType' | translate }}</label><br />
                    <ng-select
                        [items]="monitoringTypeClassification"
                        [multiple]="true"
                        bindLabel="monitoringClassification"
                        groupBy="monitoringType"
                        orderBy="id"
                        [selectableGroup]="true"
                        placeholder="Select Alert Type"
                        [selectableGroupAsModel]="true"
                        [closeOnSelect]="false"
                        [(ngModel)]="selected" (add)="onAdd($event)" (remove)="onAdd($event)">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.monitoringType | uppercase}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" *ngIf="item.monitoringClassification"/> {{item.monitoringClassification}}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-item="item" let-clear="clear">
                            <div class="ng-value" >
                                <span class="ng-value-label">{{selected.length}} selected...</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <div fxFlex="25" style="margin: 10px;">
                    <label>{{ 'monitor.alertTriggers' | translate }}</label><br />
                    <ng-select name="alerts" [items]="alertTrigger" [multiple]="true" bindLabel="alertTrigger" [closeOnSelect]="false" [(ngModel)]="selectedAlertTrigger" placeholder="Select Alert Trigger" >
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.alertTrigger }}
                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.alertTrigger }}
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-item="item" let-clear="clear">
                            
                            <div class="ng-value" >
                                <span class="ng-value-label">{{selectedAlertTrigger.length}} selected...</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <div fxFlex="25" style="margin: 10px;">
                    <label>{{ 'monitor.dateOfAlert' | translate }}</label><br />
                    <input type="text"
                    ngxDaterangepickerMd
                    [(ngModel)]="startDate"
                    [showCustomRangeLabel]="true"
                    [lockStartDate]="false"
                    [alwaysShowCalendars]="true"
                    [singleDatePicker]="true"
                    [locale]="locale"
                    [ranges]="ranges"
                    [linkedCalendars]="false"
                    [autoApply]="true"
                    [minDate]="startMinDate"
                    [maxDate]="maxDate"
                    [showClearButton]="false"
                    (datesUpdated)="startDateUpdated()"
                    [placeholder]="'placeholder.from' | translate" readonly/>
                    <mat-icon matSuffix class="search-input-icon ngx-daterangepicker-action" (click)="open($event)">date_range</mat-icon>
                </div>
                <div fxFlex="25" style="margin: 10px;">
                    <label style="padding-bottom: 14px;"></label><br />
                    <input type="text" #picker1
                    ngxDaterangepickerMd
                    [(ngModel)]="endDate"
                    [showCustomRangeLabel]="false"
                    [alwaysShowCalendars]="true"
                    [singleDatePicker]="true"
                    [locale]="locale"
                    [linkedCalendars]="false"
                    [autoApply]="true"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [showClearButton]="false"
                    (datesUpdated)="endDateUpdated()"
                    [placeholder]="'placeholder.to' | translate" readonly/>
                     <mat-icon matSuffix  class="search-input-icon open ngx-daterangepicker-action" >date_range</mat-icon>
                </div>
            </div>
            <div fxLayout="row">
                <div style="margin: 10px;flex: auto;">
                    <button class="button button__primary float-right" (click)="applyFilter()" style="margin: 10px;">
                        {{ 'bulk.upload.apply' | translate }}
                    </button>
                    <button (click)="removeFilter()" class="button btn button__basic float-right" style="margin: 10px;">
                        {{ 'monitor.clear_filter' | translate }}
                    </button> 
                </div>
            </div>

        </mat-card>
        <div class="btn-data-grid-strip border-b-n">
            <div class="filter-container" (click)="openFilterPopup()" *ngIf="isMobile">
                <i class="fa fa-sliders p-r-8 icon-color" aria-hidden="true"></i>
                <span class="font-14">{{ 'monitor.filter' | translate }}</span>
            </div>
            <div (click)=ExpandAll(allExpand) class="arrow-container" *ngIf="isMobile && alertList.length>0">
                <mat-icon class="down-arrow-icon icon-color" *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon> <span>{{expandCollapseButtonValue}}</span>
            </div>
        </div>
        <ag-grid-angular class="ag-theme-alpine" [id]="gridId" [rowData]="alertList" [columnDefs]="columnDefs"
            [masterDetail]="true" [detailRowHeight]="detailRowHeight" [detailCellRenderer]="detailCellRenderer"
            [loadingOverlayComponent]="loadingOverlayComponent" [loadingOverlayComponentParams]="loadingOverlayComponentParams"
            (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [tooltipShowDelay]="tooltipShowDelay"
            (filterChanged)="onFilterChanged($event)" [domLayout]="domLayout" [icons]="icons">
        </ag-grid-angular>
        <app-monitoring-pagination *ngIf="alertList.length > 0" 
            [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" (loadNext)="loadNextRecords()" (loadPrev)="loadPreviousRecords()"
            [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="totalRecords" [hideNextRec]="true" [showing]="showing" [avalilableResults]="avalilableResults">
        </app-monitoring-pagination>
        <!-- <app-pagination
            [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" *ngIf="alertList.length > 0"
            [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
        </app-pagination>  -->
    </div>
</article>