<div id="confirm">
    <div class="close-dialog">
        <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
    </div>
    <div class="cross-header">
        <h1 matDialogTitle class="confirm-title">
            <i class="fa fa-check-circle icon-success"></i>
        </h1>
    </div>
    <div matDialogContent class="content-msg"> 
        <div>
            <h1 matDialogTitle class="confirm-title">
                <span *ngIf="action.type== 7"> Sucessfully Deleted</span><span *ngIf="action.type != 7">{{ action.message }}</span>
            </h1>
        </div>
        <div class="fs-2" style ="letter-spacing: normal;">
            <span *ngIf="action.type== 7">{{ action.message }}</span> <span *ngIf="action.type != 7">{{ 'monitor.common_action_message' | translate }} {{ action.message | lowercase }}.</span>
        </div>
    </div>
    
</div>