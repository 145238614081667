import { Component, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { OrderHistoryService } from './order-history.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../services/loader.service';
import { CustomToolTipComponent } from '../custom-tooltip/custom-tooltip.component';
import { interval, Subscription } from 'rxjs';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';
import { NoResultDialogComponent } from '../no-result-dialog-box/no-result-dialog.component';
import { HomeService } from '../home/home.service';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import * as moment from 'moment';
import { ErrorTranslationResultDialogComponent } from '../error-translation-result-dialog-box/error-translation-result-dialog.component';
import { OrderHistoryDataCellRenderer } from './order-history-data/order-history-data.component';

@Component({
	selector: 'app-order-history',
	templateUrl: './order-history.component.html',
	styleUrls: ['./order-history.component.scss']
})

export class OrderHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	columnDefs: any;
	detailCellRenderer: any;
	detailRowHeight: any;
	orderHistoryList: any;
	gridOptions: any;
	gridColumnApi: any;
	selectedStatusBar: string;
	orderList: any = [];
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	options: any;
	option: string;
	uncollectedCount: number;
	mySubscription: Subscription;
	private paginationPageSize;
	private totalPages;
	initialFlag: boolean;
	domLayout: any;
	loadFirstTime: boolean = true;
	allExpand: boolean = false;
	expandCollapseButtonValue: String;
	title: string;
	JPtranslationDialogRef: MatDialogRef<ErrorTranslationResultDialogComponent>;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(public orderHistoryService: OrderHistoryService, public dialog: MatDialog,
		public loaderService: LoaderService, public translateService: TranslateService,
		private homeService: HomeService, public router: Router) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.columnDefs = [];
		this.orderHistoryList = [];

		this.uncollectedCount = 0;
		this.paginationPageSize = 5;
		this.totalPages = 0;
		this.initialFlag = false;
		this.selectedStatusBar = 'uncollected';
		this.option = "uncollected";
		this.domLayout = "autoHeight";
		this.expandCollapseButtonValue = "Expand all rows";
		this.title = "Order History";
		this.getColDefs();
		this.values();
		window.scroll(0, 0);
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 250;
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"myDetailCellRenderer": OrderHistoryDataCellRenderer,
			"customTooltip": CustomToolTipComponent
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.loaderService.isLoading.next(true);
		this.orderHistoryService.setSid(localStorage.getItem('sessionID'))
		this.loadOrderHistoryData();
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
	}

	ngOnInit(): void {
		this.mySubscription = interval(60000).subscribe((x => {
			this.loadFirstTime = false;
			this.checkProcessingRecords();
		}));
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.deselectAll();
	}

	checkProcessingRecords() {
		let present = this.orderList.some((item: any) => ((item.itemStatus).toLowerCase() == "processing"));
		if (present) {
			this.loadOrderHistoryData();
		}
	}

	getColDefs() {
		this.translateService.get(['orderHistory.orderItemId', 'orderHistory.status', 'orderHistory.identification',
			'orderHistory.product', 'orderHistory.country', 'orderHistory.turnAroundTime', 'orderHistory.availableTill',
			'orderHistory.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'itemId',
						headerName: value['orderHistory.orderItemId'],
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 100,
						minWidth: 100,
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'itemStatus',
						headerName: value['orderHistory.status'],
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 100,
						minWidth: 100,
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'entityName',
						headerName: value['orderHistory.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile ? '13px 0px 13px 13px' : '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'productDescription',
						headerName: value['orderHistory.product'],
						width: 170,
						minWidth: 170,
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'countryName',
						headerName: value['orderHistory.country'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							return param.data?.parentCountryName ? param.data?.parentCountryName : param.data?.countryName;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'speedDescription',
						headerName: value['orderHistory.turnAroundTime'],
						width: 150,
						minWidth: 150,
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							if (param.data.itemStatus != 'Processing' && param.data.itemStatus != 'Cancelled') {
								return param.data.speedDescription;
							} else {
								return param.data.expectedDeliveryDate ? (param.data.speedDescription + '<br/><span style="font-size:13px; color: #8C8C8C">Expected delivery date:</span><br/>' + moment(param.data.expectedDeliveryDate).format('DD MMM YYYY')) : param.data.speedDescription;
							}
						},
						comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any, isInverted: any) => {
							if (nodeA.data.turnAroundTimeBySupplier == nodeB.data.turnAroundTimeBySupplier) return 0;
							return (nodeA.data.turnAroundTimeBySupplier > nodeB.data.turnAroundTimeBySupplier) ? 1 : -1;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						field: 'availableTill',
						headerName: value['orderHistory.availableTill'],
						width: 130,
						minWidth: 130,
						hide: this.isMobile,
						valueFormatter: (params: any) => {
							if (params.value != null && params.value != '' && params.value != undefined) {
								return moment(params.value).format('DD MMM YYYY');
							} else {
								return null;
							}
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px', 'font-size': '14px' }
					},
					{
						width: this.isMobile ? 60 : 110,
						maxWidth: this.isMobile ? 60 : 110,
						sortable: false,
						headerName: this.isMobile ? "" : value['orderHistory.action'],
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'order-history'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile ? '0px' : "13px 18px", }
					},
				]
			});
	}

	ngOnDestroy(): void {
		this.mySubscription.unsubscribe();
	}

	changeLanguage(ev: any) {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	loadOrderHistoryData() {
		this.orderHistoryService.getOrderHistory().subscribe((data: any) => {
			this.uncollectedCount = 0;
			data = data.data;
			data.find((obj: any) => {
				if ((obj.itemStatus).toLowerCase() == 'received') {
					if (!obj.archived) {
						this.uncollectedCount++;
					}
					obj.itemStatus = 'Available';
				}
				if ((obj.itemStatus).toLowerCase() == 'cancel' || (obj.itemStatus).toLowerCase() == 'cancelled') {
					obj.itemStatus = 'Cancelled';
				}
				if ((obj.itemStatus).toLowerCase() == 'placed' || (obj.itemStatus).toLowerCase() == 'sent') {
					obj.itemStatus = 'Processing';
				}
				if ((obj.itemStatus).toLowerCase() == 'collected') {
					obj.itemStatus = 'Collected';
				}
			});
			this.orderHistoryList = data.map((record: any) => ({
				...record,
				expanded: false
			}));
			this.orderList = data;
			if (this.uncollectedCount == 0) {
				if (!this.initialFlag) {
					this.initialFlag = true;
					this.option = "all";
					this.changeStatusBar('all');
				} else {
					this.changeStatusBar(this.selectedStatusBar);
				}
			} else {
				this.changeStatusBar(this.selectedStatusBar);
			}
			if (this.loadFirstTime) {
				this.loaderService.isLoading.next(false);
			}
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	values() {
		this.translateService.get(['orderHistory.allOrders', 'orderHistory.unCollectedOrders', 'orderHistory.collectedOrders',
			'orderHistory.processing', 'orderHistory.archived']).subscribe(value => {
				this.options = [
					{
						value: value['orderHistory.allOrders'],
						key: "all"
					},
					{
						value: value['orderHistory.unCollectedOrders'],
						key: "uncollected"
					},
					{
						value: value['orderHistory.collectedOrders'],
						key: "collected"
					},
					{
						value: value['orderHistory.processing'],
						key: "processing"
					},
					{
						value: value['orderHistory.archived'],
						key: "archived"
					}
				]
			});
	}

	changeStatusBar(status: any) {
		this.allExpand = false;
		this.expandCollapseButtonValue = "Expand all rows";
		this.selectedStatusBar = status;
		this.orderHistoryList = [];
		if (!this.isMobile) {
			this.gridColumnApi.setColumnsVisible(['availableTill'], true);
		} else {
			this.gridColumnApi.setColumnsVisible(['availableTill'], false);
		}

		if (status == "uncollected") {
			this.orderList.forEach((element: any) => {
				if (!element.archived && (element.itemStatus).toLowerCase() == "available") {
					this.orderHistoryList.push(element);
				}
			});
		}
		if (status == "collected") {
			this.orderList.forEach((element: any) => {
				if (!element.archived && (element.itemStatus).toLowerCase() == "collected") {
					this.orderHistoryList.push(element);
				}
			});
		}
		if (status == "processing") {
			this.gridColumnApi.setColumnsVisible(['availableTill'], false);
			this.orderList.forEach((element: any) => {
				if (!element.archived && (((element.itemStatus).toLowerCase() == "processing") || ((element.itemStatus).toLowerCase() == "cancelled"))) {
					this.orderHistoryList.push(element);
				}
			});
		}
		if (status == "archived") {
			this.orderList.forEach((element: any) => {
				if (element.archived) {
					this.orderHistoryList.push(element);
				}
			});
		}
		if (status == "all") {
			this.orderList.forEach((element: any) => {
				let res = JSON.parse(JSON.stringify(element));
				if (res.archived) {
					res.itemStatus = 'Archived';
					this.orderHistoryList.push(res);
				} else {
					this.orderHistoryList.push(res);
				}
			});
		}
		if (!this.isMobile) {
			setTimeout(() => {
				this.gridOptions.api?.sizeColumnsToFit();
			}, 100);
		}
	}

	onActionClick(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
		if (ev.actionEvent === 'downlaod') {
			this.genrateReport(ev, 'PDF');
		}
		if (ev.actionEvent === 'html') {
			this.genrateReport(ev, 'HTML');
		}
		if (ev.actionEvent === 'json') {
			this.genrateReport(ev, 'JSON');
		}
		if (ev.actionEvent === 'downloadReportForMobile') {
			this.genrateReportForMobile(ev, 'PDF');
		}
		if (ev.actionEvent === 'billing') {
			this.router.navigate(['billing-statement'], { state: { dataList: ev.data } });
		}

		if (ev.label === 'jp_translation') {
			this.loaderService.isLoading.next(true);
			let categoryId = ev.data.categoryId;
			this.homeService.getTranslations(ev.data.countryCode, categoryId).subscribe((data: any) => {
				if (data.errors?.length > 0) {
					this.dialog.open(ErrorDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: data?.errors[0].errorMsg,
							tryAgain: false
						}
					});
				} else {
					if (data.data != null) {
						let translateObject = data.data.find((item: any) => (item.translationLanguage == "Japanese"));
						translateObject.reportTypeData = JSON.parse(translateObject?.reportTypeData);
						translateObject.reportTypeData.find((res: any) => {
							if ((ev.data.productOfferingId) == (res.ReportOfferingId)) {
								let obj = {
									"ProductOfferingId": res.TranslationOfferingId,
									"OrderType": "SINGLE",
									"SellingPrice": parseFloat((res.ProductPrice).replace('', '')),
								}
								let arr = [];
								arr.push(obj);
								this.loaderService.isLoading.next(true);
								this.orderHistoryService.addChildToCart(arr, ev.data.itemId).subscribe((data: any) => {
									if (data.success) {
										this.loaderService.isLoading.next(false);
										this.router.navigate(['/home']);
									} else if (data.errors[0].errorCode == 'A01040202') {
										this.loaderService.isLoading.next(false);
										this.JPtranslationDialogRef = this.dialog.open(ErrorTranslationResultDialogComponent, {
											width: this.isMobile ? 'auto' : '37vw',
											data: {
											}
										});
										this.translateService.get(['jp_translation_error']).subscribe(value => {
											this.JPtranslationDialogRef.componentInstance.confirmMessage = value['jp_translation_error'];
										});
									} else {
										this.loaderService.isLoading.next(false);
										this.dialog.open(ErrorTranslationResultDialogComponent, {
											width: this.isMobile ? 'auto' : '37vw',
											data: {
												errorMessage: data.errors[0]
											}
										});
									}
								}, () => {
									this.loaderService.isLoading.next(false);
									this.translateService.get(['errorMsg.translationFailed']).subscribe(value => {
										this.dialog.open(ErrorDialogComponent, {
											width: this.isMobile ? 'auto' : '37vw',
											data: {
												errorMessage: value['errorMsg.translationFailed']
											}
										});
									});
								});
							}
						})
						if (translateObject.reportTypeData.length == 0) {
							this.loaderService.isLoading.next(false);
							this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
								this.dialog.open(NoResultDialogComponent, {
									width: this.isMobile ? 'auto' : '37vw',
									data: {
										infoMessage: value['errorMsg.pleaseTryAgain']
									}
								});
							});
						}
					} else {
						this.loaderService.isLoading.next(false);
						this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
							this.dialog.open(NoResultDialogComponent, {
								width: this.isMobile ? 'auto' : '37vw',
								data: {
									infoMessage: value['errorMsg.pleaseTryAgain']
								}
							});
						});
					}
				}
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					this.dialog.open(NoResultDialogComponent, {
						width: this.isMobile ? 'auto' : '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
				});
			});
		}
	}

	genrateReport(ev: any, format: any) {
		this.loaderService.isLoading.next(true);
		var formData: any = new FormData();
		formData.append("itemId", ev.data.itemId);
		formData.append("reportFormat", format);
		formData.append("vendorCategory", ev.data.categoryId);

		this.orderHistoryService.generateReport(formData).subscribe(async (res) => {
			this.loadOrderHistoryData();
			if (format == 'JSON') {
				const a = document.createElement('a');
				a.setAttribute('download', ev.data.itemId + '.json');
				a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((await res.text())));
				a.click();
			} else {
				this.dialog.open(DocumentViewComponent, {
					width: '80vw',
					data: {
						response: res,
						format: format
					}
				});
			}
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	genrateReportForMobile(ev: any, format: any) {
		this.loaderService.isLoading.next(true);
		var formData: any = new FormData();
		formData.append("itemId", ev.data.itemId);
		formData.append("reportFormat", format);
		formData.append("vendorCategory", ev.data.categoryId);

		this.orderHistoryService.generateReport(formData).subscribe(async (res) => {
			this.loadOrderHistoryData();
			const blob = new Blob([res], { type: "application/pdf" });
			let url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('download', 'Report.pdf');
			a.setAttribute('href', url);
			a.click();
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	advancedSearch() {
		let dialogRef = this.dialog.open(AdvancedSearchComponent, {
			disableClose: true,
			width: "70vw",
			panelClass: 'advanced-search-dialog'
		});
		dialogRef.afterClosed().subscribe(res => {
		});
	}

	ExpandAll(data: any) {
		if (!data) {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		} else {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}

}
