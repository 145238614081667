import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';

@Injectable()
export class OrderHistoryService {

	headers = new HttpHeaders();
	sessionId: any = "";

	constructor(private http: HttpClient, private appService: AppService) {

		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('Accept', 'application/json');
		this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
		this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
		this.headers = this.headers.set('sessionid', this.sessionId);
		this.headers = this.headers.set('X-FRAME-OPTIONS', 'deny');
		this.headers = this.headers.set('Content-Security-Policy', 'frame-ancestors "none";');

	}

	setSid(sessionId: any) {
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', sessionId);
	}

	getOrderHistory() {
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', this.sessionId);
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Order/OrderHistory',
			{ headers: this.headers }).pipe(map(res => res));
	}


	addChildToCart(data: any, seqId: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/items/' + seqId + '/children', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	generateReport(formData: any): Observable<Blob> {
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers
		};
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/GenerateReport', formData,
			httpOptions).pipe(map(res => res));
	}

}